// import { api } from '../config/routes';
// import React from "react";
import ApiMethods from "../models/StaticTypes";
import { api ,baseUrl} from "../config/apiconfig";
import axios from "../api/config";
//import Apiee

const _api = { ...api };
const _abortController = new AbortController();
// const apiCall = (params) => {
//      return new Promise((resolve, reject) => {
//        if (!params.options) {
//          return fetch(`${params.url}`, { signal: this.signal })
//            .then(response =>
//              !response.ok
//                ? response.json().then(data => this.handleErrors(data, reject))
//                : response.json().then(data => resolve(data))
//            )
//            .catch(error => this.handleErrors(error, reject));
//        }
//        fetch(`${params.url}`, {
//          signal: this.signal,
//          ...params.options
//        })
//          .then(response =>
//            !response.ok
//              ? response.json().then(data => this.handleErrors(data, reject))
//              : response.json().then(data => resolve(data))
//          )
//          .catch(error => this.handleErrors(error, reject));
//      });
//    };

export let token = localStorage.getItem("token");

export default class Api {}

function apiCall(params) {
  return new Promise((resolve, reject) => {
    if (!params.options) {
      return fetch(`${params.url}`, { signal: _abortController.signal })
        .then((response) =>
          !response.ok
            ? response.json().then((data) => handleErrors(data, reject))
            : response.json().then((data) => resolve(data))
        )
        .catch((error) => handleErrors(error, reject));
    }
    fetch(`${params.url}`, {
      signal: _abortController.signal,
      ...params.options,
    })
      .then((response) =>
        !response.ok
          ? response.json().then((data) => handleErrors(data, reject))
          : response.json().then((data) => resolve(data))
      )
      .catch((error) => handleErrors(error, reject));
  });
}

const handleErrors = (error1, callback) => {
  var error = error1.ErrorModel;
  if (error !== undefined) {
    if (/SyntaxError/.test(error.name)) {
      return callback({
        error_code: error.code,
        error_message: error.message,
      });
    }
    if (/AbortError/.test(error.name)) {
      return callback({
        error_code: error.code,
        error_message: error.message,
      });
    }
    debugger;
    return callback({
      error_code: error.errorCode,
      error_message: error.errorMessage || "Something went wrong",
    });
  } else {
    return callback({
      error_code: 400,
      error_message: "Something went wrong",
    });
  }
};
//Users
export async function getUsers() {
  var url = new URL(`${_api.admin}/users/asc/1/0/0`);
  token = localStorage.getItem("token");
  const options = {
    method: ApiMethods.get,
    headers: {
      Authorization: `Bearer ${token ? token : token}`,
    },
  };
  const response = apiCall({ url, options });
  return response;
}

export async function updateUser(params, id) {
  var url = new URL(`${_api.admin}/user/${id}`);
  token = localStorage.getItem("token");
  const options = {
    method: "PATCH",
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = apiCall({ url, options });
  console.log("response from user",response)
  return response;
}

export async function deleteUserbyId(id) {
  var url = new URL(`${_api.admin}/user/${id}`);
  token = localStorage.getItem("token");
  const options = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = apiCall({ url, options });
  return response;
}

export async function blockUserbyId(id, status) {
  var url = new URL(`${_api.admin}/user/${id}/${status}`);
  token = localStorage.getItem("token");
  const options = {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = apiCall({ url, options });
  return response;
}

export async function loginUser(params) {
  var url = new URL(`${_api.admin}/login`);
  token = localStorage.getItem("token");
  const options = {
    method: "POST",
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = apiCall({ url, options });
  return response;
}

//Jobs
export async function getJobs() {
  var url = new URL(`${_api.admin}/jobs/1/0/0`);
  token = localStorage.getItem("token");
  const options = {
    method: ApiMethods.get,
    headers: {
      Authorization: `Bearer ${token ? token : token}`,
    },
  };
  const response = apiCall({ url, options });
  return response;
}

export async function updateJobStatus(id, isVisible) {
  var url = new URL(`${_api.admin}/jobs/${id}/${isVisible}`);
  token = localStorage.getItem("token");
  const options = {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token ? token : token}`,
    },
  };
  const response = apiCall({ url, options });
  return response;
}

export async function getJobById(id) {
  var url = new URL(`${_api.admin}/jobs/${id}`);
  token = localStorage.getItem("token");
  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token ? token : token}`,
    },
  };
  const response = apiCall({ url, options });
  return response;
}

export function abort() {
  // let abort1 = _abortController.abort();
  // _abortController = new AbortController();
  //   return abort1;
}

//categories

export async function getCategories() {
  var url = new URL(`${_api.admin}/categories/1/asc/1/0/0`);
  token = localStorage.getItem("token");
  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token ? token : token}`,
    },
  };
  const response = apiCall({ url, options });
  return response;
}
export async function deleteCategoryById(id) {
  //console.log("id in api delte",id)
  var url = new URL(`${_api.admin}/category/${id}`);
  token = localStorage.getItem("token");
  const options = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  //console.log(options);
  const response = apiCall({ url, options });
  console.log("inside main category delete api",response)
 
  return response;
 
}

export async function createCategory(params) {
  console.log(" here params in createCategory api call", params);
  
  var url = new URL(`${_api.admin}/category/create`);
  token = localStorage.getItem("token");
  const options = {
    method: "POST",
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = apiCall({ url, options });
  console.log("response", response);
  return response;
}

export async function blockCategorybyId(id, status) {
  var url = new URL(`${_api.admin}/category/${id}/${status}`);
  token = localStorage.getItem("token");
  const options = {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = apiCall({ url, options });
  return response;
}

export async function updateCategory(params, id) {
  //console.log("params and id in updateCategory",params,id)
  var url = new URL(`${_api.admin}/category/${id}`);
  token = localStorage.getItem("token");
  const options = {
    method: "PATCH",
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = apiCall({ url, options });
  return response;
}

export async function createSubCategory(params, id) {
  console.log("params in createCategory api call",  id,params);
  console.log('_api.admin', _api.admin)
  var url = new URL(`${_api.admin}/sub/category/create/${id}`);
  token = localStorage.getItem("token");
  const options = {
    method: "POST",
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = apiCall({ url, options });
  //console.log("response",response)
  return response;
}

export async function deleteSubCategoryById(id) {
  console.log("inside delete subCategory",id)
  var url = new URL(`${_api.admin}/sub/category/${id}`);
  token = localStorage.getItem("token")
  const options = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  //console.log(options);
  const response = apiCall({ url, options });
  console.log("response inside delete subcategory",response)
  return response;
}

export async function blockSubCategory(id, status) {
  var url = new URL(`${_api.admin}/sub/category/${id}/${status}`);
  token = localStorage.getItem("token");
  const options = {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = apiCall({ url, options });
  return response;
}

export async function updateSubCategory(params, id) {
  console.log("job_categories_id",id)
  var url = new URL(`${_api.admin}/sub/category/${id}`);
  token = localStorage.getItem("token");
  const options = {
    method: "PATCH",
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = apiCall({ url, options });
  console.log("response in updatesubcat",response)
  return response;
}


//app pages

export async function getAppPages() {
  var url = new URL(`${_api.admin}/pages/asc/0/0`);
  token = localStorage.getItem("token");
  const options = {
    method: ApiMethods.get,
    headers: {
      Authorization: `Bearer ${token ? token : token}`,
    },
  };
  const response = apiCall({ url, options });
  console.log("response from app pages", response);
  return response;
}


//Dashboard
export async function getDashboard() {
  var url = new URL(`${_api.admin}/dashboard`);
  token = localStorage.getItem("token");
  const options = {
    method: ApiMethods.get,
    headers: {
      Authorization: `Bearer ${token ? token : token}`,
    },
  };
  const response = apiCall({ url, options });
  return response;
}

// Messages
export async function getMessages(type) {
  var url = new URL(`${_api.admin}/messages/list/${type}/0/0`);
  token = localStorage.getItem("token");
  const options = {
    method: ApiMethods.get,
    headers: {
      Authorization: `Bearer ${token ? token : token}`,
    },
  };
  const response = apiCall({ url, options });
  return response;
}

export async function getMessageTypesUsers(type) {
  var url = new URL(`${_api.admin}/messages/list/users/${type}`);
  token = localStorage.getItem("token");
  const options = {
    method: ApiMethods.get,
    headers: {
      Authorization: `Bearer ${token ? token : token}`,
    },
  };
  const response = apiCall({ url, options });
  return response;
}

export async function sendMessage(params) {
  var url = new URL(`${_api.admin}/message/create`);
  token = localStorage.getItem("token");
  const options = {
    method: "POST",
    body: JSON.stringify(params), //JSON.stringify(params),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = apiCall({ url, options });
  return response;
}

export async function deleteMessage(param) {
  var url = new URL(`${_api.admin}/messages`);
  token = localStorage.getItem("token");
  const options = {
    method: "DELETE",
    body: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = apiCall({ url, options });
  return response;
}

export async function getAppPageData(type) {
  var url = new URL(`${_api.page}/${type}`);
  token = localStorage.getItem("token");
  const options = {
    method: ApiMethods.get,
    headers: {
      Authorization: `Bearer ${token ? token : token}`,
    },
  };
  const response = apiCall({ url, options });
  return response;
}

export async function updateAppPages(id,params) {
  console.log("id",id, params)
  var url = new URL(`${_api.admin}/page/${id}`);
  token = localStorage.getItem("token");
  // const options = {
  //   method: "POST",
  //   body: params,//JSON.stringify(params),
  //   headers: {
  //     'Content-Type': 'multipart/form-data; boundary=gc0p4Jq0M2Yt08jU534c0p',
  //     'Accept': 'application/json',

  //     Authorization: `Bearer ${token}`,
  //   },
  // };
  // const response = apiCall({ url, options });
  // return response;
  axios({
    method: "PATCH",
    //processData: false,
    //contentType: false,
    //enctype: 'multipart/form-data',
    //cache: false,
    url: url,
    data: params,
    headers: {
      //'Access-Control-Allow-Origin': '*',
      //'Content-Type':'application/x-www-form-urlencoded',
      // Accept: "application/json",
      "Content-Type": "multipart/form-data; boundary=gc0p4Jq0M2Yt08jU534c0p",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
      console.log(response);
    })
    .catch((error) => {
      console.log("error");
    });
}

export async function createAppPage(params) {
  var url = new URL(`${_api.admin}/page/create`);
  token = localStorage.getItem("token");
  // const options = {
  //   method: "POST",
  //   body: params,//JSON.stringify(params),
  //   headers: {
  //     'Content-Type': 'multipart/form-data; boundary=gc0p4Jq0M2Yt08jU534c0p',
  //     'Accept': 'application/json',

  //     Authorization: `Bearer ${token}`,
  //   },
  // };
  // const response = apiCall({ url, options });
  // return response;
  axios({
    method: "post",
    //processData: false,
    //contentType: false,
    //enctype: 'multipart/form-data',
    //cache: false,
    url: url,
    data: params,
    headers: {
      //'Access-Control-Allow-Origin': '*',
      //'Content-Type':'application/x-www-form-urlencoded',
      // Accept: "application/json",
      "Content-Type": "multipart/form-data; boundary=gc0p4Jq0M2Yt08jU534c0p",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      console.log(response);
    })
    .catch((error) => {
      console.log("error");
    });
  // axios
  //   .post(url,
  //     params,
  //     processData: false,
  //     contentType: false,
  //     cache: false, {
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "multipart/form-data; boundary=gc0p4Jq0M2Yt08jU534c0p",
  //       Authorization: `Bearer ${token}`,
  //     },
  //   })
}
//Faqs
export async function getFAQ() {
  //console.log("inside et faq")
  var url = new URL(`${_api.admin}/faqs/1/asc/0/0`);
  token = localStorage.getItem("token");
  const options = {
    method: ApiMethods.get,
    headers: {
      Authorization: `Bearer ${token ? token : token}`,
    },
  };
  const response = apiCall({ url, options });
  console.log("response from ",response)
  return response;
}
export async function createFAQ(params,id) {
  var url = new URL(`${_api.admin}/faq/create/${id}`);
  token = localStorage.getItem("token");
  const options = {
    method: "POST",
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = apiCall({ url, options });
  console.log("response from createFAQ",response)
  return response;
 
}
export async function getAllFAQs() {
  //console.log("inside et faq")
  var url = new URL(`${baseUrl}/faqs/0/0`);
  token = localStorage.getItem("token");
  const options = {
    method: ApiMethods.get,
    headers: {
      Authorization: `Bearer ${token ? token : token}`,
    },
  };
  const response = apiCall({ url, options });
  console.log("response from getAllFAQs ",response)
  return response;
}



export async function updateQuestion(params,id) {
  var url = new URL(`${_api.admin}/faq/question/${id}`);
  token = localStorage.getItem("token");

  const options = {
    method: "PATCH",
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = apiCall({ url, options });
  
  return response;
}


export async function updateFAQ(params,id) {
  
  // console.log("id",id)
  // console.log("params",params)
  var url = new URL(`${_api.admin}/faq/${id}`);
  token = localStorage.getItem("token");

  const options = {
    method: "PATCH",
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = apiCall({ url, options });
  console.log("response in updatesubcat",response)
  return response;
}


export async function deleteFAQ(id) {
  //console.log("inside deleteFAQ",id)
  var url = new URL(`${_api.admin}/faq/${id}`);

  token = localStorage.getItem("token")
  const options = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  //console.log(options);
  const response = apiCall({ url, options });
  
  // console.log("response inside delete faq",response)
  return response;
}

export async function createQuestions(params,id) {
  // console.log("create questions")

// console.log("params create question", params,id);

  var url = new URL(`${_api.admin}/faq/question/create/${id}`);
  token = localStorage.getItem("token");
  const options = {
    method: "POST",
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  };
  const response = apiCall({ url, options });
  return response;
  }

  export async function deleteQuestion(id) {
    var url = new URL(`${_api.admin}/faq/question/${id}`);
    
  token = localStorage.getItem("token")
  const options = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  //console.log(options);
  const response = apiCall({ url, options });
  return response;
  }

  //create ad
  export async function createAd(params) {
    var url = new URL(`${_api.admin}/advertisement`);
    token = localStorage.getItem("token");
    axios({
      method: "POST",
      url: url,
      data: params,
      headers: {
        "Content-Type": "multipart/form-data;",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        return response;
       
      })
      .catch((error) => {
        console.log("error");
      });
    }

    // listing ads
    export async function getAds() {
      var url = new URL(`${_api.admin}/advertisements`);
      token = localStorage.getItem("token");
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = apiCall({ url, options });
      return response;
    }

    //update ad
    export async function updateAdv(params,id) {
      var url = new URL(`${_api.admin}/advertisement/${id}`);
      token = localStorage.getItem("token");
    
      axios({
        method: "PATCH",
        url: url,
        data: params,
        headers: {
          "Content-Type": "multipart/form-data;",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          return response;
         
        })
        .catch((error) => {
          console.log("error");
        });

    }
 
export async function resetCounter(id) {
  var url = new URL(`${_api.admin}/advertisement/${id}/counter/reset`);
  token = localStorage.getItem("token");

  const options = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = apiCall({ url, options });
  
  return response;
}