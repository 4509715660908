import React from "react";
//import axios from  '../bin/api/config';
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import Edit from "./Edit";
import Delete from "./Delete";
import Block from "./Block";
import swal from "sweetalert";
import ReactTooltip from "react-tooltip";

//import {User} from "../../bin/models/StaticTypes"

let editUser = {
  city: "",
  firstName: "",
  lastName: "",
  street: "",
  zip: "",
  phone: "",
};

function Table({ stateUser, onHide, onShow, onDelete, onBlock, onEdit }) {
  // const [edit, user] =React.useState(
  //   selectedUser
  // )
  function handleEdit(row) {
    stateUser.selecteduser = row.original;
    editUser = {
      city: row.original.city,
      firstName: row.original.firstName,
      lastName: row.original.lastName,
      street: row.original.street,
      zip: row.original.zip,
      phone: row.original.phone,
    };
    onEdit();
    //onShow();
  }

  function handleDelete(row) {
    stateUser.selecteduser = row.original;
    onDelete();
  }

  function handleBlock(row) {
    stateUser.selecteduser = row.original;
    onBlock();
  }

  function handleSave(row) {
    if (
      editUser.firstName.trim() === "" ||
      row.original.email.trim() === "" ||
      editUser.phone.trim() === "" ||
      editUser.street.trim() === "" ||
      editUser.zip.trim() === "" ||
      editUser.city.trim() === ""
    ) {
      swal({
        title: "Vennligst fyll ut de tomme detaljene.",
        button: {
          text: "OK",
          value: true,
          visible: true,
          className: "--confirm",
          closeModal: true,
        },
      });
    } else {
      stateUser.selecteduser = null;
      onHide();

      var params = {
        first_name: editUser.firstName,
        last_name: editUser.lastName,
        email: row.original.email,
        phone: editUser.phone,
        street: editUser.street,
        zip: editUser.zip,
        city: editUser.city,
        //latitude: "11.111111",
        //longitude: "22.222222",
      };

      editUser = {
        city: "",
        firstName: "",
        lastName: "",
        street: "",
        zip: "",
        phone: "",
      };

      var values = {
        id: row.original.id,
        userinfo: { ...params },
      };

      stateUser.updateUsers(values);
      document.getElementById("searchUser").value = "";
      swal({
        title: "Du har oppdatert brukeren",
        button: {
          text: "OK",
          value: true,
          visible: true,
          className: "--confirm",
          closeModal: true,
        },
      });
    }
  }

  function handleFilter(e) {
    const filter = e.target.value.toString().toLowerCase();
    var key = e.keyCode || e.charCode;
    if (key === 8 || key === 46) {
      filterRecords(filter);
    } else {
      if (filter.length >= 3) {
        filterRecords(filter);
      }
    }
  }

  function filterRecords(filter) {
    var dd = stateUser.users;
    var gg = dd.filter(
      (it) =>
        it.email.toLowerCase().includes(filter) ||
        it.id.toString().includes(filter) ||
        it.firstName.toLowerCase().toString().includes(filter) ||
        it.lastName.toLowerCase().toString().includes(filter) ||
        it.phone.toLowerCase().toString().includes(filter) ||
        it.street.toLowerCase().toString().includes(filter) ||
        it.zip.toLowerCase().toString().includes(filter) ||
        it.city.toLowerCase().toString().includes(filter)
    );

    stateUser.filtered = gg;

    stateUser.setfilteredUsers(stateUser);
  }

  function handleKeyPress(e, row) {
    console.log(editUser);
    switch (row.column.Header) {
      case "Sted":
        editUser.city = e.target.value;
        return;
      case "Fornavn":
        editUser.firstName = e.target.value;
        console.log(editUser.firstName);
        return;
      case "Etternavn":
        editUser.lastName = e.target.value;
        return;
      case "Gate":
        editUser.street = e.target.value;
        return;
      case "Post nr.":
        editUser.zip = e.target.value;
        return;
      case "Telefon":
        editUser.phone = e.target.value;
        return;
      default:
        return;
    }
  }

  const columns = [
    {
      Header: "Id",
      accessor: "id",
      filterable: false,
      width: "50",
      show: false,
    },
    {
      Header: "IsBlocked",
      accessor: "isBlocked",
      filterable: false,
      width: "50",
      show: false,
    },
    {
      Header: "E-post",
      accessor: "email",
      width: "250",
      Cell: (row) => <span>{row.original.email}</span>,
    },
    {
      Header: "Telefon",
      accessor: "phone",
      width: "130",
      Cell: (row) =>
        stateUser.selecteduser !== null &&
        stateUser.isDelete === false &&
        stateUser.isBlock === false ? (
          stateUser.selecteduser.id === row.original.id ? (
            <input
              style={{ width: "100%" }}
              type="text"
              defaultValue={row.original.phone}
              onKeyUp={(e) => handleKeyPress(e, row)}
            ></input>
          ) : (
            row.original.phone
          )
        ) : (
          row.original.phone
        ),
    },
    {
      Header: "Fornavn",
      accessor: "firstName",
      width: "100",
      Cell: (row) =>
        stateUser.selecteduser !== null &&
        stateUser.isDelete === false &&
        stateUser.isBlock === false ? (
          stateUser.selecteduser.id === row.original.id ? (
            <input
              style={{ width: "100%" }}
              type="text"
              defaultValue={row.original.firstName}
              onKeyUp={(e) => handleKeyPress(e, row)}
            ></input>
          ) : (
            row.original.firstName
          )
        ) : (
          row.original.firstName
        ),
    },
    {
      Header: "Etternavn",
      accessor: "lastName",
      width: "120",
      Cell: (row) =>
        stateUser.selecteduser !== null &&
        stateUser.isDelete === false &&
        stateUser.isBlock === false ? (
          stateUser.selecteduser.id === row.original.id ? (
            <input
              style={{ width: "100%" }}
              type="text"
              defaultValue={row.original.lastName}
              onKeyUp={(e) => handleKeyPress(e, row)}
            ></input>
          ) : (
            row.original.lastName
          )
        ) : (
          row.original.lastName
        ),
    },
    {
      Header: "Gate",
      accessor: "street",
      width: "250",
      editable: true,
      Cell: (row) =>
        stateUser.selecteduser !== null &&
        stateUser.isDelete === false &&
        stateUser.isBlock === false ? (
          stateUser.selecteduser.id === row.original.id ? (
            <input
              style={{ width: "100%" }}
              type="text"
              defaultValue={row.original.street}
              onKeyUp={(e) => handleKeyPress(e, row)}
            ></input>
          ) : (
            row.original.street
          )
        ) : (
          row.original.street
        ),
    },
    {
      Header: "Post nr.",
      accessor: "zip",
      width: "80",
      Cell: (row) =>
        stateUser.selecteduser !== null &&
        stateUser.isDelete === false &&
        stateUser.isBlock === false ? (
          stateUser.selecteduser.id === row.original.id ? (
            <input
              style={{ width: "100%" }}
              type="text"
              defaultValue={row.original.zip}
              onKeyUp={(e) => handleKeyPress(e, row)}
            ></input>
          ) : (
            row.original.zip
          )
        ) : (
          row.original.zip
        ),
    },
    {
      Header: "Sted",
      accessor: "city",
      width: "120",
      Cell: (row) =>
        stateUser.selecteduser !== null &&
        stateUser.isDelete === false &&
        stateUser.isBlock === false ? (
          stateUser.selecteduser.id === row.original.id ? (
            <input
              style={{ width: "100%" }}
              type="text"
              defaultValue={row.original.city}
              onKeyUp={(e) => handleKeyPress(e, row)}
            ></input>
          ) : (
            row.original.city
          )
        ) : (
          row.original.city
        ),
    },
    {
      Header: "Handling",
      accessor: "id",
      width: "100",
      sortable: false,
      filterable: false,
      Cell: (row) => (
        <div>
          {stateUser.selecteduser === null ? (
            <span onClick={() => handleEdit(row)} style={{ cursor: "pointer" }}>
              <img
                alt=""
                src={require("../../assets/edit.svg")}
                data-tip="Edit"
              />
            </span>
          ) : stateUser.selecteduser.id === row.original.id ? (
            <span onClick={() => handleSave(row)} style={{ cursor: "pointer" }}>
              {" "}
              <img
                alt=""
                src={require("../../assets/tick.svg")}
                data-tip="Save"
              />
            </span>
          ) : (
            <span onClick={() => handleEdit(row)} style={{ cursor: "pointer" }}>
              <img
                alt=""
                src={require("../../assets/edit.svg")}
                data-tip="Edit"
              />
            </span>
          )}
          &nbsp;&nbsp;
          <span onClick={() => handleDelete(row)} style={{ cursor: "pointer" }}>
            <img
              alt=""
              src={require("../../assets/delete.svg")}
              data-tip="Delete"
            />
          </span>
          &nbsp;&nbsp;
          <span onClick={() => handleBlock(row)} style={{ cursor: "pointer" }}>
            <img
              alt=""
              src={require("../../assets/block.svg")}
              data-tip={row.original.isBlocked ? "Unblock" : "Block"}
            />
          </span>
          {/* <button
            onClick={() => handleEdit(row)}
            className="glyphicon glyphicon-pencil"
          > */}
          {/* Edit */}
          {/* </button> */}
          {/* <button
            onClick={() => handleDelete(row)}
            className="glyphicon glyphicon-pencil"
          >
            Delete
          </button> */}
          {/* <span className="glyphicon glyphicon-pencil"></span> */}
          {/* <button onClick={() => handleDelete(row.original)}>Delete</button> */}
          <ReactTooltip />
        </div>
      ),
    },
  ];
  return (
    <div>
      <div className="row ">
        <div className="col-9 inline-block ">
          <span
            style={{
              fontFamily: "Roboto",
              fontWeight: "700",
              fontSize: "14px",
            }}
          >
            <img alt="" src={require("../../assets/users-active.svg")} />
            <span style={{ marginLeft: "7px" }}>BRUKERE</span>
          </span>
        </div>
        <div className="col-3 inline-block ">
          <input
            id="searchUser"
            style={{ width: "100%" }}
            type="text"
            onKeyUp={handleFilter}
            className="prompt"
            placeholder="  Søk "
          />
        </div>
      </div>
      <br />
      <div>
        {stateUser.users ? (
          <div>
            <ReactTable
            previousText="Tilbake"  nextText="Neste"
            pageText="Side" ofText="av"
            rowsText="rader"
              className="bg-white user-table"
              style={{
                fontSize: "14px",
                fontFamily: "Roboto",
                paddingLeft: "0px",
              }}
              minRows={1}
              noDataText="Ingen Rader Funnet"
              columns={columns}
              data={stateUser.filtered}
              filterable={false}
              sortable
              defaultPageSize={25}
              resizable={false}
              getTrProps={(state, rowInfo, instance) => {
                if (rowInfo) {
                  return {
                    style: {
                      color: rowInfo.original.isBlocked ? "red" : "#68707d",
                    },
                  };
                }
                return {};
              }}
            ></ReactTable>

            {stateUser.isDelete ? (
              <Delete state={stateUser} onHide={onHide} />
            ) : stateUser.isBlock ? (
              <Block state={stateUser} onHide={onHide} />
            ) : (
              <Edit state={stateUser} onHide={onHide} />
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default Table;
