import React from "react";
import { Link } from "react-router-dom";
export default class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adDetails: props?.data
        ? props?.data.adDetails
        : {
            adTitle: "",
            adDescription: "",
            adLinkLabel: "",
            adLink: "",
          },
      adImage: props?.data ? props?.data.adImage : {},
      selectedFile: null,
      errors: {},
      imageData: null,
    };
    this.hiddenFileInput = React.createRef(null);
  }
  handleChange = (e, name) => {
    const { adDetails, errors } = this.state;
    adDetails[`${name}`] = e.target.value;
    errors[`${name}`] = "";
    this.setState({
      adDetails,
    });
  };
  handleSave = (e) => {
    e.preventDefault()
    const { adDetails, adImage } = this.state;
   
    var form_data = new FormData();
    if(adImage.name){
      form_data.append("adImage", adImage, adImage.name);
    }
    else{
      form_data.append("adImage", JSON.stringify(adImage));
    }
   
    form_data.set("adDetails", JSON.stringify(adDetails));
    if (this.handleValidation()) {
     return this.props.updateAd(form_data);
    }
  };

  handleClick = (e) => {
    e.preventDefault();
    this.hiddenFileInput.current.click();
  };
  handleChangeFile = (event) => {
    const fileUploaded = event.target.files[0];
    const { errors } = this.state;
    errors["adImage"] = "";
    this.setState({
      adImage: fileUploaded,
      errors,
    });
  };
  handleValidation() {
    let { adDetails, adImage } = this.state;
    let errors = {};
    let formIsValid = true;
    let linkFormat=/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
    if (!adDetails["adTitle"]) {
      formIsValid = false;
      errors["adTitle"] = "*Obligatorisk felt";
    }
    if (!adDetails["adDescription"]) {
      formIsValid = false;
      errors["adDescription"] = "*Obligatorisk felt";
    }
    if (!adDetails["adLinkLabel"]) {
      formIsValid = false;
      errors["adLinkLabel"] = "*Obligatorisk felt";
    }
    if (!adDetails["adLink"]) {
      formIsValid = false;
      errors["adLink"] = "*Obligatorisk felt";
    }
    if (adDetails["adLink"]) {
      if(!linkFormat.test(adDetails["adLink"])){

        formIsValid = false;
        errors["adLink"] = "*Skriv inn riktig lenke";
      }
      
    }
    
    if (!adImage["name"]) {
      if(!adImage.length){
        formIsValid = false;
        errors["adImage"] = "*Obligatorisk felt";
      }
    }
    this.setState({ errors: errors });
    return formIsValid;
  }
  clearImage=()=>{
    this.setState({
      adImage: {},
    });
  }
  render() {
    const { adDetails, adImage, errors } = this.state;

    return (
      <>
        <div className="bg-component" style={{ padding: "25px" }}>
          <h6
            style={{
              color: "#1657d5",
              fontSize: "14px",
              fontWeight: "500",
              fontFamily: "Roboto",
            }}
            className="text-decor"
          >
            <Link to="/advertisement">
              <i className="fa fa-angle-left"></i>
              &nbsp;tilbake
            </Link>
          </h6>
          <br />
          <form onSubmit={this.handleSave} encType="multipart/form-data">
            <div>
              <label>Bilde </label>
              {/* <br /> */}
              {adImage.length && 
              <>
                  <div style={{...styles.imageDisplay, background:`url(${adImage})`,backgroundRepeat:"no-repeat", backgroundSize:"cover"}}>
                  <img
                    mode="view"
                    onClick={this.clearImage}
                    alt="icon "
                    src={require("../../assets/delete-icon.svg")}
                    style={{
                     float:"right",
                     cursor: "pointer",
                    }}
                  />
                    </div>
                    <br/>
                </>
                }
               
              <div style={{ display: "flex" }}>
                
                <div style={styles.uploadDiv}>
                  {adImage?.name ? (
                    <span>{adImage?.name}</span>
                  ) : (
                    <span style={{ color: "grey" }}>{adImage.length?"valgt fil" :"Ingen fil valgt"}</span>
                  )}
                </div>
                <button
                  className="Rectangle-Copy"
                  style={styles.chooseFileButton}
                  onClick={this.handleClick}
                >
                  Velg Fil
                </button>
                <input
                  type="file"
                  accept="image/*"
                  ref={this.hiddenFileInput}
                  onChange={this.handleChangeFile}
                  style={{ display: "none" }}
                  name="adImage"
                />
              </div>
              <span style={{ color: "red" }}>{errors["adImage"]}</span>
            </div>
           
            <div style={{marginTop:"22px"}}>
              <label>Tittel</label>
              <br />
              <input
                type="text"
                name="title"
                className="adInputs"
                style={styles.inputs}
                value={adDetails.adTitle}
                onChange={(e) => {
                  this.handleChange(e, "adTitle");
                }}
                placeholder="Tittel"
              />
              <br />
              <span style={{ color: "red" }}>{errors["adTitle"]}</span>
            </div>
            
            <div style={{marginTop:"22px"}}>
              <label>Tekst</label>
              <br />
              <textarea
                type="text"
                name="text"
                className="adInputs"
                style={styles.inputs}
                value={adDetails.adDescription}
                onChange={(e) => {
                  this.handleChange(e, "adDescription");
                }}
                placeholder="Tekst"
              />
              <br />
              <span style={{ color: "red" }}>{errors["adDescription"]}</span>
            </div>
           
            <div style={{ display: "flex", marginTop:"17px"}}>
              <div>
                <label>Lenke</label>
                <br />
                <input
                  type="text"
                  style={styles.inputs}
                  className="adInputs"
                  value={adDetails.adLinkLabel}
                  onChange={(e) => {
                    this.handleChange(e, "adLinkLabel");
                  }}
                  placeholder="Merkelapp"
                />
                <br />
                <span style={{ color: "red" }}>{errors["adLinkLabel"]}</span>
              </div>

              <div style={{ margin: "33px 15px" }}>
                <input
                  type="text"
                  className="adInputs"
                  style={styles.inputs}
                  value={adDetails.adLink}
                  onChange={(e) => {
                    this.handleChange(e, "adLink");
                  }}
                  placeholder="Lenke"
                />
                <br />
                <span style={{ color: "red" }}>{errors["adLink"]}</span>
              </div>
            </div>
            <br />
            <input 
              type="submit"
              className="Rectangle-Copy"
              // onClick={this.handleSave}
              style={styles.saveButton}
              value="LAGRE"
            />
            
          </form>
        </div>
      </>
    );
  }
}

const styles = {
  saveButton: {
    width: "118px",
    height: "38px",
    color: "white",
    textAlign: "center",
    padding: "8px",
    cursor: "pointer",
    border: "none",
    borderRadius: "5px",
  },
  custom_file_upload: {
    border: "1px solid #ccc",
    display: "inline-block",
    padding: "6px 12px",
    cursor: "pointer",
  },
  uploadDiv: {
    border: "1px solid grey",
    padding: "7px 10px",
    width: "150px",
    borderRadius: "5px",
    background:"white",
    
  },
  chooseFileButton: {
    width: "118px",
    color: "white",
    textAlign: "center",
    padding: "8px",
    cursor: "pointer",
    border: "none",
    boxShadow: "none",
    borderRadius: "5px",
    marginLeft:"-5px"
  },
  inputs: {
    padding: "7px 10px",
    borderRadius: "5px",
    border: "1px solid grey",
    width: "267px",
  },
  imageDisplay:{
    width:"150px",
    height:"150px",
    marginTop:"5px"
  }
};
