import React from "react";
import { Route, Redirect } from "react-router-dom";

import {
  userRoutes,
  dashboardRoutes,
  messageRoutes,
  jobRoutes,
  categoryRoutes,
  appPageTableRoutes,
  freeRoutes,
  advertisementRoutes
} from "../bin/config/routes";
// import { useStateValue } from '../bin/config/AppContext';

// Pages
import UserComponent from "./Users";
import DashboardComponent from "./Dashboard";
import LoginComponent from "../components/Login";
import MessageComponent from "./Message";
import JobComponent from "./Job";
import CategoryComponent from "./Categories";
import AppPagesComponent from "./AppPages";
import FreeRoutes from "./FreeRoutes";
import AdvertisementComponent from "./Advertisement";

import { useStateValue } from "../bin/config/AppContext";

// Pages
// import UserComponent from './Users';
// import DashboardComponent from './Dashboard';

// Global Components

import NavMenuComponent from "../components/sidebar";

function IndexRouterComponent(prop) {
  const { authenticated } = useStateValue();
  if (!authenticated) {
    return (
      <div>
        {prop.location.pathname.toString().includes("pages/") ? (
          <Route exact path={freeRoutes} component={FreeRoutes} />
        ):
        (
          <>
          {localStorage.getItem("token")===null?(
            <>
        <Redirect to="/" />
        <Route exact path="/" component={LoginComponent}/>
          </>)
          :
          (<>
          

              <Route exact path={messageRoutes} component={LoginComponent} />
        <Route exact path={jobRoutes} component={LoginComponent} />
        <Route exact path={dashboardRoutes} component={LoginComponent} />
        <Route exact path={categoryRoutes} component={LoginComponent} />
        <Route exact path={userRoutes} component={LoginComponent} />
        <Route exact path={appPageTableRoutes} component={LoginComponent} />
        <Route exact path={freeRoutes} component={FreeRoutes} /> 
        <Route exact path={advertisementRoutes} component={LoginComponent} /> 
          </>)
  }
          </>
        )
  }
       
      </div>
    );
  } else {
    return (
      <div>
        {prop.location.pathname.toString().includes("pages/") ? (
          <Route exact path={freeRoutes} component={FreeRoutes} />
        ) : (
          <>
          
          <div id="" className="row" style={{marginRight:0}}>
            <div className="col-md-2">
              <NavMenuComponent />
            </div>

            <div className="col-md-10" style={{ display: "inline-block" , padding:0}}>
              <div className="col-12" style={{ height: "60px",boxShadow:"0 2px 4px 0 rgba(34, 57, 79, 0.06)" }}>
                <br></br>
              <span style={{float:"right"}}>{localStorage.getItem("userName")}</span>
              </div>
              <Route exact path={userRoutes} component={UserComponent} />
              <Route
                exact
                path={dashboardRoutes}
                component={DashboardComponent}
              />
              <Route exact path={messageRoutes} component={MessageComponent} />
              <Route exact path={jobRoutes} component={JobComponent} />
              <Route
                exact
                path={categoryRoutes}
                component={CategoryComponent}
              />
              <Route
                exact
                path={appPageTableRoutes}
                component={AppPagesComponent}
              />
                <Route
                exact
                path={advertisementRoutes}
                component={AdvertisementComponent}
              />
            </div>
          </div>
          </>
        )}
      </div>
    
    );
  }
}

export default IndexRouterComponent;
