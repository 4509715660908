import React from "react";
import { Modal, Button } from "react-bootstrap";
import swal from "sweetalert";

const faqTitle = React.createRef();

export default function AddFAQ({ state, onHide }) {
  console.log("addFAQ", state.pageData);

  const selectedPage = state.pageData;
  function onSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    var recordId = selectedPage.id;
    var values = {};
    var params = {};

    formData.forEach((v, k) => {
      Object.assign(params, { [k]: v });
    });

    values = {
      id: recordId,
      faqinfo: { ...params },
      // categoryinfo: { subCategoryName: subCategoryName.current !== null ? subCategoryName.current.value : "" },
    };

    Promise.resolve(state.postFAQ(values))
      .then((error) => {
        if (error === undefined || error === null) {
          onHide();
          swal({
            title: "Du har lagt til vanlige spørsmål",
            button: {
              text: "OK",
              value: true,
              visible: true,
              className: "--confirm",
              closeModal: true,
            },
          });
        } else {
          document.getElementById("form-errors").innerText =
            error.error_message;
        }
      })
      .catch((error) => {
        swal({
          title: error.error_message,
          button: {
            text: "OK",
            value: true,
            visible: true,
            className: "--confirm",
            closeModal: true,
          },
        });
      });
  }
  function handleErrors(e) {
    e.target.value = e.target.value.trimLeft();
  }

  return (
    <Modal show={state.modal} onHide={onHide} centered backdrop="static">
      <Modal.Header
      // style={{
      //   backgroundColor:"rgb(31, 160, 91)"
      // }}
      >
        <Modal.Title
          style={{
            marginLeft: "0",
            marginRight: "auto",
            display: "block",
          }}
        >
        Legg Til Vanlige Spørsmål
        </Modal.Title>
        <Button
          type="button"
          onClick={onHide}
          variant="outline-secondary"
          className="close"
          style={{ width: "50px" }}
        >
          &times;
        </Button>
      </Modal.Header>
      <Modal.Body>
        {/* <!-- Input fields come here --> */}
        <div>
          <div id="error"></div>
          {state.pageData ? (
            <form
              id="postFAQ"
              style={{ marginTop: "20px" }}
              onSubmit={onSubmit}
            >
              <input
                type="text"
                hidden={true}
                disabled={true}
                name="id"
                defaultValue={selectedPage.id}
              />

              <div className="row" style={{ textAlign: "left" }}>
                <div className="col-12">
                  <label className="col-3" style={{ textAlign: "left" }}>
                   FAQ Tittel
                  </label>
                  <input
                    style={{ textAlign: "left", width: "100%" }}
                    className="col-8"
                    type="text"
                    ref={faqTitle}
                    placeholder="Skriv Inn FAQ-Tittel"
                    name="faqTitle"
                    required
                    onChange={handleErrors}
                  />
                  <span id="form-errors"></span>
                </div>
                <div className="col-12" style={{ textAlign: "center" }}>
                  <input
                    className="btn text-light"
                    type="submit"
                    value="SAVE"
                    style={{ textAlign: "center", backgroundColor: "#1657d5" }}
                  />
                </div>
              </div>
            </form>
          ) : (
            <span className="form-errors"></span>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}
