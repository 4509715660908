import React from "react";
import Modal from "react-bootstrap/Modal";

export function generateDate(dateString, format) {
  let date = new Date(dateString);
  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  var yyyy = date.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  switch (format) {
    case "DD-MM-YYYY":
      return dd + "-" + mm + "-" + yyyy;

    default:
      return yyyy + "-" + mm + "-" + dd;
  }
}

export function filterCaseInsensitive(filter, row) {
  const id = filter.pivotId || filter.id;
  return row[id] !== undefined
    ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
    : true;
}

// Loading
export function LoadingModal(props) {
  return (
    <Modal {...props} id="loading" style={{backgroundColor:"transparent !important"}}>
      
      <Modal.Body style={{backgroundColor:"transparent !important"}}>
        <div style={{textAlign:"center"}}>
        {/* <img style={{height:"50%", width:"50%"}} alt="" src={require("../../assets/loader.gif")} /> */}
        <div className="spinner-border text-primary"></div>
        </div>
      
      </Modal.Body>
    </Modal>
  );
}

//modal for successfull operations display
export default function LoadingSuccessModal(props) {
  return (
    <Modal >
      {/* <Modal.Header>
        <Modal.Title>Loading</Modal.Title>
      </Modal.Header> */}
      <Modal.Body>successfully Added A New Record!!</Modal.Body>
    </Modal>
  );
}