import React from "react";
import { Modal, Button } from "react-bootstrap";
import swal from "sweetalert";
const faqTitle = React.createRef();
export default function EditFAQ({ state, onHide, onFAQEdit, onFAQEditOff }) {
  console.log("inside editFaq");
  const faqdata = state.selectedFAQ;
  console.log("faqdata", faqdata);
  function onSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    /////////text editor//////////////
    // let formData1= new FormData();
    // formData1.set("faqTitle", e.target.faqTitle.value);
    //state.saveAppPage(state.pageData.id, formData1);
    ////////////////////////////////
    var recordId = faqdata.id;
    var values = {};
    var params = {};

    formData.forEach((v, k) => Object.assign(params, { [k]: v }));

    values = {
      id: recordId,
      faqinfo: { ...params },
    };

    Promise.resolve(state.editFAQ(values))
      .then((error) => {
        if (error === undefined || error === null) {
          onHide();
          swal({
            title: "Du har oppdatert vanlige spørsmål",
            button: {
              text: "OK",
              value: true,
              visible: true,
              className: "--confirm",
              closeModal: true,
            },
          });
        } else {
          document.getElementById("error").innerText = error.error_message;
        }
      })
      .catch((error) => {
        swal({
          title: error.error_message,
          button: {
            text: "OK",
            value: true,
            visible: true,
            className: "--confirm",
            closeModal: true,
          },
        });
      });
  }

  function handleErrors(e) {
    e.target.value = e.target.value.trimLeft();
  }
  return (
    <Modal show={state.modal} onHide={onHide} centered backdrop="static">
      <Modal.Header
      // style={{
      //   backgroundColor:"rgb(31, 160, 91)"
      // }}
      >
        <Modal.Title
          style={{
            marginLeft: "0",
            marginRight: "auto",
            display: "block",
          }}
        >
         Rediger Vanlige Spørsmål
        </Modal.Title>
        <Button
          type="button"
          onClick={onHide}
          variant="outline-secondary"
          className="close"
          style={{ width: "50px" }}
        >
          &times;
        </Button>
      </Modal.Header>
      <Modal.Body>
        {/* <!-- Input fields come here --> */}
        <div>
          <div id="error"></div>
          {faqdata ? (
            <form
              id="editFAQ"
              style={{ marginTop: "20px" }}
              onSubmit={onSubmit}
            >
              <input
                type="text"
                hidden={true}
                disabled={true}
                defaultValue={faqdata.id}
              />

              <div className="row" style={{ textAlign: "left" }}>
                <div className="col-12">
                <label className="col-3" style={{ textAlign: "left" }}>
                FAQ Tittel
                  </label>
                  <input
                    style={{ textAlign: "left", width: "100%" }}
                    className="col-8"
                    type="text"
                    defaultValue={faqdata.title}
                    name="faqTitle"
                    ref={faqTitle}
                    required
                    onChange={handleErrors}
                  />
                  <span className="form-errors"></span>
                </div>
                <div className="col-12" style={{ textAlign: "center" }}>
                  <input
                    className="btn text-light"
                    type="submit"
                    value="SAVE"
                    style={{ textAlign: "center", backgroundColor: "#1657d5" }}
                  />
                </div>
              </div>
            </form>
          ) : (
            <h2>Ingen Data</h2>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}
