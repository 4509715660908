import React from "react";
import { Modal, Button } from "react-bootstrap";
import swal from "sweetalert";
export default function FAQDelete({ state, onHide }) {
  const faqdata = state.selectedFAQ;
  console.log("faqdata in FAQDelete", state);
  function onCancel(e) {
    e.preventDefault();
    onHide();
  }
  function onSubmit(e) {
    e.preventDefault();
    var recordId = faqdata.id;
    var params = {};
    params = {
      id: recordId,
    };
    state.removeFAQ(params);
    onHide();
    swal({
      title: "Du har slettet vanlige spørsmål",
      button: {
        text: "OK",
        value: true,
        visible: true,
        className: "--confirm",
        closeModal: true,
      },
    });
  }

  return (
    <Modal show={state.modal} onHide={onHide} centered backdrop="static">
      <Modal.Header
      // style={{
      //   backgroundColor:"rgb(31, 160, 91)"
      // }}
      >
        <Modal.Title
          style={{
            marginLeft: "0",
            marginRight: "auto",
            display: "block",
          }}
        >
          Slett Vanlige Spørsmål
        </Modal.Title>
        <Button
          type="button"
          onClick={onHide}
          variant="outline-secondary"
          className="close"
          style={{ width: "50px" }}
        >
          &times;
        </Button>
      </Modal.Header>
      <Modal.Body>
        {/* <!-- Input fields come here --> */}
        <div>
          {faqdata ? (
            <form id="removeFAQ" onSubmit={onSubmit}>
              <input
                type="text"
                hidden={true}
                disabled={true}
                defaultValue={faqdata.id}
              />
              <div className="row" style={{ textAlign: "center" }}>
                <div className="col-12" style={{ textAlign: "center" }}>
                  <div className="col-12">
                    <span className="col-12 text-dark">
                      <h5>Er du sikker på at du vil slette FAQ?</h5>
                    </span>
                    <br />
                    <input
                      className="btn text-light bg-danger col-6"
                      type="submit"
                      value="SLETTE"
                      style={{ textAlign: "center" }}
                    />
                    <span className="col-1"></span>
                    <input
                      className="btn text-light bg-warning col-6"
                      type="button"
                      value="AVBRYTE"
                      onClick={onCancel}
                      style={{ textAlign: "center" }}
                    />
                  </div>
                </div>
              </div>
            </form>
          ) : (
            <></>
          )}
        </div>{" "}
      </Modal.Body>
    </Modal>
  );
}
