import Axios from 'axios';
const axios = Axios.create({
  baseURL: 'https://api.smaatjenester.no/api/v1',
});
export default axios;

export const baseUrl = 'https://api.smaatjenester.no/api/v1'

export const api = {
  admin: `${baseUrl}/admin`,
  page: `${baseUrl}/page`,
}
