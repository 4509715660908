import React from "react";
import Table from "./Table";
import {
  getMessages,
  getMessageTypesUsers,
  sendMessage,
  deleteMessage,
} from "../../bin/controllers/api";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { LoadingModal } from "../../bin/common/commonFunctions";
import { Message } from "../../bin/models/StaticTypes";
export default function MessageComponent() {
 
  const [stateMessage, setStateMessage] = React.useState({
    
    inbox: [],
    archived: [],
    selectall: false,
    modal: false,
    isView:false,
    isDelete:false,
    isHistortTab: false,
    checked: [],
    updateChecked: updateChecked.bind(this),
    error: false,
    filteredInbox: [],
    filteredArchived: [],
    emails: [],
    zipcodes: [],
    selectedMessage : Message | null,
    saveMessage: saveMessage.bind(this),
    deleteMessages: deleteMessages.bind(this),
    setfilteredData: setfilteredData.bind(this),
  });

  const [loading, setLoading] = React.useState(false);

  function setfilteredData(filtereddata) {
    setStateMessage({
      ...filtereddata,
    });
  }
  function updateChecked(param) {
    console.log(param);
    setStateMessage(param);
  }

  async function saveMessage(param) {
    //const data = JSON.stringify({ ...param });
    const data1 = await sendMessage({ ...param });
    loadMessages();
  }
  async function deleteMessages(param) {
    //const data = JSON.stringify(param);
    const data1 = await deleteMessage({ ...param });
    loadMessages();
  }

  async function loadMessages(filtereddata) {
    setLoading(true);
    const data1 = await getMessages("both");
    const emailData = await getMessageTypesUsers("email");
    const zipcodeData = await getMessageTypesUsers("zipcode");
    setStateMessage({
      ...stateMessage,
      inbox: data1.data.inbox,
      archived: data1.data.archived,
      filteredInbox: data1.data.inbox,
      filteredArchived: data1.data.archived,
      emails: emailData.data.sort(),
      zipcodes: zipcodeData.data.sort(),
      selectedMessage:null
    });
    setLoading(false);
  }
  function startsection() {
    if (stateMessage.inbox || stateMessage.archived) {
      return (
        <div className="">
          <div className="col-md-12 bottom-height-table">
            <Table
              stateMessage={stateMessage}
              
             
              onMessageView={()=>setStateMessage({ ...stateMessage, modal: true,isView:true,isDelete:false})}
              onHide={() => setStateMessage({ ...stateMessage, modal: false })}
              onShow={() => setStateMessage({ ...stateMessage, modal: true, isDelete:false,isView:false})}
              onDelete={() => setStateMessage({ ...stateMessage, modal: true, isDelete:true,isView:false })}
              selectAll={() =>
                setStateMessage({ ...stateMessage, selectall: true })
              }
              deselectAll={() =>
                setStateMessage({ ...stateMessage, selectall: false })
              }
            ></Table>
          </div>
        </div>
      );
    }
  }

  const handleChange = React.useCallback(() => {
    if (stateMessage.inbox.length > 0 || stateMessage.archived.length > 0 || stateMessage.emails.length > 0)
      return;
    loadMessages();
  }, [loadMessages, stateMessage]);

  React.useEffect(handleChange, [stateMessage]);
  return (
    <div>
      {stateMessage.error ? (
        <div>Noe Gikk Galt</div>
      ) : (
        <div className=" bg-component" >
          <br />
          <section style={{overflow:"auto"}}>{startsection()}</section>
          <br />
        </div>
      )}
      
      <LoadingModal show={loading} centered backdrop="static" />
    </div>
  );
}
