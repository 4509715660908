import React from "react";
import { Modal, Button } from "react-bootstrap";
import { generateDate } from "../../bin/common/commonFunctions";
import { Carousel } from "react-responsive-carousel";

export default function ViewJob({ state, onHide }) {
  const userdata = state.selectedjob ? state.selectedjob.job : null;

  function getImages() {
    var images = [];
    const imaeValues = userdata.jobImages;
    for (var i = 0; i < imaeValues.length; i++) {
      images.push(
        <div style={{ height: "200px" }}>
          <img src={imaeValues[i].imagePath}  style={{ height: "200px" }}/>
        </div>
      );
    }

    return images;
  }
  return (
    <Modal show={state.modal} onHide={onHide} centered backdrop="static">
      <Modal.Header>
        <Modal.Title style={{ color: "#00a884", fontSize: "16px",fontWeight:"bold",fontFamily:"Roboto",textTransform:"uppercase"}}>
          {userdata ? userdata.title : ""}
        </Modal.Title>
        <Button
          style={{ width:"50px"}}
          type="button"
          onClick={onHide}
          variant="outline-secondary"
          className="close"
        >
          &times;
        </Button>
      </Modal.Header>
      <Modal.Body>
        {/* <!-- Input fields come here --> */}

        {userdata ? (
          <div className="container"
          style={{
            backgroundColor:"#ffffff"
          }}
          >
            <div className="row">
              <div
                className="col-12"
                style={{
                  textAlign: "center",
                  width: "100%",
                                    minHeight: "30px",
                  maxHeight: "500px",
                }}
              >
                <Carousel
                id="crou"
                showArrows={true}
                style={{ height: "100px !important" }}
              >
                {getImages()}
              </Carousel>
              {/* <img
                  alt=""
                  src={userdata.jobImages[0].imagePath}
                  style={{ height: "100%" }}
                ></img> */}
              </div>
            </div>
            <br />
            <div className="row margin-bottom" style={{ textAlign: "left" }}>
              <div className="col-6" style={{ color: "#aeaeae",fontFamily:"Roboto",fontSize:"14px",fontWeight:"500" }}>
              Publisert:
              </div>
              <div
                className="col-6"
                style={{
                  color: "#68707d",
                  fontWeight: "bold",
                  fontFamily: "Roboto",
                  fontSize:"14px",
                }}
              >
                {generateDate(userdata.createdAt, "DD-MM-YYYY")}
              </div>
            </div>
            <div className="row margin-bottom" style={{ textAlign: "left" }}>
              <div className="col-6" style={{ color: "#aeaeae",fontFamily:"Roboto",fontSize:"14px",fontWeight:"500" }}>
              Arbeidsgiver:
              </div>
              <div
                className="col-6"
                style={{
                  color: "#68707d",
                  fontWeight: "bold",
                  fontFamily: "Roboto",
                  fontSize:"14px",
                }}
              >
                {userdata.user.firstName} {userdata.user.lastName}
              </div>
            </div>
            <div className="row margin-bottom" style={{ textAlign: "left" }}>
              <div className="col-6" style={{ color: "#aeaeae",fontFamily:"Roboto",fontSize:"14px",fontWeight:"500" }}>
              Antall søkere:
              </div>
              <div
                className="col-6"
                style={{
                  color: "#68707d",
                  fontWeight: "bold",
                  fontFamily: "Robot0",
                  fontSize:"14px",
                }}
              >
                {userdata.jobHasUsers.length}
              </div>
            </div>
            <div
              className="row margin-bottom"
              style={{ textAlign: "left", color: "#aeaeae" }}
            >
              <div className="col-6" style={{ color: "#aeaeae",fontFamily:"Roboto",fontSize:"14px",fontWeight:"500" }}>
              Valgt utfører:
              </div>
              <div
                className="col-6"
                style={{
                  color: "#68707d",
                  fontWeight: "bold",
                  fontFamily: "Roboto",
                  fontSize:"14px",
                }}
              >
                {userdata.candidate
                  ? `${userdata.candidate.firstName} ${userdata.candidate.lastName}`
                  : "---"}
              </div>
            </div>
            <div
              className="row margin-bottom"
              style={{ textAlign: "left", color: "#aeaeae" }}
            >
              <div className="col-6" style={{ color: "#aeaeae",fontFamily:"Roboto",fontSize:"14px",fontWeight:"500" }}>
                Status:
              </div>
              <div
                className="col-6"
                style={{
                  color: "#68707d",
                  fontWeight: "bold",
                  fontFamily: "Roboto",
                  fontSize:"14px",
                  color: userdata.jobStatus.color
                }}
              >
                {userdata.jobStatus.translation}
              </div>
            </div>
            <div
              className="row margin-bottom"
              style={{ textAlign: "left", color: "#aeaeae" }}
            >
              <div className="col-6" style={{ color: "#aeaeae",fontFamily:"Roboto",fontSize:"14px",fontWeight:"500" }}>
              Avtalt pris :
              </div>
              <div
                className="col-6"
                style={{
                  color: "#68707d",
                  fontWeight: "bold",
                  fontFamily: "Roboto",
                  fontSize:"14px",
                }}
              >
                {userdata.price
                  }
              </div>
            </div>
            <div
              className="row margin-bottom"
              style={{ textAlign: "left", color: "#aeaeae" }}
            >
              <div className="col-6" style={{ color: "#aeaeae",fontFamily:"Roboto",fontSize:"14px",fontWeight:"500" }}>
              Beskrivelse:
              </div>
              <div
                className="col-6"
                style={{
                  color: "#68707d",
                  fontWeight: "bold",
                  fontFamily: "Roboto",
                  fontSize:"14px",
                }}
              >
                {userdata.jobDescription}
              </div>
            </div>
            <div
              className="row margin-bottom"
              style={{ textAlign: "left", color: "#aeaeae" }}
            >
              <div className="col-6" style={{ color: "#aeaeae",fontFamily:"Roboto",fontSize:"14px",fontWeight:"500" }}>
             Tilstand:
              </div>
              <div
                className="col-6"
                style={{
                  color: "#68707d",
                  fontWeight: "bold",
                  fontFamily: "Roboto",
                  fontSize:"14px",
                }}
              >
                {userdata.isVisible? "Aktiv" : "Inaktiv"}
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </Modal.Body>
    </Modal>
  );
}
