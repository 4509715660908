import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from 'react-select';
import {
  getMessageTypesUsers,
  sendMessage,
} from "../../bin/controllers/api";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import { LoadingModal } from "../../bin/common/commonFunctions";

export default function Add() {
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const history = useHistory();
  let emailData;
    let zipcodeData;
  const [state, setState]= React.useState(
    {
      saveMessage: saveMessage.bind(this),
      emails:[],
      zipcodes:[],
      selectedOption:null
    }
  )
  async function saveMessage(param) {
    let newStr=""
    //  console.log(param.comment.includes('<a'),"ifinclude")
    // if(param.comment.includes('<a')){
     

    //   let ind=param.comment.indexOf('a')
    //  newStr=param.comment.slice(0,ind+2)+'style="color:#0F836A;"'+param.comment.slice(ind+1)
   
    //   // console.log(ind,"ind")
     
    //   // console.log('index',param.comment.indexOf('a'))
    // }
  //   console.log("newstr",newStr)
  //   console.log(param,"param")
  //  param.comment=newStr
    const data1 = await sendMessage({ ...param });
    
    history.push("/messages")
  }

  async function loadData() {
    setLoading(true);
      emailData = await getMessageTypesUsers("email");
     zipcodeData = await getMessageTypesUsers("zipcode");
      const dummyEmails=emailData.data.map((e)=>({
                        ...e,
                        value: e.id,
                        label: e.email,
                    }))
// console.log('emails', dummyEmails)
    setOptions(dummyEmails)
    setState({
      ...state,
      
      emails: dummyEmails,
      zipcodes: zipcodeData.data.sort(),

    });


    // debugger
    getData(true)
   
    setLoading(false);
  }
  const handleChange = React.useCallback(() => {
    if (state.emails.length > 0)
      return;
      loadData();
  }, [loadData, state]);

  React.useEffect(handleChange, [state]);
  const [isSingle, setSingle] = React.useState(true);
  const [allUsers, setAllUsers] = React.useState(false);

  

  const editorConfiguration1 = {
    readOnly: false,
    toolbar: [
      "bold",
      "italic",
      "Link",
      "bulletedList",
      "|",
      "numberedList",
      "Heading",
      
    ],
   // removePlugins: ["Img", "Link"],
  };
  function handleAllUsers() {
    if (allUsers) {
      
      setAllUsers(false);
    } else {
      setAllUsers(true);
    }
    clear()
  }
  function getData(isTrue) {
    // debugger;
    
    // console.log(state);
    if (state.emails.length > 0 || state.zipcodes.length > 0)
    {
      process(isTrue, state.zipcodes,state.emails)

  }
  else if(emailData.data.length> 0)
  {
    process(isTrue,zipcodeData.data, emailData.data)
  }
  }
function process(isTrue, zipcodes, emails)
{
  const values = [];
    const ids = [];
  if (isTrue) {
    ids.push("0");
    values.push("Velg e-postadresse");
    emails.forEach((element) => {
      ids.push(element.id);
      values.push(element.email);
    });
  } else {
    ids.push("0");
    values.push("Velg postnummer");
    zipcodes.forEach((element) => {
      ids.push(element);
      values.push(element);
    });
  }
// console.log(values, ids, emails);
var option = "";

for (var i = 0; i < values.length; i++) {
  option += `<option id="${ids[i]}" value="${ids[i]}"> ${values[i]} </option>`;
}
// debugger
var dd = document.getElementById("category");
var dd1 = document.getElementById("category1");
var dd2 = document.getElementById("category2");
var divbroad = document.getElementById("broadcastcombo")
if (isTrue) {


if (dd !== null) {
//   dd.innerHTML = option;
  divbroad.setAttribute("hidden", true);
// //dd2.setAttribute("hidden", true);
dd.removeAttribute("hidden")
}

}
else{

if (dd1 !== null) {
  dd1.innerHTML = option;
  
  dd2.innerHTML = option;
}
dd.setAttribute("hidden", true);
divbroad.removeAttribute("hidden");
//dd2.removeAttribute("hidden");
}
}
  function handleErrors(e) {
    e.target.value = e.target.value.trimLeft();
    clear();
  }
  function clear() {
    document.getElementById("error").innerText = "";
    document.getElementById("errorSelect").innerText = "";
    document.getElementById("errorSelect2").innerText = "";
  }
  function handleSendMessage() {
    let param = {};
    clear();
    var isValid = true;
    var msg = "";

    if (isSingle) {
      if (!document.getElementById("category").lastChild.value) {
        isValid = false;
        msg = "Velg en gyldig e-post-id";
        document.getElementById("errorSelect").innerText = msg;
      } else if (document.getElementsByClassName("ck-content")[0].innerHTML.trim() === "") {
        isValid = false;
        msg = "Melding er påkrevd";
        document.getElementById("error").innerText = msg;
      }
      else if (document.getElementById("title").value.trim() === "")
      {
        isValid = false;
        msg = "Tittel er påkrevd";
        document.getElementById("error").innerText = msg;

      }
      param = {
        type: "ADMIN",
        user: document.getElementById("category").lastChild.value,
        //zipcode: "",
        comment: document.getElementsByClassName("ck-content")[0].innerHTML.trim(),
        title: document.getElementById("title").value
      };
    } else {
      if (!allUsers) {
        if (document.getElementById("category1").value === "0") {
          isValid = false;
          msg = "Velg et gyldig postnummer";
          document.getElementById("errorSelect").innerText = msg;
        }
        else if (document.getElementById("category2").value === "0") {
          isValid = false;
          msg = "Velg et gyldig postnummer";
          document.getElementById("errorSelect2").innerText = msg;
        }
        else if(document.getElementById("category2").value < document.getElementById("category1").value)
        {
          isValid = false;
          msg = "Fra postnummer kan ikke være mindre enn Til zipcode";
          document.getElementById("errorSelect").innerText = msg;
        }
      }
      
      if (document.getElementsByClassName("ck-content")[0].innerHTML.trim() === "") {
        
        isValid = false;
        msg = "Melding er påkrevd";
        document.getElementById("error").innerText = msg;
      }
       else if (document.getElementById("title").value.trim() === "")
      {
        isValid = false;
        msg = "Tittel er påkrevd";
        document.getElementById("error").innerText = msg;

      }
      if (!allUsers) {
        param = {
          type: "ADMIN_BROADCAST",
          // user: "",
          zipcodeStart: document.getElementById("category1").value,
          zipcodeEnd: document.getElementById("category2").value,
          comment: document.getElementsByClassName("ck-content")[0].innerHTML.trim(),
          title: document.getElementById("title").value
        };
      } else {
        param = {
          type: "ADMIN_ALL",
          comment: document.getElementsByClassName("ck-content")[0].innerHTML.trim(),
          title: document.getElementById("title").value
        };
      }
    }
    if (isValid) {
      const dd = state.saveMessage(param);
      
      swal({
        title: "Du har opprettet meldingen",
        button: {
          text: "OK",
          value: true,
          visible: true,
          className: "--confirm",
          closeModal: true,
        },
      });
    } else {
      // document.getElementById("errorSelect").innerText = msg;
    }
  }

  function closeModal() {
    clear();
    setSingle(true);
    
  }
  function messegeTypeClick(isTrue) {
    // debugger;
    clear();
    setSingle(isTrue);
    
    if (isTrue) {
      document.getElementById("single").classList.add("activeMessageTab");
      document.getElementById("broadcast").classList.remove("activeMessageTab");
      setAllUsers(false);
      // document.getElementById("allusers").setAttribute("hidden", true);
    } else {
      document.getElementById("broadcast").classList.add("activeMessageTab");
      document.getElementById("single").classList.remove("activeMessageTab");
      //document.getElementById("allusers").removeAttribute("hidden");
     
    }
    getData(isTrue)
    
  }
  const setValues=(value)=>{
    document.getElementById("errorSelect").innerText = "";
    setState({
      ...state,
      selectedOption:value
    })
  }

 const handleInputChange=(option,inputValue)=>{
  //  console.log( option)
   const { label, data } = option;
   return label.includes(inputValue)|| data.firstName.includes(inputValue) || data.lastName.includes(inputValue)
 
 }

  return (
<div className=" bg-component">
          <br />
          <section>
          <div className="">
          <div
      id="createMsg"
      className="col-md-12"
      // show={state.modal}
      // onHide={onHide}
      centered
      backdrop="static"
    >
      <div>
                  <h6
                    style={{
                      color: "#1657d5",
                      fontSize: "14px",
                      fontWeight: "500",
                      fontFamily: "Roboto",
                    }}
                    className="text-decor"
                  >
                    <Link to="/messages">
                    <i className="fa fa-angle-left"></i>
                    Tilbake
                    </Link>
                  </h6>
               
        
      </div>
      <div>
        
          <div className="row">
            <div className="col-md-12">
            <div
              className=""
              style={{
                color: "#4b4a6f",
                fontFamily: "Roboto",
                fontWeight: "bold",
                fontSize:"14px"
              }}
            >
              Send til
            </div>
            </div>
          </div>
         
          <div className="row">
          <div className="col-md-12">
              <div
                className="col-3 inline-block messageTypes activeMessageTab"
                id="single"
                onClick={() => messegeTypeClick(true)}
              >
                <div
                  className="allign "
                  id="single"
                  style={{ fontFamily: "Roboto", fontWeight: "bold",fontSize:"14px" }}
                >
                  Enkelt
                </div>{" "}
              </div>
              <div
                className="col-3 inline-block messageTypes"
                id="broadcast"
                onClick={() => messegeTypeClick(false)}
              >
                <div
                  className="allign"
                  id="broadcast"
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: "bold",
                    fontSize: "14px",
                  }}
                >
                  {" "}
                  Kringkaste
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <div
              className="col-md-12"
              style={{
                color: "#4b4a6f",
                fontFamily: "Roboto",
                fontWeight: "bold",
                fontSize:"14px"
              }}
            >
              {isSingle ? "Velg e-postadresse" : "Velg postnummer"}
            </div>
          </div>
          

<div className="row">
            <div className="col-md-6">
              {/* <select
                disabled={allUsers ? true : false}
                className="col-md-12"
                name="category"
                id="category"
                onChange={clear}
                style={{
                  backgroundColor: "white",
                  height: "45px",
                  border: "1px",
                  borderStyle: "solid",
                  WebkitAppearance: "menulist",
                }}
              >
                <option defaultValue="" style={{ fontFamily: "Roboto" ,fontSize:"14px"}}>
                  Select
                </option>
              </select> */}
              <Select 
              filterOption={handleInputChange}
              placeholder="Velg e-postadresse"
              options={options}
                name="category"
                id="category" 
                onChange={(values) => setValues(values)} 
                noOptionsMessage={() => "Ingen alternativer"}
                />
                
            </div>           
          </div>
          <span id="broadcastcombo">
          <div   className="row" >
            
            <div className="col-md-6" style={{
                    fontFamily: "Roboto",
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#4b4a6f",
                  }}>FRA</div>
                  <div className="col-md-6" style={{
                    fontFamily: "Roboto",
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#4b4a6f",
                  }}>TIL</div>
                  </div>
                  
                  <div id="" className="row" >
                    <div className="col-md-6">
              <select
              
                disabled={allUsers ? true : false}
                className="col-md-12"
                name="category1"
                id="category1"
                onChange={clear}
                style={{
                  display:"inline-block",
                  backgroundColor: "white",
                  height: "45px",
                  border: "1px",
                  borderStyle: "solid",
                  WebkitAppearance: "menulist",
                }}
              >
                <option defaultValue="" style={{ fontFamily: "Roboto" }}>
                  Select
                </option>
              </select>
            </div>
            
            
                  <div className="col-md-6">
              <select
                disabled={allUsers ? true : false}
                className="col-md-12"
                name="category2"
                id="category2"
                onChange={clear}
                style={{
                  backgroundColor: "white",
                  height: "45px",
                  border: "1px",
                  borderStyle: "solid",
                  WebkitAppearance: "menulist",
                }}
              >
                <option defaultValue="" style={{ fontFamily: "Roboto",fontSize:"14px" }}>
                  Select
                </option>
              </select>
            </div>           
          </div>
          </span>
          <div className="row">
            <div className="col-md-6">
              <label
                id="errorSelect"
                style={{
                  color: "red",
                  fontFamily: "Roboto",
                  fontWeight: "bold",
                  fontSize:"14px"
                }} 
              ></label>
            </div>
            <div className="col-md-6">
              <label
                id="errorSelect2"
                style={{
                  color: "red",
                  fontFamily: "Roboto",
                  fontWeight: "bold",
                  fontSize:"14px"
                }} 
              ></label>
            </div>
          </div>
          
          {isSingle ? (
            <></>
          ) : (
            <div id="allusers" className="row">
              
              <div className="col-md-6">
                <input
                  type="checkbox"
                  onChange={handleAllUsers}
                  checked={allUsers}
                />
                <span  style={{
                color: "#4b4a6f",
                fontFamily: "Roboto",
                fontWeight: "bold",
                fontSize:"14px"
              }}>&nbsp;&nbsp;Select All Users</span>
              </div>
              
            </div>
            
          )}
          {isSingle?<></>:<br/>}
<div className="row">
            <div
              className="col-md-12"
              style={{
                color: "#4b4a6f",
                fontFamily: "Roboto",
                fontWeight: "bold",
                fontSize:"14px"
              }}
            >
              Tittel
            </div>
          </div>
          <div id="allusers" className="row">
              <div className="col-md-12">
              <input type="textbox"
                
                id="title"
                className="col-md-12"
                onChange={handleErrors}
                style={{
                  color: "#4b4a6f",
                  fontFamily: "Roboto",
                  fontSize:"14px"
                }}
              />
              
              </div>
            </div>
          <div className="row">
            <div className="col-md-6">
            <label id="error" style={{
                  color: "red",
                  fontFamily: "Roboto",
                  fontWeight: "bold",
                  fontSize:"14px"
                }} ></label>
            </div>
          </div>

              
              
          <div className="row">
            <div
              className="col-md-12"
              style={{
                color: "#4b4a6f",
                fontFamily: "Roboto",
                fontWeight: "bold",
                fontSize:"14px"
              }}
            >
              Melding
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div id="eee"></div>
            <CKEditor
                  id="editor"
                  editor={ClassicEditor}
                  data=""
                  config={{readOnly: false,
                    toolbar: [
                      // "bold",
                      // "italic",
                      "Link",
                      // "bulletedList",
                      "|",
                      // "numberedList",
                      // "Heading",
                      
                    ]}}
                  
                  disabled={false}
                  onInit={(editor) => {}}
                  //onChange ={handleErrors1}

                  // onChange={ ( event, editor ) => {
                  //     const data = editor.getData();
                  //     console.log( { event, editor, data } );
                  // } }
                  // onBlur={ ( event, editor ) => {
                  //     console.log( 'Blur.', editor );
                  // } }
                  // onFocus={ ( event, editor ) => {
                  //     console.log( 'Focus.', editor );
                  // } }
                />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-12">
              
              <div
                className="Rectangle-Copy "
                
                onClick={() => handleSendMessage()}
                style={{
                  backgroundColor:"#26A881",
                  textAlign: "center",
                  float: "right",
                  width: "153px",
                  height: "32px",
                  cursor: "pointer",
                }}
              >
                <span
                  className="Delete"
                  style={{
                    backgroundColor:"#26A881",
                    width: "118px",
                    height: "14px",
                    cursor: "pointer",
                    fontFamily: "Roboto",
                    fontSize: "14px",
                  }}
                >
                  SENDE MELDING
                </span>{" "}
              </div>
            </div>
          </div>
          <br/>
          {/* <!-- Input fields come here --> */}
        </div>
        <LoadingModal show={loading} centered backdrop="static" />
    </div>
  

          </div>
          </section>
          <br />
        </div>


    );
}
