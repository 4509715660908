export default class ApiMethods {
  get = 'GET';
  put = 'PUT';
  post = 'POST';
  del = 'DELETE';
}

export class User {
  id;
  firstName;
  lastName;
  email;
  phone;
  street;
  zip;
  city;
}

export class Category{
  id;
  name;
  description;
  status;
  jobSubCategories;

}

export class SubCategory{
  id;
  job_cateories_id;
  name;
  status;
}

export class AppPages{
  id;
  contentType;
  title;
  updatedAt;
}

export class Faq{
  id;
  title;
  status;
  questions;

}
export class Question{
  id;
  faqQuestion;
  faqAnswer;
  status;
  faq_cateory_id;

}

export class Message{
  comment;
email;
id;
isChecked;
message_by;
title;
zip_code_end;
zip_code_start;
zipcode;
}