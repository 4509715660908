import React from "react";
import ReactTable from "react-table-6";
import { Link } from "react-router-dom";
import "react-table-6/react-table.css";
import "./AppPages.css";
import ReactTooltip from 'react-tooltip'
import { generateDate } from "../../bin/common/commonFunctions";

function AppPagesTable({ statePages}) {
  
  function handleFilter(e) {
    const filter = e.target.value.toString().toLowerCase();
    var key = e.keyCode || e.charCode;
    if (key === 8 || key === 46) {
      filterRecords(filter);
    } else {
      if (filter.length >= 3) {
        filterRecords(filter);
      }
    }
  }
  // function handleEdit(page){
  //   stateFAQ.selectedPage=page
  // }

  function filterRecords(filter) {
    var dd = statePages.pages;
    console.log(dd);
    var gg = dd.filter(
      (it) =>
        it.id.toString().includes(filter) ||
        it.contentType.toLowerCase().toString().includes(filter) ||
        //it.Admin.toLowerCase().toString().includes(filter) ||
        generateDate(it.updatedAt, "DD-MM-YYYY").includes(filter)
    );
    statePages.filtered = gg;
    
    statePages.setFilteredData(statePages);
  }

  const columns = [
    {
      Header: "id",
      accessor: "id",
      show: false,
    },
    {
      Header: "Side",
      accessor: "contentTypeTranslation",
    },
    
    {
      Header: "Sist oppdatert ",
      accessor: "updatedAt",
      Cell: (row) => (
        <React.Fragment>
          {new Date(row.value)
            .toLocaleDateString("en-IN", {
              day: "numeric",
              month: "numeric",
              year: "numeric",
              // hour: '2-digit',
              // minute: '2-digit',
              // hour12: true
            })
            .replace("/", ".")
            .replace("/", ".")}
        </React.Fragment>
      ),
    },

    {
      Header: "Handling",
      accessor: "",
      sortable:false,
      Cell: (row) => (
        <>
          <Link to={`/appPages/${row.original.contentType}`}>
            <img style={{ marginRight: "10px" }} src={require("../../assets/edit.svg")} alt="edit" data-tip="Edit"/>
            <ReactTooltip />
          </Link>
          &nbsp;&nbsp;&nbsp;<Link target="_blank" to={`/pages/${row.original.contentType.toString().toLowerCase()}`}>
            Preview
          </Link>
        </>
      ),
      sortable:false
    },
  ];
  return (
    <div>
      <br />
      <div className="row ">
        <div className="col-9 inline-block ">
          <span style={{ fontFamily:"Roboto",fontWeight: "700",fontSize:"14px" }}>
            <img alt="" src={require("../../assets/app-active.svg")} />
           <span style={{ marginLeft:"7px" }}>APP INNHOLD</span>
          </span>
        </div>
        {/* <div className="col-3 inline-block ">
          <input
            id="searchUser"
            style={{ width: "100%" }}
            type="text"
            onKeyUp={handleFilter}
            className="prompt"
            placeholder="  Søk "
          />
        </div> */}
      </div>
      <br />
      <div>
        <ReactTable
        previousText="Tilbake"  nextText="Neste"
          className="bg-white"
          style={{
            fontSize: "14px",
            fontFamily: "Roboto",
            paddingLeft: "0px",
          }}
          minRows={1}
          noDataText="Ingen Rader Funnet"
          columns={columns}
          data={statePages.filtered}
          filterable={false}
          sortable
          resizable={false}
          defaultPageSize={25}
          showPagination={false}
          getTrProps={(state, rowInfo, instance) => {
            if (rowInfo) {
              return {
                style: {
                  color: "#68707d",
                },
              };
            }
            return {};
          }}
        ></ReactTable>
      </div>
    </div>
  );
}

export default AppPagesTable;
