import React from "react";
import { Modal, Button } from "react-bootstrap";
import swal from "sweetalert";
const name = React.createRef();
export default function EditCategory({ stateCategory, onHide }) {
  const categorydata = stateCategory.selectedCategory;
  console.log("selectedCategory in edit", stateCategory.selectedCategory);
  function onSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    var recordId = categorydata.id;
    var values = {};
    var params = {};

    formData.forEach((v, k) => Object.assign(params, { [k]: v }));

    values = {
      id: recordId,
      categoryinfo: { ...params },
    };
    console.log("values  in main edit", values);
    Promise.resolve(stateCategory.editCategory(values))
      .then((error) => {
        if (error === undefined || error === null) {
          onHide();
          swal({
            title: "Du har oppdatert kategorien",
            button: {
              text: "OK",
              value: true,
              visible: true,
              className: "--confirm",
              closeModal: true,
            },
          });
        } else {
          document.getElementById("error").innerText = error.error_message;
        }
      })
      .catch((error) => {
        swal({
          title: error.error_message,
          button: {
            text: "OK",
            value: true,
            visible: true,
            className: "--confirm",
            closeModal: true,
          },
        });
     
      });
  }
  function handleErrors(e) {
    e.target.value = e.target.value.trimLeft();
  }

  return (
    <Modal
      show={stateCategory.modal}
      onHide={onHide}
      centered
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title
          style={{
            marginLeft: "0",
            marginRight: "auto",
            display: "block",
          }}
        >
         
Rediger Hovedkategori
        </Modal.Title>
        <Button
          type="button"
          onClick={onHide}
          variant="outline-secondary"
          className="close"
          style={{ width: "50px" }}
        >
          &times;
        </Button>
      </Modal.Header>
      <Modal.Body>
        {/* <!-- Input fields come here --> */}
        <div>
          <div id="error"></div>
          {categorydata ? (
            <form id="editCategory" onSubmit={onSubmit}>
              <input
                type="text"
                hidden={true}
                disabled={true}
                defaultValue={categorydata.id}
              />

              <div className="row" style={{ textAlign: "left" }}>
                <div className="col-12">
                  <label className="col-3" style={{ textAlign: "left" }}>
                    {" "}
                    Navn
                  </label>
                  <input
                    className="col-6"
                    type="text"
                    defaultValue={categorydata.name}
                    name="name"
                    style={{ width: "100%" }}
                    ref={name}
                    required
                    placeholder="Kategori navn"
                    onChange={handleErrors}
                  />
                  <span className="form-errors"></span>
                </div>
                <div className="col-12" style={{ textAlign: "center" }}>
                  <input
                    className="btn text-light"
                    type="submit"
                    value="SAVE"
                    style={{ textAlign: "center", backgroundColor: "#1657d5" }}
                  />
                </div>
              </div>
            </form>
          ) : (
            <h2>
            Ingen Rader Funnet</h2>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}
