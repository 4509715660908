import React,{useEffect} from "react";
import { Modal, Button, Card } from "react-bootstrap";

export default function ViewMessage({ state, onHide }) {
const [comment,setComment]=React.useState("")
const message=state.selectedMessage
  
  
 useEffect(() => {
 
 console.log(message.comment.replace( /<[^<|>]+?>/gi,'' ).length,"lengtg")
// console.log(document.getElementById("content"))
// console.log("cont",message.comment.length)
// console.log("cont",message.comment)

  if(message.comment.replace( /<[^<|>]+?>/gi,'' ).length!==0){
    if(document.getElementById("content") !== null)
    {
     document.getElementById("content").innerHTML = message.comment;
    }
  }
  else{
    document.getElementById("content").innerHTML = "Ingen data";
  }
  
//  else if(!document.getElementById("content").innerHTML){
//   document.getElementById("content").innerHTML = "no message";
//  }
 
   
 
});
  
  return (
    <Modal
    dialogClassName="modal-90w"
    aria-labelledby="example-custom-modal-styling-title"
      show={state.modal}
      onHide={onHide}
      centered
      backdrop="static"
      style={{ wordWrap: "break-word" }}
    >
      <Modal.Header>
        <Modal.Title
          style={{
            color: "#00a884",
            fontSize: "16px",
            fontWeight: "bold",
            fontFamily: "Proxima",
            textTransform: "uppercase",
          }}
        >
          {message.title}
        </Modal.Title>
        <Button
          style={{ width: "50px" }}
          type="button"
          onClick={onHide}
          variant="outline-secondary"
          className="close"
        >
          &times;
        </Button>
      </Modal.Header>
      <Modal.Body
        style={{ fontFamily: 'Proxima' }}
        className="align-content text-div"
      >
        <div className="row" >
    <div style={{ fontFamily: 'Proxima'}} className="col-md-12 " id="content"></div>
  
</div>
     {/* {message ? 
    <div className="row">
    <div className="col-md-12" id="content"></div>
  
</div>:
<></> 
    }   */}
         
        
        
       
        
      </Modal.Body>
    </Modal>
  );
}
