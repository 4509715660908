import React from "react";
import "./User.css";
import Table from "./Table";
import { User } from "../../bin/models/StaticTypes";
import {
  getUsers,
  updateUser,
  deleteUserbyId,
  blockUserbyId,
} from "../../bin/controllers/api";
import { LoadingModal } from "../../bin/common/commonFunctions";

export default function UserComponent() {
  const [stateUser, setStateUser] = React.useState({
    users: [],
    selecteduser: User | null,
    modal: false,
    updateUsers: updateUsers.bind(this),
    isDelete: false,
    isBlock: false,
    deleteUser: deleteUser.bind(this),
    blockUser: blockUser.bind(this),
    error: false,
    filtered: [],
    setfilteredUsers: setfilteredUsers.bind(this),
  });

  const [loading, setLoading] = React.useState(false);

  function setfilteredUsers(users) {
    setStateUser({
      ...users,
    });
    //loadUsers(users);
  }
  async function loadUsers(users) {
    setLoading(true);
    const data1 = await getUsers();
    if (data1.error_code === undefined) {
      if (users !== undefined) {
        setStateUser({
          ...stateUser,
          users: data1.data,
          selecteduser: null,
          modal: false,
          updateUsers: updateUsers,
          isDelete: false,
          isBlock: false,
          deleteUser: deleteUser,
          blockUser: blockUser,
          error: false,
          filtered: users,
        });
      } else {
        setStateUser({
          ...stateUser,
          users: data1.data,
          selecteduser: null,
          modal: false,
          updateUsers: updateUsers,
          isDelete: false,
          isBlock: false,
          deleteUser: deleteUser,
          blockUser: blockUser,
          error: false,
          filtered: data1.data,
        });
      }
    } else {
      setStateUser({
        error: true,
      });
    }
    setLoading(false);
  }

  const handleChange = React.useCallback(() => {
    if (stateUser.users.length > 0) return;
    loadUsers();
  }, [loadUsers, stateUser]);

  async function updateUsers(params) {
    var userdata = { ...params };
    var data = await updateUser(userdata.userinfo, params.id);
    if (data.error_code === undefined) loadUsers();
    else return data;
  }

  async function deleteUser(params) {
    await deleteUserbyId(params.id);
    //if successful
    loadUsers();
  }

  async function blockUser(params) {
    await blockUserbyId(params.id, params.status);
    loadUsers();
  }

  function startsection() {
    if (stateUser.users) {
      return (
        <div className="">
          <div className="col-md-12 bottom-height-table">
            <Table
              stateUser={stateUser}
              onHide={() =>
                setStateUser({
                  ...stateUser,
                  modal: false,
                  isDelete: false,
                  isBlock: false,
                  selecteduser: null,
                })
              }
              onShow={() =>
                setStateUser({
                  ...stateUser,
                  modal: true,
                  isDelete: false,
                  isBlock: false,
                })
              }
              onDelete={() =>
                setStateUser({
                  ...stateUser,
                  modal: true,
                  isDelete: true,
                  isBlock: false,
                })
              }
              onBlock={() =>
                setStateUser({
                  ...stateUser,
                  modal: true,
                  isDelete: false,
                  isBlock: true,
                })
              }
              onEdit={() =>
                setStateUser({
                  ...stateUser,
                  modal: false,
                  isDelete: false,
                  isBlock: false,
                })
              }
            />
          </div>
        </div>
      );
    } else {
      return <div>No Rows Found</div>;
    }
  }

  React.useEffect(handleChange, [stateUser]);
  return (
    <div>
      {stateUser.error ? (
        <div>Noe Gikk Galt</div>
      ) : (
        <div className=" bg-component">
          <br />
          <section>{startsection()}</section>
          <br />
        </div>
      )}
      <LoadingModal show={loading} centered backdrop="static" />
    </div>
  );
}

// export default class UserComponent extends React.Component {
//   constructor() {
//     super();

//     this.state = {
//       users: [],
//       selecteduser: User | null,
//       modal: false,
//       updateUsers: this.updateUsers.bind(this)
//     };
//   }

//   async componentDidMount() {
//     this.loadUsers()
//   }

//   async

//   render() {
//     return (
//       <div className=''>
//         <UserList {...this.state} />
//       </div>
//     );
//   }
// }
