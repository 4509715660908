import React from "react";
import { Switch, withRouter, Route } from "react-router-dom";
import {
  appPageTableRoutes,
  AppPageComponentRoutes,
  
} from "../bin/config/routes";
import { AppPageComponent, PageDetail } from "../components/AppPages";
import{ FAQDetail} from "../components/AppPages";
export default function AppPagesComponent() {
  return (
    <Switch>
      <Route
        exact
        path={appPageTableRoutes[0]}
        component={withRouter(AppPageComponent)}
      />
      <Route
        exact
        path={appPageTableRoutes[1]}
        component={withRouter(PageDetail)}
      />
       {/* <Route
        exact
        path={detailFAQRoutes[0]}
        component={withRouter(FAQDetail)}
      /> */}
    </Switch>
  );
}
