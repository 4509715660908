import React from "react";
import { Card } from "react-bootstrap";
import { getDashboard } from "../../bin/controllers/api";
import Bar from "./BarGraph";
import { LoadingModal } from "../../bin/common/commonFunctions";

export default function DashboardComponent() {
  const [statedashboard, setDashboard] = React.useState({
    dashboard: {},
    error: false,
  });

  const [loading, setLoading] = React.useState(false);

  function getIcon(status) {
    if (status === "Publisert") return require("../../assets/Publisert.svg");
    else if (status === "Delvis fullført")
      return require("../../assets/Delvisfullført.svg");
    else if (status === "Fullført") return require("../../assets/Fullført.svg");
    else if (status === "Utfører har akseptert")
      return require("../../assets/Utførerharakseptert.svg");
    else if (status === "Har søknader")
      return require("../../assets/Harsøknad.svg");
    else if (status === "Utfører valgt")
      return require("../../assets/Utførervalgt.svg");
  }

  function getsecondMenu(element) {
    var elements = [];
    const statusValues = statedashboard.dashboard.status;
    console.log(statusValues);
    for (var i = 0; i < statusValues.length; i++) {
      elements.push(
        <div key={i} className="col-md-2" style={{ paddingRight: "0" }}>
          <Card style={{ height: "120px" }}>
            <Card.Header
              style={{
                backgroundColor: "transparent",

                padding: "5px",
              }}
            >
              <span
                className="col-10 "
                style={{ fontSize:"12px",color: statusValues[i].color, display: "inline-block", padding: "4px", fontFamily:"Roboto" , fontWeight:"500"}}
              >
                {statusValues[i].name}
              </span>
              <span
                className="textsize12"
                style={{
                  textAlign: "right",
                  height: "50%",
                  display: "inline-block",
                }}
              >
                <img
                  alt=""
                  //  style={{ height: "100%", width: "100%" }}
                  src={getIcon(statusValues[i].name)}
                />
              </span>
            </Card.Header>
            <Card.Body>
              <table className="tableheight" style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td
                      style={{
                        width: "35%",
                        textAlign: "center",
                        height: "80%",
                      }}
                    >
                      <h4 className="bluecolor">{statusValues[i].count}</h4>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Card.Body>
          </Card>
        </div>
      );
    }
    return elements;
  }

  async function loadDashboard() {
    setLoading(true);
    const data1 = await getDashboard();
    if (data1.error_code === undefined) {
      setDashboard({
        dashboard: data1.data,
        error: false,
      });
    } else {
      setDashboard({
        error: true,
      });
    }
    setLoading(false);
  }

  const handleChange = React.useCallback(() => {
    if (JSON.stringify(statedashboard.dashboard) !== "{}") return;
    loadDashboard();
  }, [loadDashboard, statedashboard]);

  function getFirstMenu() {
    return (
      <div>
        {statedashboard.error ? (
          <div>Noe Gikk Galt</div>
        ) : (
          <div className="row">
            <div className="col-md-3" style={{ paddingRight: "0" }}>
              <Card style={{ height: "80px" }}>
                <Card.Body style={{ padding: "0px" }}>
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td
                          style={{
                            width: "35%",
                            textAlign: "center",
                            height: "80%",
                          }}
                        >
                          <img
                            alt=""
                            style={{ height: "100%", width: "45%" }}
                            src={require("../../assets/user-lg.svg")}
                          />
                        </td>

                        <td style={{height:"79px"}}>
                          <h4 className="geencolour">
                            {statedashboard.dashboard.total_users}
                          </h4>
                          <span className="dashboard-text">Antall brukere</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-3" style={{ paddingRight: "0" }}>
              <Card style={{ height: "80px" }}>
                <Card.Body style={{ padding: "0px" }}>
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td
                          style={{
                            width: "35%",
                            textAlign: "center",
                            height: "80%",
                          }}
                        >
                          <img
                            alt=""
                            style={{ height: "100%", width: "45%" }}
                            src={require("../../assets/task-lg.svg")}
                          />
                        </td>

                        <td style={{height:"79px"}}>
                          <h4 className="geencolour">
                            {statedashboard.dashboard.active_jobs}
                          </h4>
                          <span className="dashboard-text">
                            Antall aktive jobber
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-3" style={{ paddingRight: "0" }}>
              <Card style={{ height: "80px" }}>
                <Card.Body style={{ padding: "0px" }}>
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td
                          style={{
                            width: "35%",
                            textAlign: "center",
                            height: "80%",
                          }}
                        >
                          <img
                            alt=""
                            style={{ height: "100%", width: "45%" }}
                            src={require("../../assets/ongoing-task-lg.svg")}
                          />
                        </td>

                        <td style={{height:"79px"}}>
                          <h4 className="geencolour">
                            {statedashboard.dashboard.ongoing_jobs}
                          </h4>
                          <span className="dashboard-text">


                            Antall pågående jobber
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-3">
              <Card style={{ height: "80px" }}>
                <Card.Body style={{ padding: "0px" }}>
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td
                          style={{
                            width: "35%",
                            textAlign: "center",
                            height: "80%",
                          }}
                        >
                          <img
                            alt=""
                            style={{ height: "100%", width: "45%" }}
                            src={require("../../assets/completed-task-lg.svg")}
                          />
                        </td>

                        <td style={{height:"79px"}}>
                          <h4 className="geencolour">
                            {statedashboard.dashboard.completed_jobs}
                          </h4>
                          <span className="dashboard-text">


                            Antall fullførte jobber
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Card.Body>
              </Card>
            </div>
          </div>
        )}
      </div>
    );
  }

  React.useEffect(handleChange, [statedashboard]);
  if (JSON.stringify(statedashboard.dashboard) === "{}") {
    return (
      <div>
        <LoadingModal show={loading} centered backdrop="static" />
        Ingen data funnet
        <div id="second" className="row"></div>
      </div>
    );
  } else
    return (
      <div>
        <section>
          <div>
            <div className="col-md-12 bg-component">
              <div className="row">
                <div className="col-md-12">

                  <span style={{ fontFamily:"Roboto",fontWeight: "700", fontSize: "14px",marginTop:"24px",display:"inline-block"}}>

                  
                    <img
                      alt=""
                      src={require("../../assets/dashboard-active.svg")}
                    />
                    <span style={{marginLeft:"7px"}}>OVERSIKT</span>
                  </span>
                </div>
              </div>
              <br />

              {getFirstMenu()}

              <br />

              <div id="second" className="row" style={{marginRight:"-2px"}}>
                {getsecondMenu()}
              </div>
              {console.log("ppp", statedashboard.dashboard.graph_data)}
              <br />
              <Bar graph_data={statedashboard.dashboard.graph_data} />
            </div>
          </div>
        </section>
        <LoadingModal show={loading} centered backdrop="static" />
      </div>
    );
}
