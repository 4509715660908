export default ['/', '/user', '/messages','/createMessage','/jobs','/categories','/category','/pagesTable','/appPages/:id','/pages/:type', '/advertisement', '/createAdvertisement', '/editAdvertisement'];
const baseUrl = 'https://api.smaatjenester.no/api/v1/admin'

export const api = {
    admin: `${baseUrl}/admin`,
    dashboard: `${baseUrl}/dashboard`,
    category:`${baseUrl}/categories`,
   createCategories:`${baseUrl}/category`,
    appPagesList:`${baseUrl}/apppages`,
    pageDetail:`${baseUrl}/pagedetail`,
    pageTable:`${baseUrl}/pagesTable`,
    appPages:`${baseUrl}/pages`,
    faqs:`${baseUrl}/pages/faq`,
    advertisement:`${baseUrl}/advertisement`

};

export const userRoutes =[
    '/users'
]

export const dashboardRoutes = ['/'];

export const messageRoutes =[
    '/messages',
    '/createMessage'
]

export const jobRoutes =[
    '/jobs'
]


export const categoryRoutes=['/categories'];
 export const categoryFormRoutes=['/form'];

export const appPageTableRoutes=['/pagesTable', '/appPages/:type'];

export const freeRoutes =['/pages/:type']

export const advertisementRoutes =[
    '/advertisement',
    '/createAdvertisement',
    '/editAdvertisement'
]