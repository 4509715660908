import React from "react";
import { Switch, Route } from "react-router-dom";
import { jobRoutes } from "../bin/config/routes";

import { JobListing } from "../components/Job";
export default function JobComponent() {
  return (
    <Switch>
      <Route exact path={jobRoutes[0]} component={JobListing} />
    </Switch>
  );
}
