import React from "react";
import "./AppPages.css";
import Details from "./Details";
import {
  getAppPageData,
  updateAppPages,
  updateFAQ,
  getFAQ,
  createQuestions,
  deleteQuestion,
  createFAQ,
  deleteFAQ,
  updateQuestion,
  updatePageTitle,
} from "../../bin/controllers/api";
import { LoadingModal } from "../../bin/common/commonFunctions";
import { wait } from "@testing-library/react";
import { Faq, Question, AppPages } from "../../bin/models/StaticTypes";
import FAQ from "./FAQ";
const delay = (ms) => new Promise((res) => setTimeout(res, ms));
export default function PageDetail(props) {
  // console.log("faq static type",Faq)
  // console.log("props", props);
  const { type } = props.match.params;
  const [loading, setLoading] = React.useState(false);
  const [state, setState] = React.useState({
    deletedImgs: [],
    selected: {},
    titleEdit: false,
    descEdit: false,
    pageData: null,
    saveAppPage: saveAppPage.bind(this),
    //saveFAQPage:saveFAQPage.bind(this),
    modal: false,
    isEditFAQ: false,
    isDelete: false,
    isDeleteFAQ: false,
    isAdd: false,
    PageTitle: false,
    FAQTitle: false,
    FAQQuestion: false,
    deleteImg: deleteImg.bind(this),
    FAQData: null,
    selectedFAQ: Faq | null,
    selectedPage: AppPages | null,
    selectedQuestion: Question | null,
    postQuestions: postQuestions.bind(this),
    deleteQuest: deleteQuest.bind(this),
    postFAQ: postFAQ.bind(this),
    editFAQ: editFAQ.bind(this),
    editQuestion: editQuestion.bind(this),
    //editAppPage:editAppPage.bind(this),
    isEdit: false,
    isFAQAdd: false,
    removeFAQ: removeFAQ.bind(this),
  });
  async function postFAQ(params) {
     console.log("heyy params",params.error_message)
    var faqs = { ...params };
   
    var data =await createFAQ(faqs.faqinfo, params.id);
    console.log("heyyn")
    console.log("data",data.error_message)
  if (data.error_code === undefined){
    loadPageData("reload");
  }
  else return data;
    
  }
  async function editFAQ(params) {
    //console.log("params in edit FAQ",params)
    var faqdata = { ...params };
    // console.log("faqdata.faqinfo",faqdata.faqinfo)
    // console.log("faqdata.id",faqdata.id)
    await updateFAQ(faqdata.faqinfo, faqdata.id);
    // window.location.reload(true)
    loadPageData("reload");
  }
  async function editQuestion(params) {
    //console.log("params in edit question",params)
    var questiondata = { ...params };
    //console.log("questiondata",questiondata)
    await updateQuestion(questiondata.faqinfo, params.id);
    loadPageData("reload");
  }

  // async function editAppPage(params){
  //   var pagedata={...params};
  //   await updatePageTitle(pagedata.pageinfo,params.id)
  // }
  async function removeFAQ(params) {
    await deleteFAQ(params.id);
    loadPageData("reload");
    //window.location.reload(true)
  }

  async function postQuestions(params) {
    var questions = { ...params };
    //console.log("params",params)
    await createQuestions(questions.questioninfo, params.id);
    // window.location.reload(true)
    loadPageData("reload");
  }
  async function deleteQuest(params) {
    await deleteQuestion(params.id);
    loadPageData("reload");
    //window.location.reload(true)
  }

  async function deleteImg(state, deleteImgs) {
    setState({
      ...state,
      deletedImgs: deleteImgs,
      deleteImg: deleteImg
    });
  }
  async function saveAppPage(id, params) {
    setLoading(true);
    console.log('data', params)
    const response = await updateAppPages(id, params);
    await delay(3000);
    loadPageData("reload");
  }
  async function saveFAQPage(params, id) {
    await updateFAQ(params, id);
  }

  async function loadPageData(reload) {
    const data1 = await getAppPageData(type);
    const data2 = await getFAQ();
    if (reload === undefined)
      setState({
        ...state,
        pageData: data1.data,
        FAQData: data2.data,
        deletedImgs: [],
        deleteImg: deleteImg
      });
    else {
      setState({
        titleEdit: false,
        descEdit: false,
        saveAppPage: saveAppPage,
        pageData: data1.data,
        FAQData: data2.data,
        deletedImgs: [],
        deleteImg: deleteImg,
        
        //selectedPage:null
      });
    }

    //console.log(data1.data,"data1.data")
    if (type === "FAQ") {
      if (reload === undefined)
        setState({
          ...state,
          FAQData: data2.data,
          pageData: data1.data,
          deletedImgs: [],
          deleteImg: deleteImg
        });
      else {
        setState({
          // ...state,
          PageTitle: false,
          FAQTitle: false,
          FAQQuestion: false,
          FAQData: data2.data,
          pageData: data1.data,
          selectedFAQ: null,
          modal: false,
          isDelete: false,
          isDeleteFAQ: false,
          isAdd: false,
          isDelete: false,
          selectedPage: null,
          selectedQuestion: null,
          postQuestions: postQuestions,
          removeFAQ: removeFAQ,
          postFAQ: postFAQ,
          editFAQ: editFAQ,
          editQuestion: editQuestion,
          deleteQuest: deleteQuest,
          saveAppPage: saveAppPage,
          isEdit: false,
          isFAQAdd: false,
          selected: {},
          deletedImgs: [],
          deleteImg: deleteImg
        });
      }
    } else {
      const data1 = await getAppPageData(type);
      if (reload === undefined)
        setState({
          ...state,
          deletedImgs: [],
          deleteImg: deleteImg,
          pageData: data1.data,
        });
      else {
        setState({
          titleEdit: false,
          descEdit: false,
          saveAppPage: saveAppPage,
          pageData: data1.data,
          deletedImgs: [],
          deleteImg: deleteImg
        });
      }
      setLoading(false);
    }
    setLoading(false);
  }
  //console.log(state.pageData,"paedata")
  const handleChange = React.useCallback(() => {
    if (state.pageData !== null || state.FAQData !== null) return;
    setLoading(true);
    loadPageData();
    // if (state.FAQData !== null) return;
    // loadFAQData();
  }, [loadPageData, state]);

  React.useEffect(handleChange, [state]);

  return (
    <div>
      <div className="bg-component">
        <div>
          <div className="col-md-12">
            {type === "FAQ" ? (
              <FAQ
                state={state}
                onTitleEdit={() =>
                  setState({
                    ...state,
                    titleEdit: true,
                  })
                }
                onTitleEditOff={() => setState({ ...state, titleEdit: false })}
                onFAQEdit={() =>
                  setState({
                    ...state,
                    FAQTitle: true,
                  })
                }
                onFAQEditOff={() => setState({ ...state, FAQTitle: false })}
                onFAQQuestionEdit={() =>
                  setState({
                    ...state,
                    FAQQuestion: true,
                  })
                }
                onFAQQuestionEditOff={() =>
                  setState({ ...state, FAQQuestion: false })
                }
                onHide={() =>
                  setState({
                    ...state,
                    modal: false,
                    isEditFAQ: false,
                    isDelete: false,
                    isDeleteFAQ: false,
                    isFAQAdd: false,
                    isAdd: false,
                    isEdit: false,
                  })
                }
                onShow={() =>
                  setState({
                    ...state,
                    modal: true,
                    isDelete: false,
                    isAdd: false,
                    isFAQAdd: false,
                    isDeleteFAQ: false,
                    isEditFAQ: false,
                    selectedFAQ: null,
                    selectedPage: null,
                    selectedQuestion: null,
                    isEdit: false,
                  })
                }
                onAdd={() =>
                  setState({
                    ...state,
                    modal: true,
                    isDelete: false,
                    isFAQAdd: false,
                    isEditFAQ: false,
                    isAdd: true,
                    isDeleteFAQ: false,
                    isEdit: false,
                  })
                }
                onDelete={() =>
                  setState({
                    ...state,
                    modal: true,
                    isDelete: true,
                    isFAQAdd: false,
                    isAdd: false,
                    isDeleteFAQ: false,
                    isEditFAQ: false,
                    isEdit: false,
                  })
                }
                onAdd2={() =>
                  setState({
                    ...state,
                    modal: true,
                    isDelete: false,
                    isAdd: false,
                    isFAQAdd: true,
                    isDeleteFAQ: false,
                    isEditFAQ: false,
                    isEdit: false,
                  })
                }
                onEdit2={() =>
                  setState({
                    ...state,
                    modal: true,
                    isDelete: false,
                    isAdd: false,
                    isFAQAdd: false,
                    isDeleteFAQ: false,
                    isEditFAQ: true,
                    isEdit: false,
                  })
                }
                onEdit={() =>
                  setState({
                    ...state,
                    modal: true,
                    isDelete: false,
                    isAdd: false,
                    isFAQAdd: false,
                    isDeleteFAQ: false,
                    isEditFAQ: false,
                    isEdit: true,
                  })
                }
                pageTitle={() =>
                  setState({
                    ...state,
                    modal: true,
                    isDelete: false,
                    isAdd: false,
                    isFAQAdd: false,
                    isDeleteFAQ: false,
                    isEditFAQ: false,
                    isEdit: false,
                  })
                }
                onEdit2={() =>
                  setState({
                    ...state,
                    modal: true,
                    isDelete: false,
                    isAdd: false,
                    isFAQAdd: false,
                    isDeleteFAQ: false,
                    isEditFAQ: true,
                    isEdit: false,
                  })
                }
                onEdit={() =>
                  setState({
                    ...state,
                    modal: true,
                    isDelete: false,
                    isAdd: false,
                    isFAQAdd: false,
                    isDeleteFAQ: false,
                    isEditFAQ: false,
                    isEdit: true,
                  })
                }
                pageTitle={() =>
                  setState({
                    ...state,
                    modal: true,
                    isDelete: false,
                    isAdd: false,
                    isFAQAdd: false,
                    isDeleteFAQ: false,
                    isEditFAQ: false,
                    isEdit: false,
                  })
                }
                onDelete2={() =>
                  setState({
                    ...state,
                    modal: true,
                    isDelete: false,
                    isAdd: false,
                    isFAQAdd: false,
                    isDeleteFAQ: true,
                    isEditFAQ: false,
                    isEdit: false,
                  })
                }
                show={() =>
                  setLoading({
                    loading: true,
                  })
                }
              />
            ) : (
              <>
                <Details
                  state={state}
                  onTitleEdit={() =>
                    setState({
                      ...state,
                      titleEdit: true,
                    })
                  }
                  onDescriptionEdit={() =>
                    setState({ ...state, descEdit: true })
                  }
                  onTitleEditOff={() =>
                    setState({ ...state, titleEdit: false })
                  }
                  onDescriptionEditOff={() =>
                    setState({ ...state, descEdit: false })
                  }
                  onHide={() =>
                    setState({
                      ...state,
                      modal: false,
                    })
                  }
                  onShow={() =>
                    setState({
                      ...state,
                      modal: true,
                    })
                  }
                />
              </>
            )}
          </div>
        </div>
      </div>
      <LoadingModal show={loading} centered backdrop="static" />
    </div>
  );
}
