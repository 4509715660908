import React from "react";
import { Modal, Button } from "react-bootstrap";

export default function EditUser({ state, onHide, onSubmit123 }) {
  const userdata = state.selecteduser;

  function onSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    var recordId = userdata.id;
    var values = {};
    var params = {};

    formData.forEach((v, k) => Object.assign(params, { [k]: v }));

    values = {
      id: recordId,
      userinfo: { ...params, email: userdata.email },
    };
    Promise.resolve(state.updateUsers(values)).then((error) => {
      if (error === undefined || error === null) {
        onHide();
      } else {
        document.getElementById("error").innerText = error.error_message;
      }
    });
  }

  // const handleErrors = (e) => {
  //   const formErrors = e.currentTarget.nextSibling;
  //   const inputValue = e.currentTarget.value;
  //   if (!inputValue || inputValue === '')
  //     return (formErrors.innerText = `${e.currentTarget.name} is required.`);
  //   if (inputValue.length < 8)
  //     return (formErrors.innerText = `${e.currentTarget.name} is invalid`);
  //   return (formErrors.innerText = '');
  // };

  return (
    <Modal show={state.modal} onHide={onHide} centered backdrop="static">
      <Modal.Header>
        <Modal.Title>Rediger Bruker</Modal.Title>
        <Button
         style={{width:"50px"}}
          type="button"
          onClick={onHide}
          variant="outline-secondary"
          className="close"
        >
          &times;
        </Button>
      </Modal.Header>
      <Modal.Body>
        {/* <!-- Input fields come here --> */}
        <div>
          <div id="error"></div>
          {userdata ? (
            <form id="editUser" onSubmit={onSubmit}>
              <input
                type="text"
                hidden={true}
                disabled={true}
                defaultValue={userdata.id}
              />
              <label className="col-3">E-post</label>
              <input
                className="col-3"
                type="text"
                defaultValue={userdata.email}
                disabled={true}
                name="email"
              />
              <div className="row" style={{ textAlign: "left" }}>
                <div className="col-12">
                  <label className="col-3">Fornavn</label>
                  <input
                    className="col-3"
                    type="text"
                    defaultValue={userdata.firstName}
                    name="first_name"
                    required
                    //onChange ={handleErrors}
                  />
                  <span className="form-errors"></span>
                  <label className="col-3">Etternavn</label>
                  <input
                    className="col-3"
                    type="text"
                    defaultValue={userdata.lastName}
                    name="last_name"
                    required
                  />
                  <label className="col-3">Telefon</label>
                  <input
                    className="col-3"
                    type="number"
                    defaultValue={userdata.phone}
                    name="phone"
                    required
                  />
                  <label className="col-3">Gate</label>
                  <input
                    className="col-3"
                    type="text"
                    defaultValue={userdata.street}
                    name="street"
                    required
                  />
                  <label className="col-3">Zip</label>
                  <input
                    className="col-3"
                    type="number"
                    defaultValue={userdata.zip}
                    name="zip"
                    required
                  />
                  <label className="col-3">City</label>
                  <input
                    className="col-3"
                    type="text"
                    defaultValue={userdata.city}
                    name="city"
                    required
                  />
                  <label className="col-3">Lattitude</label>
                  <input
                    className="col-3"
                    type="text"
                    defaultValue="111111"
                    name="lattitiude"
                    required
                  />
                  <label className="col-3">Longitude</label>
                  <input
                    className="col-3"
                    type="text"
                    defaultValue="22222222"
                    name="longitide"
                    required
                  />
                </div>
                <div className="col-12" style={{ textAlign: "center" }}>
                  <input
                    className="btn text-light"
                    type="submit"
                    value="SAVE"
                    style={{ textAlign: "center", backgroundColor:"#1657d5" }}
                  />
                </div>
              </div>
            </form>
          ) : (
            <></>
          )}
        </div>{" "}
        {/* <Button type='submit' variant='outline-primary'>
              Add new Session form
            </Button> */}
      </Modal.Body>
    </Modal>
  );
}
