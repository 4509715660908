import React, { useState } from "react";
import "./Category.css";
import AddCat from "./AddCat";
import AddSubCat from "./Add";
import EditSubCategory from "./EditSubCat";
import BlockSubCategory from "./BlockSubCat";
import DeleteSubCategory from "./DeleteSubCategory";
import { Accordion, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import DeleteCategory from "./Delete";
import BlockCategory from "./Block";
import EditCategory from "./Edit";
import Categories from "./Categories";
import Pagination from "./Pagination";
import ReactTooltip from "react-tooltip";
import swal from "sweetalert";
// import Accordion from './Accordion';

let editCategory = {
  name: "",
};
let editSubCategory = {
  name: "",
};

function CategoryDropdown({
  stateCategory,
  stateSubCategory,
  onHide,
  onAdd,
  onAdd2,
  onHide2,
  onShow2,
  onShow,
  onDelete,
  onDelete2,
  onBlock,
  onBlock2,
  onEdit,
  onEdit2,
}) {
  // console.log("statesub", stateSubCategory);
  // const [loading, setLoading] = useState(false);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [categoriesPerPage] = useState(1);
  const [toggle, setToggle] = React.useState({
    toogleid: null,
  });

  function onToggle(id, e) {
    // console.log(e.target);
    // debugger
    var gg = document.getElementsByClassName("fa-angle-up");
    if (gg.length > 0 && gg[0].id === ""+id) {
      setToggle({
        toogleid: null,
      });
    } else {
      setToggle({
        toogleid: id,
      });
    }
  }
  function handleSubCategoryCreate(row) {
    stateCategory.selectedCategory = row;
    onAdd2();
  }
  // if(stateCategory.categories===null){
  //   setLoading(false)
  // }

  function handleBlock(row) {
    stateCategory.selectedCategory = row;
    onBlock();
  }

  function handleSubBlock(row, cat) {
    stateCategory.selectedCategory = cat;
    stateSubCategory.selectedSubCategory = row;
    onBlock2();
  }

  function handleEdit(cat) {
    stateCategory.selectedCategory = cat;
    editCategory = {
      name: cat.name,
    };
    onEdit();
    //onShow();
  }
  function handleSubEdit(cat) {
    stateSubCategory.selectedSubCategory = cat;
    editSubCategory = {
      name: cat.name,
    };
    onEdit2();
    //onShow();
  }

  function handleCreate() {
    onAdd();
  }
  function handleSave(row) {
    stateCategory.selectedCategory = null;
    onHide();

    var params = {
      name: editCategory.name,
    };

    editCategory = {
      name: "",
    };

    var values = {
      id: row.id,
      categoryinfo: { ...params },
    };

    stateCategory.editCategory(values);
    // document.getElementById("searchUser").value = "";
  }

  function handleDelete(cat) {
    //.log("handle delete",stateCategory.selectedCategory)
    stateCategory.selectedCategory = cat;
    onDelete();
  }

  function handleSubCategoryDelete(subCat, cat) {
    // console.log("in catagory select delete", cat);
    stateCategory.selectedCategory = cat;
    stateSubCategory.selectedSubCategory = subCat;
    onDelete2();
  }
  // const indexOfLastCategory = currentPage * categoriesPerPage;
  //   const indexOfFirstCategory = indexOfLastCategory -categoriesPerPage;
  //   const currentcategories = stateCategory.categories.slice(indexOfFirstCategory, indexOfLastCategory);
  // console.log("indexOfLastCategory",indexOfLastCategory)
  // console.log("indexOfFirstCategory",indexOfFirstCategory)
  // console.log("currentcategories",currentcategories)
  //   // Change page
  //   const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <>
      <div className="row ">
        <div className="col-md-12">
          <br />
          <span
            style={{
              fontFamily: "Roboto",
              fontWeight: "700",
              fontSize: "14px",
            }}
          >
            <img alt="" src={require("../../assets/category-active.svg")} />
            <span style={{ marginLeft: "7px" }}>KATEGORIER</span>
          </span>
        </div>
      </div>
      <br />
      <div className="row ">
        {/* <Button color="primary" onClick={handleCreate} >CREATE CATEGORY</Button> */}
        <div className="col-2">
          <Link
            className="Rectangle-Copy "
            onClick={() => handleCreate()}
            style={{
              textAlign: "center",
              float: "left",
              width: "153px",
              height: "32px",
              textDecoration: "none",
            }}
          >
            <span
              className="Delete"
              style={{ width: "118px", height: "16px" }}
              //onClick={}
            >
              NY KATEGORI
            </span>{" "}
          </Link>
        </div>
      </div>
      <br />
      <div className="row ">
        <div className="col-md-12 ">
          <Accordion className="accordion-length">
            {stateCategory.categories !== undefined ? (
              stateCategory.categories.map((cat, i) => {
                return (
                  <div style={{marginTop:"20px"}}>
                  <Card className="card-style " >
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey={cat.id}
                      className="card-style "
                      onClick={(e) => onToggle(cat.id, e)}
                      
                    >
                      {toggle.toogleid === cat.id ? (
                        <i id={cat.id} className=" onClick font-awesome-back fa fa-angle-up"></i>
                      ) : (
                        <i id ={cat.id} className=" onClick font-awesome-back fa fa-angle-down"></i>
                      )}

                      {cat.status === false ? (
                        <span
                          // className="font-style"
                          style={{
                            color: "red",
                            fontSize: "14px",
                            fontFamily: "Roboto",
                          }}
                        >
                          &nbsp;&nbsp;{cat.name}
                        </span>
                      ) : (
                        <span
                          // className="font-style"
                          style={{
                            font: "#68707d",
                            fontSize: "14px",
                            fontFamily: "Roboto",
                          }}
                        >
                          &nbsp;&nbsp;{cat.name}
                        </span>
                      )}

                      <Link
                        onClick={() => {
                          handleBlock(cat);
                        }}
                      >
                        {cat.status == false ? (
                          <img
                            alt="block"
                            src={require("../../assets/block.svg")}
                            className="header-images"
                            data-tip="Unblock"
                          />
                        ) : (
                          <img
                            alt="block"
                            src={require("../../assets/block.svg")}
                            className="header-images"
                            data-tip="Block"
                          />
                        )}
                        <ReactTooltip />
                      </Link>
                      <Link
                        onClick={() => {
                          handleDelete(cat);
                        }}
                        data-tip="Delete"
                      >
                        <img
                          alt="delete"
                          src={require("../../assets/delete.svg")}
                          className="header-images"
                        />
                        <ReactTooltip />
                      </Link>

                      <Link
                        onClick={() => {
                          handleSubCategoryCreate(cat);
                        }}
                      >
                        <img
                          alt="add "
                          src={require("../../assets/add.svg")}
                          className="header-images"
                          data-tip="Add"
                        />
                        <ReactTooltip />
                      </Link>
                      <Link
                        onClick={() => {
                          handleEdit(cat);
                        }}
                      >
                        <img
                          alt="edit "
                          src={require("../../assets/edit.svg")}
                          className="header-images"
                          data-tip="Edit"
                        />
                        <ReactTooltip />
                      </Link>
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey={cat.id} onChange={(e) => alert(e)}>
                      <div style={{ paddingLeft: "5px", paddingRight: "2px" }}>
                        <ol
                          style={{
                            listStyleType: "none",
                            paddingInlineStart: "2%",
                          }}
                        >
                          {cat !== undefined &&
                            cat.jobSubCategories.map((subCat) => {
                              return subCat.isDeleted === false ? (
                                <li
                                  style={{
                                    paddingTop: "10px",
                                    paddingLeft: "10px",
                                    paddingRight: "2px !important",
                                  }}
                                >
                                  {subCat.status === false ? (
                                    <span
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      &nbsp;&nbsp;{subCat.name}
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        fontSize: "14px",
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      &nbsp;&nbsp;{subCat.name}
                                    </span>
                                  )}
                                  {cat.status === false ? (
                                    <></>
                                  ) : (
                                    <>
                                      <Link
                                        onClick={() => {
                                          handleSubBlock(subCat, cat);
                                        }}
                                      >
                                        {subCat.status == false ? (
                                          <img
                                            alt="block"
                                            src={require("../../assets/block.svg")}
                                            className="images"
                                            data-tip="Unblock"
                                          />
                                        ) : (
                                          <img
                                            alt="block"
                                            src={require("../../assets/block.svg")}
                                            className="images"
                                            data-tip="Block"
                                          />
                                        )}
                                        <ReactTooltip />
                                      </Link>

                                      <Link
                                        onClick={() => {
                                          handleSubCategoryDelete(subCat, cat);
                                        }}
                                      >
                                        <img
                                          alt="delete"
                                          src={require("../../assets/delete.svg")}
                                          className="images"
                                          data-tip="Delete"
                                        />
                                        <ReactTooltip />
                                      </Link>

                                      <Link
                                        onClick={() => {
                                          handleSubEdit(subCat);
                                        }}
                                      >
                                        <img
                                          alt="edit "
                                          src={require("../../assets/edit.svg")}
                                          className="images"
                                          data-tip="Edit"
                                        />
                                        <ReactTooltip />
                                      </Link>
                                    </>
                                  )}
                                </li>
                              ) : (
                                <></>
                              );
                            })}
                        </ol>
                      </div>
                    </Accordion.Collapse>
                    {/* <hr className="class-1" /> */}
                  </Card>
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </Accordion>
          {/* <Pagination
        categoriesPerPage={stateCategory.categories}
        totalCategories={stateCategory.categories.length}
        paginate={paginate}
      /> */}
        </div>
      </div>

      {stateCategory.isDelete ? (
        <DeleteCategory stateCategory={stateCategory} onHide={onHide} />
      ) : stateCategory.isBlock ? (
        <BlockCategory stateCategory={stateCategory} onHide={onHide} />
      ) : stateCategory.isAdd ? (
        <AddCat stateCategory={stateCategory} onHide={onHide} />
      ) : (
        <EditCategory stateCategory={stateCategory} onHide={onHide} />
      )}

      {stateCategory.categories !== null && stateSubCategory.isBlockSubCat ? (
        <BlockSubCategory
          stateCategory={stateCategory}
          stateSubCategory={stateSubCategory}
          onHide2={onHide2}
        />
      ) : stateSubCategory.isAddSubCat ? (
        <AddSubCat
          stateCategory={stateCategory}
          stateSubCategory={stateSubCategory}
          onHide2={onHide2}
        />
      ) : stateSubCategory.isDeleteSubCat ? (
        <DeleteSubCategory
          stateSubCategory={stateSubCategory}
          onHide2={onHide2}
          stateCategory={stateCategory}
        />
      ) : (
        //    <>
        //    {console.log("statesub",stateSubCategory)}
        // {stateCategory.categories.map((subCat)=>{
        //   return (subCat.jobSubCategories.length!==1 === false?
        //   console.log("heyya",subCat.jobSubCategories.length>1)
        //   :
        //             (<>

        //             <DeleteSubCategory
        //               stateSubCategory={stateSubCategory}
        //               onHide2={onHide2}
        //             />
        //             </>)
        //   )
        // })}

        //             </>
        // stateCategory.categories!==null &&
        // stateCategory.categories.map((cat)=>{
        //   if(cat.jobSubCategories.length >1)
        //   {
        //     return(

        //         <DeleteSubCategory
        //         stateSubCategory={stateSubCategory}
        //         onHide2={onHide2}
        //       />

        //      )
        //     }
        //      else if(cat.jobSubCategories.length===1){
        //       return(

        //         swal("category cannot be completely empty")
        //        )
        //      }

        // })

        <EditSubCategory
          stateSubCategory={stateSubCategory}
          onHide2={onHide2}
        />
      )}
    </>
  );
}

export default CategoryDropdown;
