import React from "react";
import { Modal, Button } from "react-bootstrap";
import swal from "sweetalert";
export default function BlockCategory({ stateCategory, onHide }) {
  console.log("inside Block.jsx stateCategory", stateCategory.selectedCategory);
  // let selectedCategory={"id":18,"jobSubCategories":[],"name":"nsh","status":true}
  // stateCategory["selectedCategory"]

  const categorydata = stateCategory.selectedCategory;

  const isBlock = stateCategory.selectedCategory.status ? false : true;
  function onCancel(e) {
    e.preventDefault();
    onHide();
  }
  function onSubmit(e) {
    e.preventDefault();
    var recordId = categorydata.id;
    var params = {};
    params = {
      id: recordId,
      status: isBlock ? "1" : "0",
    };
    console.log("in block onSubmit params", params);
    stateCategory.blockCategory(params);
    onHide();
    if (categorydata.status === false) {
      swal({
        title: "Du har fjernet blokkeringen av kategorien",
        button: {
          text: "OK",
          value: true,
          visible: true,
          className: "--confirm",
          closeModal: true,
        },
      });
    } else {
      swal({
        title: "Du har blokkert kategorien",
        button: {
          text: "OK",
          value: true,
          visible: true,
          className: "--confirm",
          closeModal: true,
        },
      });
    }
  }

  return (
    <Modal
      show={stateCategory.modal}
      onHide={onHide}
      centered
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title
          style={{
            marginLeft: "0",
            marginRight: "auto",
            display: "block",
          }}
        >
          {isBlock ? "Oppheve" : "Blockk"} Kategori
        </Modal.Title>
        <Button
          type="button"
          onClick={onHide}
          variant="outline-secondary"
          className="close"
          style={{ width: "50px" }}
        >
          &times;
        </Button>
      </Modal.Header>
      <Modal.Body>
        {/* <!-- Input fields come here --> */}
        <div>
          {categorydata ? (
            <form id="blockCategory" onSubmit={onSubmit}>
              <input
                type="text"
                hidden={true}
                disabled={true}
                defaultValue={categorydata.id}
              />
              <div className="row" style={{ textAlign: "left" }}>
                <div className="col-12" style={{ textAlign: "left" }}>
                  <div className="col-12">
                    <span className="col-12 text-dark">
                      <h5>
                      Er du sikker på at du vil {isBlock ? "oppheve" : "blokk"}{" "}
                      Kategori?
                      </h5>
                    </span>
                    <br />
                    <input
                      className="btn text-light bg-danger col-6"
                      type="submit"
                      value={isBlock ? "OPPHEVE" : "BLOKK"}
                      style={{ textAlign: "center" }}
                    />
                    <span className="col-1"></span>
                    <input
                      className="btn text-light bg-warning col-6"
                      type="button"
                      value="AVBRYTE"
                      onClick={onCancel}
                      style={{ textAlign: "center" }}
                    />
                  </div>
                </div>
              </div>
            </form>
          ) : (
            <></>
          )}
        </div>{" "}
        {/* <Button type='submit' variant='outline-primary'>
              Add new Session form
            </Button> */}
      </Modal.Body>
    </Modal>
  );
}
