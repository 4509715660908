import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import swal from "sweetalert";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';



export default function Details({
  state,
  onTitleEdit,
  onDescriptionEdit,
  onTitleEditOff,
  onDescriptionEditOff,
}) {


  const [quillValue, setQuillValue] = useState('');


  const quillModules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'video'],
    ],
  };

  useEffect(() => {

    if (state.pageData !== null) {
      setQuillValue(state.pageData.content);
    }

  }, [state])


  let deletedImages = state.deletedImgs;

  const editorConfiguration = {
    readOnly: false,

    toolbar: [
      "bold",
      "italic",
      "bulletedList",
      "|",
      "numberedList",
      "alignment",
      "Heading",
      "Link",
    ],
    image: {

    },
    mediaEmbed: {

    }
    // removePlugins: ["Img", "Link"],
  };

  function clearSelectedFile(e) {
    e.preventDefault();
    document.getElementById("file").value = "";
    document.getElementById("clear").setAttribute("hidden", true);
  }

  function popButton(e) {
    e.preventDefault();
    var files = document.getElementById("file").files;
    // for (var i = 0; i < files.length; i++) {
    //   if (!files[i].type.toString().includes("image")||!files[i].type.toString().includes("video")) {
    //     clearSelectedFile(e);
    //     swal({
    //       title: "Please select only image files",
    //       button: {
    //         text: "OK",
    //         value: true,
    //         visible: true,
    //         className: "--confirm",
    //         closeModal: true,
    //       },
    //     });
    //     return;
    //   }
    // }

    if (files.length > 0) {
      document.getElementById("clear").removeAttribute("hidden");
      // console.log(document.getElementById("file").files);
    } else {
      document.getElementById("clear").setAttribute("hidden", true);
    }
  }
  function handleErrors(e) {
    e.target.value = e.target.value.trimLeft();
  }
  function handleErrors1(e) {
    if (
      document.getElementsByClassName("ck-content")[0].innerHTML.trim() !== ""
    )
      document.getElementById("datError").innerText = "";
  }

  function cancelTitle() {
    document.getElementById("title").value = state.pageData.title;

    onTitleEditOff();
  }

  function cancelDesc() {

    // document.getElementsByClassName("ck-content")[0].innerHTML =
    //   state.pageData.content;

    onDescriptionEditOff();
  }




  function handleSave(e) {
    e.preventDefault();

    var selectedImagesCount = 0;

    selectedImagesCount = e.target.pageImages.files.length;

    if (
      state.pageData.pageImages.length +
      selectedImagesCount -
      deletedImages.length >
      5
    ) {
      swal(
        {
          title: "Bare maksimalt 5 bilder er tillatt",
          button: {
            text: "OK",
            value: true,
            visible: true,
            className: "--confirm",
            closeModal: true,
          },
        });
      clearSelectedFile(e);
      return;
    }

    if (
      // document.getElementsByClassName("ck-content")[0].innerHTML.trim() === ""

      quillValue === ""

    ) {
      document.getElementById("dataError").innerText = "Kan ikke være tom";
    } else {
      let formData = new FormData();
      for (var i = 0; i < e.target.pageImages.files.length; i++) {
        formData.append("pageImages", e.target.pageImages.files[i]);
      }

      //formData.set('pageDetails', JSON.stringify(pageDetails));
      formData.set("pageDetails[contentType]", "RULES1");
      formData.set("pageDetails[pageTitle]", e.target.title.value);
      for (var i = 0; i < state.deletedImgs.length; i++) {
        formData.set(
          `pageDetails[deletedPageImages][${i}]`,
          state.deletedImgs[i]
        );
      }

      formData.set(
        "pageDetails[pageDescription]",
        // document.getElementsByClassName("ck-content")[0].innerHTML

        quillValue


      );

      // console.log("pageData", state.pageData)
      deletedImages.splice(0, deletedImages.length);
      state.saveAppPage(state.pageData.id, formData);



      document.getElementById("file").value = "";
      document.getElementById("clear").setAttribute("hidden", true);
    }
  }





  function handleDeleteImage(e) {
    e.preventDefault();
    var dd = document.getElementById(e.target.id);
    var ff = document.getElementById(`page${e.target.id}`);
    const mode = dd.getAttribute("mode");
    if (mode === "view") {
      deletedImages.push(dd.getAttribute("link"));
    } else {
      deletedImages = deletedImages.filter(
        (it) => !it.toString().includes(dd.getAttribute("link"))
      );
    }

    state.deleteImg(state, deletedImages);
  }
  function displayImages() {
    var images = [];
    if (state.pageData.pageImages.length > 0) {
      for (var i = 0; i < state.pageData.pageImages.length; i++) {
        images.push(
          <div
            key={i}
            className="col-md-2 imgheight"
            style={{ textAlign: "center" }}
          >
            {/* {console.log("kkk", state.deletedImgs)} */}
            {!state.deletedImgs.includes(
              state.pageData.pageImages[i].imagePath
            ) ? (
                <>
                  <img
                    src={state.pageData.pageImages[i].imagePath}
                    id={`page${i}`}
                    className=""
                    style={{ width: "100%", height: "100%" }}
                  />
                  {/* <video width="100" height="100" >
  <source
   src={state.pageData.pageImages[i].imagePath}
   id={`page${i}`}
   className=""
   style={{ width: "100%", height: "100%" }}
                  type="video/mp4"
                  />
  
  
</video> */}
                  <img
                    id={i}
                    link={state.pageData.pageImages[i].imagePath}
                    mode="view"
                    onClick={(e) => handleDeleteImage(e)}
                    alt="icon "
                    src={require("../../assets/delete-icon.svg")}
                    style={{
                      position: "absolute",
                      bottom: "10%",
                      right: "15%",
                      cursor: "pointer",
                    }}
                  />
                  {/* <video width="100" height="100" >
  <source id={i}
                  link={state.pageData.pageImages[i].imagePath}
                  mode="view"
                  onClick={(e) => handleDeleteImage(e)}
                  alt="icon "
                  src={require("../../assets/delete-icon.svg")}
                  style={{
                    position: "absolute",
                    bottom: "10%",
                    right: "15%",
                    cursor: "pointer",
                  }} type="video/mp4"/>
  
  
</video> */}
                </>
              ) : (
                <>
                  <img
                    src={state.pageData.pageImages[i].imagePath}
                    id={`page${i}`}
                    className="ondelete"
                    style={{ width: "100%", height: "100%" }}
                  />
                  {/* <video width="100" height="100" >
  <source
   src={state.pageData.pageImages[i].imagePath}
   id={`page${i}`}
   className="ondelete"
   style={{ width: "100%", height: "100%" }}
                  type="video/mp4"
                  />
  
  
</video> */}
                  <img
                    id={i}
                    link={state.pageData.pageImages[i].imagePath}
                    mode="delete"
                    onClick={(e) => handleDeleteImage(e)}
                    alt="icon "
                    src={require("../../assets/restore.svg")}
                    style={{
                      position: "absolute",
                      bottom: "10%",
                      right: "15%",
                      cursor: "pointer",
                    }}
                  />
                  {/* <video width="100" height="100" >
  <source id={i}
                  link={state.pageData.pageImages[i].imagePath}
                  mode="view"
                  onClick={(e) => handleDeleteImage(e)}
                  alt="icon "
                  src={require("../../assets/delete-icon.svg")}
                  style={{
                    position: "absolute",
                    bottom: "10%",
                    right: "15%",
                    cursor: "pointer",
                  }} type="video/mp4"/>
  
  
</video> */}
                </>
              )}
          </div>
        );
      }
    }
    return images;
  }
  return (
    <>
      {state.pageData !== null ? (
        <div>
          <br />

          <form onSubmit={handleSave} id="myform" encType="multipart/form-data">
            <div className="row">
              <div className="col-md-12">

                <h6
                  style={{
                    color: "#1657d5",
                    fontSize: "14px",
                    fontWeight: "500",
                    fontFamily: "Roboto",
                  }}
                  className="text-decor"
                >
                  <Link to="/pagesTable">
                    <i className="fa fa-angle-left"></i>
                    Tilbake
                    </Link>
                </h6>

              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                <h6
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: "700",
                  }}
                >
                  Sidetittel &nbsp;&nbsp;
                  {!state.titleEdit ? (
                    <div onClick={() => onTitleEdit()} className="inline-block">
                      <img
                        alt="edit "
                        src={require("../../assets/green-edit.svg")}
                      />

                      
                      <label
                        style={{
                          fontFamily: "Roboto",
                          FontSize: "14px",
                          fontWeight: "400",
                          color: "#00a884",
                          cursor: "pointer",
                        }}
                      >
                        Endre
                      </label>

                    </div>


                  ) : (
                      <div onClick={() => cancelTitle()} className="inline-block">
                        <label
                          style={{
                            fontFamily: "Roboto",
                            FontSize: "14px",
                            fontWeight: "400",
                            color: "red",
                            cursor: "pointer",
                          }}
                        >
                          Cancel
                      </label>
                      </div>
                    )}
                </h6>

                <input
                  id="title"
                  type="text"
                  name="title"
                  defaultValue={state.pageData.title}
                  style={
                    state.titleEdit
                      ? {
                        backgroundColor: "##f5f7fa",
                        border: "none",
                        width: "100%",
                      }
                      : { width: "100%" }
                  }
                  required
                  onChange={handleErrors}
                  disabled={state.titleEdit ? false : true}
                />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6" style={{ top: "10px" }}>
                <h6
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: "700",
                  }}
                >
                  {/* Beskrivelse &nbsp;&nbsp; */}

                  {/* {!state.descEdit ? (
                    <div
                      onClick={() => onDescriptionEdit()}
                      className="inline-block"
                    >
                      <img
                        alt="edit "
                        src={require("../../assets/green-edit.svg")}
                      />
                      <label
                        style={{
                          fontFamily: "Roboto",
                          FontSize: "14px",
                          fontWeight: "400",
                          color: "#00a884",
                          cursor: "pointer",
                        }}
                      >
                        Endre
                      </label>
                    </div>
                  ) : (
                      <div onClick={() => cancelDesc()} className="inline-block">
                        <label
                          style={{
                            fontFamily: "Roboto",
                            FontSize: "14px",
                            fontWeight: "400",
                            color: "red",
                            cursor: "pointer",
                          }}
                        >
                          Cancel
                      </label>
                      </div>
                    )}
 */}

                </h6>

                {/* <CKEditor
                  id="editor"
                  editor={ClassicEditor}
                  data={`${state.pageData.content}`}
                  // config={editorConfiguration}
                  disabled={state.descEdit ? false : true}
                  onInit={(editor) => {}}
                  //onChange ={handleErrors1}

                  // onChange={ ( event, editor ) => {
                  //     const data = editor.getData();
                  //     console.log( { event, editor, data } );
                  // } }
                  // onBlur={ ( event, editor ) => {
                  //     console.log( 'Blur.', editor );
                  // } }
                  // onFocus={ ( event, editor ) => {
                  //     console.log( 'Focus.', editor );
                  // } }
                /> */}


                <ReactQuill
                  theme="snow"
                  value={quillValue}
                  onChange={setQuillValue}
                  modules={quillModules}
                />




                <label id="dataError" style={{ color: "red" }}></label>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                <h6>Media</h6>
              </div>
            </div>
            <br />
            <div className="row">{displayImages()}</div>
            <br />
            <div className="row">
              <div className="col-md-12">
                {" "}
                Note: Images will be saved / deleted once the form is submited
              </div>
            </div>
            <br />

            <div>
              <input
                onChange={(e) => popButton(e)}
                type="file"
                id="file"
                name="pageImages"
                multiple
                accept="image/x-png,image/gif,image/jpeg,video/*,audio/*"
              />
              <button
                id="clear"
                hidden={true}
                onClick={(e) => clearSelectedFile(e)}
              >
                Clear Selected Image
              </button>
            </div>

            <div className="row">
              <div className="col-12">
                <input
                  type="submit"
                  className="Rectangle-Copy "
                  //onClick={() => handleSendMessage()}
                  style={{
                    paddingTop: "3px",
                    textAlign: "center",
                    float: "left",
                    width: "153px",
                    height: "32px",
                    color: "#ffffff",
                    marginTop: "20px",
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: "500",
                    backgroundColor: "#1657d5",
                    marginBottom: "30px"
                  }}
                  value="SAVE"
                />
                {/* <span
                  className="Delete"
                  style={{ width: "118px", height: "16px" }}
                >
                  SEND MESSAGE
                </span>{" "}
              </input> */}
              </div>
            </div>
          </form>
        </div>
      ) : (
          <></>
        )}
    </>
  );
}