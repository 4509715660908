import React from "react";
import { Switch, Route } from "react-router-dom";
import { userRoutes } from "../bin/config/routes";

import { UserListing } from "../components/Users";
export default function UserComponent() {
  return (
    <Switch>
      <Route exact path={userRoutes[0]} component={UserListing} />
    </Switch>
  );
}
