import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useStateValue } from "../../bin/config/AppContext";
import swal from "sweetalert";
import {Link} from "react-router-dom"
const faqQuestion = React.createRef();
const faqAnswer = React.createRef();

export default function Add({ state, onHide }) {
  //console.log("in add po up",state.selectedFAQ)
  // console.log("selected faq",stateFAQ.selectedFAQ)
  const questiondata = state.selectedFAQ;

  function onSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    var recordId = questiondata.id;
    var values = {};
    var params = {};

    formData.forEach((v, k) => {
      Object.assign(params, { [k]: v });
    });

    values = {
      id: recordId,
      questioninfo: { ...params },
      // categoryinfo: { subCategoryName: subCategoryName.current !== null ? subCategoryName.current.value : "" },
    };

   
    // console.log("questioninfo",values.questioninfo.faqAnswer)

    Promise.resolve(state.postQuestions(values))
      .then((error) => {
        if (error === undefined || error === null) {
          onHide();
          swal(
          {
            title: "Du har lagt til spørsmål",
            button: {
              text: "OK",
              value: true,
              visible: true,
              className: "--confirm",
              closeModal: true,
            },
          });
        } else {
          document.getElementById("error").innerText = error.error_message;
        }
      })
      .catch((error) => {
        swal({
          title: error.error_message,
          button: {
            text: "OK",
            value: true,
            visible: true,
            className: "--confirm",
            closeModal: true,
          },
        });
      });
  }
// function handleChange(e){
//   let w=document.getElementsByClassName("links")
//   console.log(w,"name")
//   let symbols="./://"
//   console.log(e.target.value)
//   var words = e.target.value.split(" ");
//   console.log(words,"words")
//   if(symbols.includes(e.target.value)){
//     return 
//     }
//   // if(words.includes("." || "/"||":")){
//   //   console.log(words,"words")
//   // }
//   // words.forEach((word)=>{
//   //   for(let i=0;i<word.length;i++){
//   //    if(symbols.includes(word[i])){
//   //    return <Link>{word}</Link>
//   //    }
   
//   // }
//   // })
// }
  function handleErrors(e) {
    e.target.value = e.target.value.trimLeft();
  }
  return (
    <Modal
      show={state.modal}
      onHide={onHide}
      centered
      backdrop="static"
      // style={{height:"800px"}}
    >
      <Modal.Header
      // style={{
      //   backgroundColor:"rgb(31, 160, 91)"
      // }}
      >
        <Modal.Title>Legg Til Spørsmål</Modal.Title>
        <Button
          type="button"
          onClick={onHide}
          variant="outline-secondary"
          style={{ width: "60px", height: "80px" }}
          className="close"
        >
          &times;
        </Button>
      </Modal.Header>
      <Modal.Body>
        {/* <!-- Input fields come here --> */}
        <div>
          <div id="error"></div>

          <form id="postQuestions" onSubmit={onSubmit}>
            <input
              type="text"
              hidden={true}
              disabled={true}
              name="id"
              // defaultValue={categorydata.id}
            />

            <div className="row">
              <div className="col-12">
                <label
                  style={{
                    fontSize: "20px",
                    textAlign: "center",
                  }}
                >
                  {" "}
                 Spørsmål
                </label>

                <br />
                <input
                  type="text"
                  ref={faqQuestion}
                  placeholder="
                  Legg Inn Spørsmål"
                  name="faqQuestion"
                  style={{
                    height: "50px",
                    width: "100%",
                  }}
                  required
                  onChange={handleErrors}
                />

                <span className="form-errors" style={{ top: "5px" }}></span>
                <div style={{ paddingTop: "10px" }}>
                  <label
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                    }}
                  >
                    Svar
                  </label>
                  <br />
                  <textarea
                  className="links"
                    id="textarea"
                    type="text"
                    ref={faqAnswer}
                    placeholder="Skriv Inn Svaret"
                    name="faqAnswer"
                    required
                    style={{
                      whiteSpace: " pre-line",
                      height: "200px",
                      width: "100%",
                    }}
                    // onChange={handleChange}
                    onChange={handleErrors}
                  />

                  <span className="form-errors"></span>
                </div>
              </div>
              <div className="col-12" style={{ textAlign: "end" }}>
                <input
                  className="btn text-light"
                  type="submit"
                  value="SAVE"
                  style={{ textAlign: "center", backgroundColor:"#1657d5" }}
                />
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}
