import React from "react";
import { Link } from "react-router-dom";
import { getAds } from "../../bin/controllers/api";
import AdTable  from "./AdTable";
import { LoadingModal } from "../../bin/common/commonFunctions";
export default class AdvertisementListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      loading: true,
      statePages:{
        pages: [],
        filtered: [],
      }
      
    };
  }
  delay = (ms) => new Promise((res) => setTimeout(res, ms));
  componentDidMount = async () => {
    await this.delay(5000);
    const list = await getAds();
    
    const statePages={
      pages: list.data,
      filtered: list.data,
    }
    this.setState({
      statePages,
      loading: false
    });

  };
  handleFilter = (e) => {
    const filter = e.target.value.toString().toLowerCase();
    var key = e.keyCode || e.charCode;
    if (key === 8 || key === 46) {
      this.filterRecords(filter);
    } else {
      if (filter.length >= 3) {
        this.filterRecords(filter);
      }
    }
  };
  updateList=async()=>{
    this.setState({
      loading: true
    }, async ()=>{
      const list = await getAds();
      await this.delay(5000);
      const statePages={
        pages: list.data,
        filtered: list.data,
      }
      this.setState({
        statePages,
        loading: false
      });
    });
    
  }
  filterRecords = (filter) => {
   
  };
  setFilteredData=(pages)=>{
    const {statePages}= this.state
    statePages.pages={...pages}
    this.setState({
      statePages
    });
  }
  render() {
    const {loading, list} =this.state
    return (
      <div className="bg-component" style={{ padding: "20px", minHeight:"115%" }}>
        <div className="row">
          <div className="col-7 inline-block ">
            <span
              style={{
                fontFamily: "Roboto",
                fontWeight: "700",
                fontSize: "14px",
              }}
            >
              <img alt="" src={require("../../assets/ad-active.svg")} />
              <span style={{ marginLeft: "7px" }}>ANNONSE</span>
            </span>
          </div>
         {this.state.statePages.pages.length<4 && !this.state.loading? <div className="col-2">
            <Link to={`/createAdvertisement`}>
              <div
                className="Rectangle-Copy "
                //onClick={() => handleCreate()}

                style={{
                  textAlign: "center",
                  float: "right",
                  width: "153px",
                  height: "32px",
                  cursor: "pointer",
                }}
              >
                <span
                  className="Delete"
                  color="#26A881"
                  style={{ width: "118px", height: "16px" }}
                  //onClick={}
                >
                  SKAPE
                </span>{" "}
              </div>
            </Link>
          </div>
          : null}
          <div className="col-3 inline-block ">
            {/* <input
              id="searchUser"
              style={{ width: "100%", height: "32px" }}
              type="text"
              onKeyUp={this.handleFilter}
              className="prompt"
              placeholder=" SEARCH"
            /> */}
          </div>
        </div>
        {loading ? (
           <LoadingModal show={loading} centered backdrop="static" />
        ) : this.state.statePages.pages.length ? (
          <>
            <div className="row">
              <div className="col-md-12 bottom-height-table">
                <AdTable statePages={this.state.statePages} setFilteredData={this.setFilteredData} updateList={this.updateList}/>
              </div>
            </div>
          </>
        ) : (
          <div>Ingen Rader Funnet</div>
        )}
      </div>
    );
  }
}

const styles = {};
