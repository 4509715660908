import React from "react";
import { Modal, Button } from "react-bootstrap";
import swal from "sweetalert";

export default function DeleteMessage({ state, onHide , ids}) {
  const userdata = ids;
  function onCancel(e) {
    e.preventDefault();
    onHide();
  }
  function onSubmit(e) {
    e.preventDefault();
    console.log(userdata)
    const params = {
        messages: userdata.ids,
      };
      state.deleteMessages(params);
    onHide();
    swal({
      title: "Du har slettet meldingen",
      button: {
        text: "OK",
        value: true,
        visible: true,
        className: "--confirm",
        closeModal: true,
      },
    })
  }

  return (
    <Modal show={state.modal} onHide={onHide} centered backdrop="static">
      <Modal.Header>
        <Modal.Title>Slett Melding</Modal.Title>
        <Button
        style={{ width: "50px" }}
          type="button"
          onClick={onHide}
          variant="outline-secondary"
          className="close"
        >
          &times;
        </Button>
      </Modal.Header>
      <Modal.Body>
        {/* <!-- Input fields come here --> */}
        <div>
          {userdata ? (
            <form id="deleteUser" onSubmit={onSubmit}>
              <input
                type="text"
                hidden={true}
                disabled={true}
                defaultValue={userdata.id}
              />
              <div className="row" style={{ textAlign: "left" }}>
                <div className="col-12" style={{ textAlign: "center" }}>
                  <div className="col-12">
                    <span className="col-12 text-dark">
                      <h5>Er du sikker på at du vil slette meldingene?</h5>
                    </span>
                    <br />
                    <input
                      className="btn text-light bg-danger col-6"
                      type="submit"
                      value="SLETTE"
                      style={{ textAlign: "center" }}
                    />
                    <span className="col-1"></span>
                    <input
                      className="btn text-light bg-warning col-6"
                      type="button"
                      value="AVBRYTE"
                      onClick={onCancel}
                      style={{ textAlign: "center" }}
                    />
                  </div>
                </div>
              </div>
            </form>
          ) : (
            <></>
          )}
        </div>{" "}
        {/* <Button type='submit' variant='outline-primary'>
              Add new Session form
            </Button> */}
      </Modal.Body>
    </Modal>
  );
}
