import React from "react";
import { Switch, Route } from "react-router-dom";
import { categoryRoutes } from "../bin/config/routes";
// import {categoryFormRoutes} from '../bin/config/routes';
import { Categories } from "../components/Categories";
// import {Form} from '../components/Categories';
export default function CategoryComponent() {
  return (
    <Switch>
      <Route exact path={categoryRoutes[0]} component={Categories} />
      {/* <Route exact path={categoryFormRoutes[0]} component={Form}/> */}
    </Switch>
  );
}
