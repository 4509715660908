import React from "react";
import Api from "../../bin/controllers/api";
import { State, Provider } from "../config/AppContext";

import {
  token,
  loginUser,
  abort,
  createCategory,
  createSubCategory
 
} from "../../bin/controllers/api";

import { LoadingModal } from "../common/commonFunctions";

export default class AppProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...State,

      Methods: {
        handleLogin: this.handleLogin.bind(this),
        isLogin: this.isLogin.bind(this),
        handleSubmit: this.handleSubmit.bind(this),
        handleSubCategorySubmit:this.handleSubCategorySubmit(this),
        setAppState: this.setAppState,
        handleUnauthorized: this.handleUnauthorized,
        handleAbort: this.handleAbort,
        handleLogout: this.handleLogout.bind(this),
        getUser: Api.getUser,
        getCategories: Api.getCategories,
        getAppPages: Api.getAppPages,
        createCategory: Api.createCategory,
      },
    };
  }

  state = State;

  render = () => {
    return (
      <Provider value={{ ...this.state }}>
        {this.props.children}
        <LoadingModal
          centered
          backdrop={"static"}
          show={this.state.loading}
          message={this.state.message}
          onHide={() =>
            this.setState({
              loading: false,
            })
          }
        />
      </Provider>
    );
  };

  componentDidMount = () => {
    if (
      !!!this.state.authenticated &&
      token &&
      token !== null &&
      token !== "null" &&
      token.length > 0
    ) {
      return this.isLogin();
    }
    this.setAppState();
  };

  componentDidUpdate = () => {};

  setAppState(params) {
    const props = !params ? {} : { ...params };
    return this.setState(props);
  }

  componentWillUnmount = () => this.handleAbort();

  async handleLogin(params) {
    try {
      const data = await loginUser({ ...params });
      const { access_token } = data;
      const { user } = data;
      localStorage.setItem("userName", user.name);
      localStorage.setItem("token", access_token);
      return this.isLogin();
    } catch (error) {
      return this.handleUnauthorized(error);
    }
  }

  handleLogout() {
    localStorage.clear();
    return this.setAppState({
      authenticated: false,
      errorMessage: "",
        errorCode: "",
    });
  }

  isLogin() {
    return this.setAppState({
      authenticated: true,
    });
  }

  handleUnauthorized(error) {
    if (error.error_code !== 200) {
      localStorage.clear();
      return this.setAppState({
        authenticated: false,
        errorMessage: error.error_message,
        errorCode: error.error_code,
      });
    }
  }

  handleAbort() {
    return abort();
  }

  async handleSubmit(params) {

    console.log("handle submit in appProvider",params)
    try {

      const data = await createCategory({ ...params });
      console.log("inside handle submit params",data)
     
      const { name} = data;
     localStorage.setItem("name", name);
     window.location.reload(true)
      return this.setState({name});

    } catch (error) {
      console.log(error)
    }
    // try {

    //   const data = await createSubCategory({ ...params,id });
    //   console.log("inside handle subcategory submit params",data)
     
    //   const { message } = data;
    //  localStorage.setItem("message", message);
    //   return this.setState({data});
    // } catch (error) {
    //   console.log(error)
    // }
  }

  async handleSubCategorySubmit(params,id) {
    // console.log("handle submit in appProvider")
    try {

      const data = await createSubCategory({ ...params,id });
     // console.log("inside handle subcategory submit params",data)
     
      const {name} = data;
     localStorage.setItem("name", name);
      return this.setState({name});
    } catch (error) {
      console.log(error)
    }
  }
  // async handleDeleteSubmit(params){
  //   try {

  //     const data = await deleteCategoryById({ ...params });
  //     console.log("inside handle delete submit params",params)
     
  //     const { id } = data;
  //    localStorage.removeItem("id", id);
  //     return this.setState({data});
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
  
}
