import React from "react";
import { Switch, Route } from "react-router-dom";
import { advertisementRoutes } from "../bin/config/routes";

import { AdvertisementListing, AddAdvertisement, EditAdvertisement } from "../components/Advertisement";
export default function AdvertisementComponent() {
  return (
    <Switch>
      <Route exact path={advertisementRoutes[0]} component={AdvertisementListing} />
      <Route exact path={advertisementRoutes[1]} component={AddAdvertisement} />
      <Route exact path={advertisementRoutes[2]} component={EditAdvertisement} />
    </Switch>
  );
}
