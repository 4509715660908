import React from "react";
import { Modal, Button } from "react-bootstrap";
import swal from "sweetalert";
const faqQuestion = React.createRef();
const faqAnswer = React.createRef();

export default function Edit({ state, onHide }) {
  const questiondata = state.selectedQuestion;

  function onSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    var recordId = questiondata.id;
    var values = {};
    var params = {};

    formData.forEach((v, k) => Object.assign(params, { [k]: v }));

    values = {
      id: recordId,
      faqinfo: { ...params },
    };
    
    console.log("values", values);

    Promise.resolve(state.editQuestion(values))
      .then((error) => {
        if (error === undefined || error === null) {
          onHide();

          swal({
            title: "Du har oppdatert spørsmålet",
            button: {
              text: "OK",
              value: true,
              visible: true,
              className: "--confirm",
              closeModal: true,
            },
          });

        } else {
          document.getElementById("error").innerText = error.error_message;
        }
      })
      .catch((error) => {
        swal({
          title: error.error_message,
          button: {
            text: "OK",
            value: true,
            visible: true,
            className: "--confirm",
            closeModal: true,
          },
        });
      });
  }
  function handleErrors(e) {
    e.target.value = e.target.value.trimLeft();
  }

  return (
    <Modal show={state.modal} onHide={onHide} centered backdrop="static">
      <Modal.Header
      // style={{
      //   backgroundColor:"rgb(31, 160, 91)"
      // }}
      >
        <Modal.Title
          style={{
            marginLeft: "0",
            marginRight: "auto",
            display: "block",
            fontWeight: "bolder",
          }}
        >
          Rediger spørsmål og svar
        </Modal.Title>
        <Button
          type="button"
          onClick={onHide}
          variant="outline-secondary"
          className="close"
          style={{ width: "50px" }}
        >
          &times;
        </Button>
      </Modal.Header>
      <Modal.Body>
        {/* <!-- Input fields come here --> */}
        <div>
          <div id="error"></div>
          {questiondata ? (
            <form id="editQuestion" onSubmit={onSubmit}>
              <input
                type="text"
                hidden={true}
                disabled={true}
                defaultValue={questiondata.id}
              />

              <div className="row" style={{ textAlign: "left" }}>
                <div className="col-12">
                  <label
                    style={{
                      fontSize: "20px",
                      textAlign: "center",
                      // fontWeight: "bolder",
                    }}
                  >
                    {" "}
                    Spørsmål
                  </label>
                  <br />
                  <input
                    style={{
                      width: "300px",
                      height: "50px",
                    }}
                    type="text"
                    defaultValue={questiondata.faqQuestion}
                    name="faqQuestion"
                    ref={faqQuestion}
                    required
                    onChange={handleErrors}
                  />
                 <span className="form-errors" style={{ top: "5px" }}></span>
                 <div style={{ paddingTop: "20px" }}>
                  <label
                    style={{
                      fontSize: "20px",
                      textAlign: "center",
                      // fontWeight: "bolder",
                    }}
                  >
                   
                    Svar
                  </label>
                  <br/>
                  <textarea
                    type="text"
                    defaultValue={questiondata.faqAnswer}
                    name="faqAnswer"
                    ref={faqAnswer}
                    style={{
                      whiteSpace: " pre-line",
                      height: "200px",
                      width: "400px",
                    }}
                    required
                    onChange={handleErrors}
                  />
                  <span className="form-errors"></span>
                  </div>
                </div>
                <div className="col-12" style={{ textAlign: "center" }}>
                  <input
                    className="btn text-light"
                    type="submit"
                    value="SAVE"
                    style={{ textAlign: "center", backgroundColor: "#1657d5" }}
                  />
                </div>
              </div>
            </form>
          ) : (
            <h2>No data</h2>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}
