import React from "react";
import ReactTable from "react-table-6";
import { Link } from "react-router-dom";
import "react-table-6/react-table.css";
// import "./AppPages.css";
import ReactTooltip from "react-tooltip";
import { generateDate } from "../../bin/common/commonFunctions";
import {
  resetCounter
} from "../../bin/controllers/api";
import swal from "sweetalert";

function AdTable({ statePages, updateList }) {
  function handleFilter(e) {
    const filter = e.target.value.toString().toLowerCase();
    var key = e.keyCode || e.charCode;
    if (key === 8 || key === 46) {
      filterRecords(filter);
    } else {
      if (filter.length >= 3) {
        filterRecords(filter);
      }
    }
  }
 
  function filterRecords(filter) {
    var dd = statePages.pages;
    console.log(dd);
    var gg = dd.filter(
      (it) =>
        it.id.toString().includes(filter) ||
        it.contentType.toLowerCase().toString().includes(filter) ||
        //it.Admin.toLowerCase().toString().includes(filter) ||
        generateDate(it.updatedAt, "DD-MM-YYYY").includes(filter)
    );
    statePages.filtered = gg;

    statePages.setFilteredData(statePages);
  }

  async function resetCount(id){
    const res=await resetCounter(id)
    swal(
      {
        title: res?.message,
        button: {
          text: "OK",
          value: true,
          visible: true,
          className: "--confirm",
          closeModal: true,
        },
      }
      );
     
    updateList()
     
  }
  const columns = [
    {
      Header: "Bilde",
      accessor: "image",
      Cell: (row) => (
        <div
          style={{
            width: "100%",
            textAlign: "left",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          <img
            alt=""
            className="img-responsive"
            style={{ width: "82px", height: "47px" }}
            src={row.original.adImage}
          ></img>
        </div>
      ),
    },

    {
      Header: "Tittel",
      accessor: "title",
      Cell: (row) => (
        <div
          style={{
            width: "100%",
            textAlign: "left",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          <span> {row.original.adTitle}</span>
        </div>
      ),
    },
    {
      Header: "Tekst",
      accessor: "text",
      Cell: (row) => (
        <div
          style={{
            width: "100%",
            textAlign: "left",
            textOverflow: "ellipsis",
            overflow: "hidden",
            margin:"0px 2px",
          }}
        >
          <span> {row.original.adDescription}</span>
        </div>
      ),
    },
    {
      Header: "Telle",
      accessor: "count",
      Cell: (row) => (
        <div
          style={{
            width: "100%",
            textAlign: "left",
            textOverflow: "ellipsis",
            overflow: "hidden",
            margin:"0px 2px",
          }}
        >
          <span> {row.original.adCount}</span>
        </div>
      ),
    },
    {
      Header: "Lenke",
      accessor: "link",
      Cell: (row) => (
        <div
          style={{
            width: "100%",
            cursor: "pointer",
            textAlign: "left",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          <a
            style={{ color: "#00a884" }}
            target="_blank"
            href={`${row.original.adLink}`}
            rel="noreferrer"
          >
            {row.original.adLink}
          </a>
        </div>
      ),
    },

    {
      Header: "Handling",
      accessor: "",
      sortable: false,
      Cell: (row) => (
        <>
          <Link
            to={{
              pathname: `/editAdvertisement`,
              state: {
                data: {
                  id:row.original.id,
                  adDetails: {
                    adTitle: row.original.adTitle,
                    adDescription: row.original.adDescription,
                    adLinkLabel: row.original.adLinkLabel,
                    adLink: row.original.adLink,
                  },
                  adImage: row.original.adImage,
                },
              },
            }}

          >
          
            <img
              style={{ marginRight: "10px" }}
              src={require("../../assets/edit.svg")}
              alt="Redigere"
              data-tip="Redigere"
            />
            <ReactTooltip />
          </Link>
          <img
            style={{ marginLeft: "-5px", width: "40px"}}
            src={require("../../assets/reset.svg")}
            alt="Nullstille"
            data-tip="Nullstille"
            onClick={()=>{
              resetCount(row.original.id)
            }}
          />
        </>
      ),
      sortable: false,
    },
  ];
  return (
    <div>
      <br />
      <div className="row ">
        <div className="col-9 inline-block ">
         
        </div>
      
      </div>
      <div>
        <ReactTable
          previousText="Tilbake"
          nextText="Neste"
          className="bg-white"
          style={{
            fontSize: "14px",
            fontFamily: "Roboto",
            paddingLeft: "0px",
          }}
          minRows={1}
          noDataText="Ingen Rader Funnet"
          columns={columns}
          data={statePages.filtered}
          filterable={false}
          sortable
          resizable={false}
          defaultPageSize={25}
          showPagination={false}
          getTrProps={(state, rowInfo, instance) => {
            if (rowInfo) {
              return {
                style: {
                  color: "#68707d",
                },
              };
            }
            return {};
          }}
        ></ReactTable>
      </div>
    </div>
  );
}

export default AdTable;
