import React from "react";

// const AppContext = React.createContext();

// export default AppContext;

// export const getStateValue = React.useContext(AppContext);


export const Methods =  {
    handleLogin: () =>{},
    isLogin: () =>{},
    setAppState: () =>{},
    handleUnauthorized: () =>{},
    setState: () =>{},
    handleLogout: () =>{},
    handleSubmit:()=>{},
    handleSubCategorySubmit:()=>{}
    
}
export const  State = {
    authenticated : false,
    loading: false,
    errorMessage : "",
    errorCode : "",
    Methods : {
        handleLogin: () =>{},
        handleLogout: () =>{},
        
        
    }
}


export const AppContext = React.createContext(State);

export const { Consumer, Provider } = AppContext;
export const useStateValue = () => React.useContext(AppContext);

export default React.createContext(null);