import React from "react";
import { useStateValue } from "../../bin/config/AppContext";
import { Card } from "react-bootstrap";
//const { Methods } = useStateValue();
const email = React.createRef();
const password = React.createRef();

export default function Login() {
  const { Methods, errorMessage, authenticated } = useStateValue();

  function handleChange() {
    document.getElementById("error").innerText = "";
  }
  async function onSubmit(e) {
    e.preventDefault();
    const query = {
      email: email.current !== null ? email.current.value : "",
      password: password.current !== null ? password.current.value : "",
    };

    await Promise.resolve(Methods.handleLogin(query)).then((error) => {
      if (error === undefined || error === null) {
        if (document.getElementById("error") !== null)
        {
          document.getElementById("error").innerText = "Ugyldige Legitimasjon.";
        }
      } else {
        document.getElementById("error").innerText = "";
      }
    });
  }
  return (
    <div
      id="Login"
      style={{
        height: "100vh",
        width: "100%",
        //backgroundImage: `url(${require("../../assets/bg-banner.jpg")})`,
      }}
    >
      <div className="col-md-4 offset-md-4" style={{ paddingTop: "10%" }}>
        <Card style={{ width: "100%", height: "80%", border:"none" }}>
          <form onSubmit={onSubmit} style={{ padding: "20px" }}>
            <div
              className="row"
              style={{
                textAlign: "center",
                verticalAlign: "middle",
                fontFamily: "Roboto",
                    fontSize: "16px",
              }}
            >
              <img
              style={{maxWidth:"240px",maxHeight:"85px"}}
                className="col-md-6 offset-md-3"
                src={require("../../assets/loginlogo.png")}
              />
             
            </div>
          
            <br/>
            <div  className="col-md-6 offset-md-3" style={{display:"inline"}}>
            <label  id="error" className="text-danger"></label>
            </div>
            <div
              className="row"
              style={{
                textAlign: "center",
                verticalAlign: "middle",
                fontFamily: "Roboto",
                    fontSize: "16px",
              }}
            >
            
              <div className="col-12" style={{ padding: "10px" }}>
                <input
                className = "borderlogin  login-input"
                id="email"
                  onChange={() => handleChange()}
                  type="email"
                  name="email"
                  placeholder="E-postadresse"
                  ref={email}
                  required
                  style={{ paddingBottom:"10px",width: "331px",textAlign:"center",fontFamily:"Roboto",fontSize:"18px" }}
                />
              </div>
              <div className="col-12" style={{ padding: "10px" }} >
                <input
                id="password"
                className="borderlogin login-input"
                  onChange={() => handleChange()}
                  type="password"
                  name="password"
                  placeholder="Passord"
                  ref={password}
                  style={{ paddingBottom:"10px",width: "331px",textAlign:"center" ,fontFamily: "Roboto",
                  fontSize: "16px",}}
                  required
                />
              </div>
              {/* <div
                className="col-6"
                style={{ padding: "10px", textAlign: "left" }}
              >
                <div className="roundCheck col-1 inline-block">
                  <input type="checkbox" id="checkbox" />
                  <label for="checkbox"></label>
                </div>
                <span
                  className="col-md-11"
                  style={{ width: "100%", color: "#9b9b9b" }}
                >
                  Remember Me
                </span>
              </div>
              <div
                className="col-6"
                style={{ padding: "10px", textAlign: "right" }}
              >
                <span style={{ width: "100%" }}>Forgot Password</span>
              </div> */}
              {/* <div style={{height:"120px"}}></div>
              <br/> */}
              <div className="col-12" style={{ paddingTop:"73px" }}>
                <input
                className="login-input"
                  type="submit"
                  value="Logg inn"
                  style={{
                    width: "202px",
                    height:"49px !important",
                    backgroundColor: "#00A884",
                    borderRadius: "70px",
                    padding: "10px",
                    color: "#ffffff",
                    fontFamily: "Roboto",
                    fontSize: "18px",
                    fontWeight: "500",
                    border:"none"
                  }}
                />
              </div>
            </div>

            
          </form>
        </Card>
      </div>
      {/*  */}
    </div>
  );
}
