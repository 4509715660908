import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useStateValue } from "../../bin/config/AppContext";
import swal from "sweetalert";
const name = React.createRef();
//const id=React.createRef();

export default function AddSubCat({
  stateCategory,
  stateSubCategory,
  onHide2,
}) {
  console.log(stateCategory, "addsubcat");
  const categorydata = stateCategory.selectedCategory;
  const { Methods } = useStateValue();

  function handleErrors(e) {
    e.target.value = e.target.value.trimLeft();
  }
  function onSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    var recordId = categorydata.id;
    var values = {};
    var params = {};
    console.log("before foreach", stateCategory);
    formData.forEach((v, k) => {
      console.log("kv", k, v);
      Object.assign(params, { [k]: v });
    });

    values = {
      id: recordId,
      categoryinfo: { ...params },
      // categoryinfo: { subCategoryName: subCategoryName.current !== null ? subCategoryName.current.value : "" },
    };

    Promise.resolve(stateSubCategory.postSubCategory(values))
      .then((error) => {
        if (error === undefined || error === null) {
          onHide2();
          swal({
            title: "Du har lagt til underkategorien",
            button: {
              text: "OK",
              value: true,
              visible: true,
              className: "--confirm",
              closeModal: true,
            },
          });
        } else {
          document.getElementById("error").innerText = error.error_message;
        }
      })
      .catch((error) => {
        swal({
          title: error.error_message,
          button: {
            text: "OK",
            value: true,
            visible: true,
            className: "--confirm",
            closeModal: true,
          },
        });
      
        
      });
  }

  return (
    <Modal
      show={stateSubCategory.modal}
      onHide={onHide2}
      centered
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title
          style={{
            marginLeft: "0",
            marginRight: "auto",
            display: "block",
          }}
        >
          Legg Til Underkategori
        </Modal.Title>
        <Button
          type="button"
          onClick={onHide2}
          variant="outline-secondary"
          className="close"
          style={{ width: "50px" }}
        >
          &times;
        </Button>
      </Modal.Header>
      <Modal.Body>
        {/* <!-- Input fields come here --> */}
        <div>
          <div id="error"></div>

          <form id="postSubCategory" onSubmit={onSubmit}>
            <input
              type="text"
              hidden={true}
              disabled={true}
              name="id"
              // defaultValue={categorydata.id}
            />

            <div className="row">
              <div className="col-12">
                <label className="col-5">
                  {/* {" "} */}
                  Underkategorinavn
                </label>
                <input
                  style={{ paddingRight: "10px" }}
                  className="col-6"
                  type="text"
                  ref={name}
                  placeholder="Skriv Inn Underkategorinavn"
                  name="name"
                  required
                  onChange={handleErrors}
                />
                <span className="form-errors"></span>
              </div>
              <div className="col-12" style={{ textAlign: "center" }}>
                <input
                  className="btn text-light"
                  type="submit"
                  value="SAVE"
                  style={{ textAlign: "center", backgroundColor: "#1657d5" }}
                />
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}
