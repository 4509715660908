import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { getAppPageData, getAllFAQs } from "../../bin/controllers/api";
//import "react-responsive-carousel/dist/carousel.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import { Carousel } from "react-responsive-carousel";
import { Accordion, Card, Button } from "react-bootstrap";
import "./AppPages.css";
//import {OverlayTrigger,Button,Popover} from 'react-bootstrap'
// var Carousel = require('react-responsive-carousel').Carousel;

const AccordianNew = ({ children = ['', '', '', ''] }) => {

  const [isToggleOn, setToggle] = useState(false);

  return (
    <div>

      <div onClick={() => setToggle(!isToggleOn)} className="faq-question">
        {
          children[0]
        }

        {
          isToggleOn ? children[1] : children[2]
        }
      </div>

      <div style={{ height: isToggleOn ? '100%' : '0', overflow: 'hidden' }}>
        {
          children[3]
        }
      </div>

    </div>
  );
}

export default function AppPagePreview(props) {
  const [state, setState] = React.useState({
    pageData: null,
    faqData: null,

  });
  const [toggle, setToggle] = React.useState({
    toogleid: null,
  });
  const { type } = props.match.params;
  // console.log(type, "type");
  async function loadPageData() {
    //else {
    const data1 = await getAppPageData(type);
    const data2 = await getAllFAQs();


    setState({
      pageData: data1.data,
      faqData: data2.data
    });

    if (document.getElementById("content") !== null)
      document.getElementById("content").innerHTML = data1.data.content;
    if (type === "faq") {
      setState({
        faqData: data2.data,
        pageData: data1.data
      });
    }
    //}
  }

  function onToggle(id, e) {
    // console.log(e.target);

    var gg = document.getElementsByClassName("fa-angle-up");
    if (gg.length > 0 && gg[0].id === "" + id) {
      setToggle({
        toogleid: null,
      });
    } else {
      setToggle({
        toogleid: id,
      });
    }
  }
  const handleChange = React.useCallback(() => {
    if (state.pageData !== null || state.faqData !== null) return;
    loadPageData();
  }, [loadPageData, state]);

  function getImages() {
    var images = [];
    const imaeValues = state.pageData.pageImages;
    for (var i = 0; i < imaeValues.length; i++) {
      images.push(
        <div>
          <img src={imaeValues[i].imagePath} />
        </div>
      );
    }

    return images;
  }
  React.useEffect(handleChange, [state]);

  return (
    <div className="app-page-preview">
      {/* {console.log("faqdata", state.faqData)} */}

      {type !== "faq" ? (
        <div className="bg-component" style={{ backgroundColor: 'white' }}>
          <div>
            <div className="col-md-12">

              {/* <div style={{marginTop:"15px"}} /> */}
              {state.pageData !== null ?
                <>
                  <div className="row">

                    <div className="col-md-12" style={{}}>
                      <div style={{ fontSize: '28px', marginLeft:"-1px" }}><b>{state.pageData.title}</b></div>
                    </div>
                  </div>
                  <br />
                  <Carousel
                    showArrows={true}
                    style={{ height: "200px !important" }}
                  >
                    {getImages()}
                  </Carousel>
                  <div className="row">
                    <div className="col-md-12" id="content"></div>
                  </div>
                </> :
                (<></>)
              }
            </div>

          </div>
        </div>
      ) : (
          <>
            {type === "faq" ? (
              <div className="bg-component" style={{ backgroundColor: 'white', padding: '0 .6rem', }}>
                {
                  (state.pageData !== null && state.FAQData !== null) &&

                  <h1 style={{ fontWeight: 'bold', paddingTop: '1rem', paddingLeft: '.8rem', fontSize:"27px" }}>{state.pageData.title}</h1>

                }
                <hr />
                <div>
                  <br />
                  <div className="col-md-6 offset-md-3" style={{ marginLeft: 0 }} >
                    {/* <h3
               style={{
                 textAlign:"center",
                 marginTop:"20px",
                 color:"rgb(11, 192, 132)",
                 fontWeight:"bolder",
                 fontSize:"30px"
                      
              }}
               >Browse the topics below to find what you are looking for!! </h3> */}
                    {/* <h2 style={{ padding: '.8rem 0' }}>Legge ut tjenester</h2> */}

                    {/* ------------------ new faq accordian start ------------------ */}
                    <div>

                      {
                        state.faqData !== null && state.pageData !== null &&

                        state.faqData.map(faq => <div key={faq.id} style={{ paddingBottom: '3rem'}}>

                          <h2 style={{ paddingTop: '', paddingBottom: '.8rem' }}>{faq.title}</h2>

                          {
                            faq.questions.length !== 0 &&

                            faq.questions.map((questions, i) => <div key={questions.id} style={{ color: '#595959'}}>

                              <AccordianNew>

                                <span className="faq-ques-span">{questions.faqQuestion}</span>

                                <i className="fa fa-caret-up" aria-hidden="true"></i>
                                <i className="fa fa-caret-down" aria-hidden="true"></i>

                                <div className="faq-answer">{questions.faqAnswer}</div>

                              </AccordianNew>

                            </div>
                            )
                          }

                        </div>
                        )

                      }




                    </div>




                  </div>
                </div>
              </div>
            ) : (
                <></>
              )}
          </>

        )}
    </div>
  );
}
