import React from "react";
import { Modal, Button } from "react-bootstrap";
import swal from "sweetalert";
export default function DeleteSubCategory({
  stateCategory,
  stateSubCategory,
  onHide2,
}) {
  const categorydata = stateSubCategory.selectedSubCategory;
  const category = stateCategory.selectedCategory;
  console.log("selectedCategory in Delete", categorydata);
  function onCancel(e) {
    e.preventDefault();
    onHide2();
  }
  function onSubmit(e) {
    e.preventDefault();
    var recordId = categorydata.id;
    var params = {};
    params = {
      id: recordId,
    };

    Promise.resolve(stateSubCategory.deleteSubCategory(params)).then(
      (error) => {
        if (error === undefined || error === null) {
        } else {
          if (error.ErrorModel !== undefined) {
            swal({
              title: error.ErrorModel.errorMessage,
              button: {
                text: "OK",
                value: true,
                visible: true,
                className: "--confirm",
                closeModal: true,
              },
            });
           
            onHide2();
            return;
          }
        }
      }
    );

    onHide2();
    swal({
      title: "Du har slettet underkategorien",
      button: {
        text: "OK",
        value: true,
        visible: true,
        className: "--confirm",
        closeModal: true,
      },
    });
  }


  return (
    <Modal
      show={stateSubCategory.modal}
      onHide2={onHide2}
      centered
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title
          style={{
            marginLeft: "0",
            marginRight: "auto",
            display: "block",
          }}
        >
         Slett Underkategori
        </Modal.Title>
        <Button
          type="button"
          onClick={onHide2}
          variant="outline-secondary"
          className="close"
          style={{ width: "50px" }}
        >
          &times;
        </Button>
      </Modal.Header>

      {console.log("category in delete", category)}
      {category.jobSubCategories.length > 1 === true ? (
        <Modal.Body>
          {/* <!-- Input fields come here --> */}
          <div>
            {categorydata ? (
              <form id="deleteSubCategory" onSubmit={onSubmit}>
                <input
                  type="text"
                  hidden={true}
                  disabled={true}
                  defaultValue={categorydata.id}
                />
                <div className="row" style={{ textAlign: "left" }}>
                  <div className="col-12" style={{ textAlign: "center" }}>
                    <div className="col-12">
                      <span className="col-12 text-dark">
                        <h5>
                        Er du sikker på at du vil slette underkategorien?
                        </h5>
                      </span>
                      <br />
                      <input
                        className="btn text-light bg-danger col-6"
                        type="submit"
                        value="SLETTE"
                        style={{ textAlign: "center" }}
                      />
                      <span className="col-1"></span>
                      <input
                        className="btn text-light bg-warning col-6"
                        type="button"
                        value="AVBRYTE"
                        onClick={onCancel}
                        style={{ textAlign: "center" }}
                      />
                    </div>
                  </div>
                </div>
              </form>
            ) : (
              <></>
            )}
          </div>{" "}
        </Modal.Body>
      ) : (
        <Modal.Body style={{ textAlign: "center", fontSize: "20px" }}>
         Kategorien kan ikke være tom
        </Modal.Body>
      )}
    </Modal>
  );
}
