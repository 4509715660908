import React from "react";
import ReactTable from "react-table-6";
import Add from "./Add";
import Delete from "./Delete";
import ReactTooltip from "react-tooltip";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import "react-table-6/react-table.css";
import ReactHtmlParser  from "react-html-parser";
import ViewMessage from "./viewMessage";

function Table({
  onMessageView,
  stateMessage,
  onHide,
  onShow,
  onDelete,
  selectAll,
  deselectAll,
}) {
  const [historyTab, setHistoryTab] = React.useState(Boolean);
  const [ids, setIds] = React.useState({ ids: [] });
  console.log("statemessage",stateMessage)
  function handleFilter(e) {
    const filter = e.target.value.toString().toLowerCase();
    var key = e.keyCode || e.charCode;
    if (key === 8 || key === 46) {
      filterRecords(filter);
    } else {
      if (filter.length >= 3) {
        filterRecords(filter);
      }
    }
  }
  function MessageView(row) {
    
  console.log(row,"row")
    stateMessage.selectedMessage=row
   
    onMessageView()
  }
  function filterRecords(filter) {
   
    var dd = stateMessage.inbox;
    var hh = stateMessage.archived;
    var gg = dd.filter(
      (it) =>
        it.zipcode.toString().includes(filter) ||
        it.id.toString().includes(filter) ||
        it.email.toLowerCase().toString().includes(filter) ||
        it.comment.toLowerCase().toString().includes(filter)
    );

    var kk = hh.filter(
      (it) =>
        it.zipcode.toString().includes(filter) ||
        it.id.toString().includes(filter) ||
        it.email.toLowerCase().toString().includes(filter) ||
        it.comment.toLowerCase().toString().includes(filter)
    );

    stateMessage.filteredInbox = gg;
    stateMessage.filteredArchived = kk;
    stateMessage.setfilteredData(stateMessage);
  }

  function handleCreate() {
    
  }
  function isChecked(row) {
    return row.original.isChecked;
  }
  function deleteMessage(row) {
    console.log("row id",row.original.id);
    const ids = [row.original.id];
    
    deleteMsg(ids);
  }

  function deleteMsg(ids) {
    setIds({ ids: ids });
    //const id=ids
    // const params = {
    //   messages: ids,
    // };
    // stateMessage.deleteMessages(params);
    onDelete();
  }

  function handleSingleCheckboxChange(rowIndex) {
    if (!historyTab) {
      if (stateMessage.inbox[rowIndex].isChecked)
        stateMessage.inbox[rowIndex].isChecked = false;
      else stateMessage.inbox[rowIndex].isChecked = true;
    } else {
      if (stateMessage.archived[rowIndex].isChecked)
        stateMessage.archived[rowIndex].isChecked = false;
      else stateMessage.archived[rowIndex].isChecked = true;
    }

    var params = {
      inbox: stateMessage.inbox,
      archived: stateMessage.archived,
      selectall: false,
      modal: false,
      isDelete: false,
      isView:false,
      isHistortTab: false,
      checked: [],
      updateChecked: stateMessage.updateChecked,
      error: false,
      filteredInbox: stateMessage.filteredInbox,
      filteredArchived: stateMessage.filteredArchived,
      emails: [],
      zipcodes: [],
      saveMessage: stateMessage.saveMessage,
      deleteMessages: stateMessage.deleteMessages,
      setfilteredData: stateMessage.setfilteredData,
    };
    stateMessage.updateChecked(params);
  }


  function bulkDelete() {
    var ids = [];
    if (!historyTab) {
      stateMessage.inbox.forEach(function (e, index) {
        if (e.isChecked) ids.push(e.id);
      });
    } else {
      stateMessage.archived.forEach(function (e, index) {
        if (e.isChecked) ids.push(e.id);
      });
    }
    //console.log(ids);
    if (ids.length > 0) deleteMsg(ids);
    else
      swal({
        title: "Ingen oppføringer er valgt for å slette",
        button: {
          text: "OK",
          value: true,
          visible: true,
          className: "--confirm",
          closeModal: true,
        },
      });
  }
  function handleChange(e) {
    var selectAll1 = !stateMessage.selectall;
    if (selectAll1) {
      selectAll();
    } else {
      deselectAll();
    }
    if (!historyTab) {
      stateMessage.inbox.forEach(function (e, index) {
        e.isChecked = selectAll1;
      });
    } else {
      stateMessage.archived.forEach(function (e, index) {
        e.isChecked = selectAll1;
      });
    }

    var params = {
      inbox: stateMessage.inbox,
      archived: stateMessage.archived,
      selectall: selectAll1,
      modal: false,
      isDelete: false,
      isView:false,
      isHistortTab: false,
      checked: [],
      updateChecked: stateMessage.updateChecked,
      error: false,
      filteredInbox: stateMessage.filteredInbox,
      filteredArchived: stateMessage.filteredArchived,
      emails: [],
      zipcodes: [],
      saveMessage: stateMessage.saveMessage,
      deleteMessages: stateMessage.deleteMessages,
      setfilteredData: stateMessage.setfilteredData,
    };
    stateMessage.updateChecked(params);
  }
  const columns = [
    {
      Header: (
        <div id="ff" style={{ textAlign: "center" }}>
          <input
            type="checkbox"
            onChange={handleChange}
            checked={stateMessage.selectall}
          />
        </div>
      ),
      width:"80",
      Cell: (row) => (
        <div className="col-12" style={{ textAlign: "center" }}>
          <input
            id={row.index}
            type="checkbox"
            checked={isChecked(row)}
            onChange={() => handleSingleCheckboxChange(row.index)}
          />
        </div>
      ),
      sortable: false,
      filterable: false,
    },
    {
      Header: "Id",
      accessor: "id",
      show: false,
    },
    {
      Header: "Post nr.",
      accessor: "zip_code_start",
      width:"140",
      Cell: (row) => (
        <span>{row.original.zip_code_start !== "" && row.original.zip_code_start !== null ? row.original.zip_code_start +" - "+  row.original.zip_code_end : "-"}</span>
      ),
    },
    {
      Header: "E-post",
      accessor: "email",
      width:"380",
      Cell: (row) => (
        <span>{row.original.email !== "" ? row.original.email : "-"}</span>
      ),
    },
    {
      Header: "Tittel",
      accessor: "title",
      width:"430",
      Cell: (row) => (
      
        <div  style ={{ width: "100%",cursor:"pointer"  , textAlign:"left",textOverflow: "ellipsis", overflow:"hidden"}} onClick={() =>MessageView(row.original)}>
          
          <span style={{ color: "#00a884" }}> {row.original.title}</span>
        </div>
      ),
    },
    // {
    //   Header: "Meldinig",
    //   accessor: "comment",
    //   width:"550",
    //   Cell:(row)=> (
    //     <div  >{ ReactHtmlParser (row.original.comment) }</div>
    //   )
    // },
    {
      Header: "Handling",
      Cell: (row) => (
        <label onClick={() => deleteMessage(row)} style={{ cursor: "pointer" }}>
          <img
            alt=""
            src={require("../../assets/delete.svg")}
            data-tip="Delete"
          />
          <ReactTooltip />
        </label>
      ),
      width:"80",
      sortable: false,
      filterable: false,
    },
  ];

  const columns1 = [
    // {
    //   Header: "isChecked",
    //   accessor: "isChecked",

    // },
    {
      
      Header: (
        <div id="ff" style={{ textAlign: "center" }}>
          <input
            type="checkbox"
            onChange={handleChange}
            checked={stateMessage.selectall}
          />
        </div>
      ),
      width:"40",
      Cell: (row) => (
        <input style={{  width:"10px"}}
          id={row.index}
          type="checkbox"
          checked={isChecked(row)}
          onChange={() => handleSingleCheckboxChange(row.index)}
        />
      ),
      sortable: false,
      filterable: false,
      show: false,
    },
    {
      Header: "Id",
      accessor: "id",
      width:"10",
      show: false,
    },
    {
      Header: "Post nr.",
      accessor: "zip_code_start",
      width:"140",
      Cell: (row) => (
        
        <span>{row.original.zip_code_start !== "" && row.original.zip_code_start !== null ? row.original.zip_code_start +" - "+  row.original.zip_code_end : "-"}</span>
      ),
    },
    {
      Header: "E-post",
      accessor: "email",
      width:"500",
      Cell: (row) => (
        <span>{row.original.email !== "" ? row.original.email : "-"}</span>
      ),
    },
    {
      Header: "Tittel",
      accessor: "title",
      width:"600",
      Cell: (row) => (
        <div  style ={{ width: "100%",cursor:"pointer"  , textAlign:"left",textOverflow: "ellipsis", overflow:"hidden"}} onClick={() =>MessageView(row.original)}>
          
          <span style={{ color: "#00a884" }}> {row.original.title}</span>
        </div>
      ),
    },
    // {
    //   Header: "Melding",
    //   accessor: "comment",
    //   width:"70",
    //   Cell:(row)=> (
    //     <div  >{ ReactHtmlParser (row.original.comment) }</div>
    //   )
    // },
  ];

  return (
    <div >
      <div className="row " >
        <div className="col-7 inline-block ">
          <span
            style={{
              fontFamily: "Roboto",
              fontWeight: "700",
              fontSize: "14px",
            }}
          >
            <img alt="" src={require("../../assets/messages-active.svg")} />
            <span style={{ marginLeft: "7px" }}>MELDINGER</span>
          </span>
        </div>
        <div className="col-2">
        <Link to={`/createMessage`}>
          <div
            className="Rectangle-Copy "
            //onClick={() => handleCreate()}
            
            style={{
              textAlign: "center",
              float: "right",
              width: "153px",
              height: "32px",
              cursor: "pointer",
            }}
          >
            
           
          
            <span
              className="Delete"
              color="#26A881"
              style={{ width: "118px", height: "16px" }}
              //onClick={}
            >
              SKRIV MELDING
            </span>{" "}
          </div>
          </Link>
        </div>
        <div className="col-3 inline-block ">
          <input
            id="searchUser"
            style={{ width: "100%", height: "32px" }}
            type="text"
            onKeyUp={handleFilter}
            className="prompt"
            placeholder="  Søk "
          />
        </div>
      </div>
      <br />
      <div>
        <div>
         
            <div className="row">
              <div className="col-md-12">
                <div
                  id="bulkDelete"
                  className="Rectangle-Copy "
                  onClick={() => bulkDelete()}
                  style={{
                    textAlign: "center",
                    float: "right",
                    width: "93px",
                    height: "32px",
                    cursor: "pointer",
                  }}
                >
                  <span
                    className="Delete"
                    style={{ width: "50px", height: "16px" }}
                  >
                    DELETE
                  </span>{" "}
                </div>
              </div>
            </div>
      <br/>
            <ReactTable
            previousText="Tilbake"  nextText="Neste"
            pageText="Side" ofText="av"
            rowsText="rader"
              style={{
                backgroundColor: "#ffffff",
                fontSize: "14px",
                fontFamily: "Roboto",
              }}
              className="user-table"
              minRows={1}
              noDataText="Ingen Rader Funnet"
              columns={columns}
              data={stateMessage.filteredInbox}
              filterable={false}
              sortable
              resizable={false}
              defaultPageSize={25}
              getTrProps={(state, rowInfo, instance) => {
                if (rowInfo) {
                  return {
                    style: {
                      color: "#68707d",
                    },
                  };
                }
                return {};
              }}
            ></ReactTable>
        
          {stateMessage.isDelete ? (
            <Delete state={stateMessage} onHide={onHide} ids={ids} />
          ) : (
            stateMessage.isView ?
            (<ViewMessage state={stateMessage} onHide={onHide} />):
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default Table;
