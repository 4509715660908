import React from "react";
import { Switch, Route } from "react-router-dom";
import { messageRoutes } from "../bin/config/routes";

import { MessageListing, AddMessages } from "../components/Messages";
export default function MessageComponent() {
  return (
    <Switch>
      <Route exact path={messageRoutes[0]} component={MessageListing} />
      <Route exact path={messageRoutes[1]} component={AddMessages} />
    </Switch>
  );
}
