
import React from 'react';
import CategoryDropdown from './CategorySelect';
import SubCategoryModal from './AddSubCategory';
import AddCat from './AddCat'
import AddSubCat from './Add'

function List({stateCategory,stateSubCategory, onHide, onHide2,onShow2,onShow,onAdd,onAdd2,onDelete,onDelete2,onBlock,onBlock2,onEdit,onEdit2}) {

  return (
    <div>

      <CategoryDropdown 
      stateSubCategory={stateSubCategory}
      stateCategory={stateCategory } 
      onHide={onHide}
      onHide2={onHide2}
       onAdd={onAdd} 
       onAdd2={onAdd2}
        onEdit={onEdit}
        onEdit2={onEdit2}
         onShow={onShow}
         onShow2={onShow2}
          onDelete={onDelete}
          onDelete2={onDelete2} 
          onBlock={onBlock}
          onBlock2={onBlock2}
         
          />
      {/* <CategoryModal stateCategory={stateCategory} onHide={onHide} onShow={onShow} />
      <Form stateCategory={stateCategory} onHide={onHide}  onShow={onShow} /> */}
    {/* <SubCategoryModal stateSubCategory={stateSubCategory} onHide2={onHide2} onShow2={onShow2}  /> */}
    {/* <AddCat stateCategory={stateCategory } onHide={onHide} onAdd={onAdd}  onShow={onShow} />
    <AddSubCat stateSubCategory={stateSubCategory} onHide2={onHide2} onShow2={onShow2}  /> */}
   
    </div>
  );
}

export default List;
