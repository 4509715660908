import React from "react";
import { Switch, withRouter, Route } from "react-router-dom";
import {
  freeRoutes,
} from "../bin/config/routes";
import {  AppPagePreview } from "../components/AppPages";
export default function FreePagesComponent() {
  return (
    <Switch>
      <Route
        exact
        path={freeRoutes[0]}
        component={AppPagePreview}
      />
    </Switch>
  );
}
