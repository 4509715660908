import React from "react";
//import axios from  '../bin/api/config';
import ReactTable from "react-table-6";

import "react-table-6/react-table.css";
import {
  generateDate,
} from "../../bin/common/commonFunctions";
import ViewJob from "./ViewJob";
import { getJobById } from "../../bin/controllers/api";

function Table({ stateJob, onHide, onShow }) {
  async function viewJob(rowInfo) {
    const data1 = await getJobById(rowInfo.id);
    stateJob.selectedjob = data1;
    onShow();
  }
  function changeStatus(rowIndex) {
    if (stateJob.jobs[rowIndex].isVisible) {
      stateJob.updateStatus(stateJob.jobs[rowIndex].id, 0);
    } else {
      stateJob.updateStatus(stateJob.jobs[rowIndex].id, 1);
    }
    document.getElementById("searchJob").value = "";
  }

  function isVisible(row) {
    return row.original.isVisible;
  }
  function getColour(status) {
    if (status === "Published") return "#2B57D5";
    else if (status === "Semi Complete") return "#e60f0f";
    else if (status === "Completed") return "#24a884";
    else if (status === "Performer Accepted") return "#f45617" ;
    else if (status === "Have applications") return "#338ab5";
    else if (status === "Assigned performer") return "#ff901b";
  }

  function handleFilter(e) {
    const filter = e.target.value.toString().toLowerCase();
    var key = e.keyCode || e.charCode;
    if (key === 8 || key === 46) {
      filterRecords(filter);
    } else {
      if (filter.length >= 3) {
        filterRecords(filter);
      }
    }
  }
  function filterRecords(filter) {
    var dd = stateJob.jobs;
    var gg = dd.filter(
      (it) =>
        it.title.toLowerCase().includes(filter) ||
        it.id.toString().includes(filter) ||
        it.jobCategories.name.toLowerCase().toString().includes(filter) ||
        it.jobStatus.translation.toLowerCase().toString().includes(filter) ||
        generateDate(it.createdAt, "DD-MM-YYYY").includes(filter)
    );
    stateJob.filtered = gg;
    stateJob.setfilteredJobs(stateJob);
  }

  const columns = [
    {
      Header: "Id",
      accessor: "id",
      Cell: (row) => (
        <div style={{ width: "100%" }} >
          {" "}
          {row.original.id}
        </div>
      ),
      show: false,
    },

    {
      Header: "Tittel",
      accessor: "title",
      Cell: (row) => (
        <div  style ={{ width: "100%",cursor:"pointer"  , textAlign:"left",textOverflow: "ellipsis", overflow:"hidden"}} onClick={() => viewJob(row.original)}>
          <img
            alt=""
            className="img-responsive"
            style={{ width: "30px", height: "30px" }}
            src={row.original.jobImages[0].imagePath}
          ></img>
          <span style={{ color: "#00a884" }}> {row.original.title}</span>
        </div>
      ),
    },
    {
      Header: "Kategori",
      accessor: "jobCategories.name",
      // Cell: (row) => (
      //   <div style={{ width: "100%" }} >
      //     {" "}
      //     {row.original.jobCategories.name}
      //   </div>
      // ),
    },
    {
      Header: "Publisert",
      accessor: "createdAt",
      Cell: (row) => (
        <div style={{ width: "100%" }} >
          {" "}
          {generateDate(row.original.createdAt, "DD-MM-YYYY")}
        </div>
      ),
    },
    {
      Header: "Status",
      accessor: "jobStatus.name",
      filterable: false,
      Cell: (row) => (
        <div style={{ width: "100%", textAlign:"left" }} >
          {" "}
          <span
            className="dot"
            style={{ backgroundColor: getColour(row.original.jobStatus.name) }}
          ></span>
          <span style={{ color: getColour(row.original.jobStatus.name) }}>
            &nbsp;&nbsp;{row.original.jobStatus.translation}
          </span>
        </div>
      ),
    },
    {
      Header: "Tilstand",
      accessor: "isVisible",
      //sortable: false,
      filterable: false,
      Cell: (row) => (
        <label className="switch">
          <input
            type="checkbox"
            //defaultChecked={isVisible(row)}
            checked={isVisible(row)}
            onChange={() => changeStatus(row.index)}
          />

          <span className="slider round">
            <div className="row">
              <span
                className="col-5"
                style={{
                  textAlign: "center",
                  fontSize: "12px",
                  paddingLeft: "30px",
                  color:"#ffffff",
                }}
              >
                Aktiv
              </span>
              <span
                className="col-7"
                style={{
                  textAlign: "center",
                  fontSize: "12px",
                  paddingRight: "20px",
                  color:"#ffffff",
                }}
              >
                Inaktiv
              </span>
            </div>
          </span>
        </label>
      ),
    },
  ];

  return (
    <div>
      <div className="row ">
        <div className="col-9 inline-block ">
          <span style={{ fontFamily:"Roboto",fontWeight: "700",fontSize:"14px" }}>
            <img alt="jobs" src={require("../../assets/jobs-overview-active.svg")} />
            <span style={{marginLeft:"7px"}}>JOBBER</span>
          </span>
        </div>
        <div className="col-3 inline-block ">
          <input
            id="searchJob"
            style={{ width: "100%" }}
            type="text"
            onKeyUp={handleFilter}
            className="prompt"
            placeholder="  Søk "
          />
        </div>
      </div>
      <br />
      <div>
        {stateJob.jobs ? (
          <div>
            <ReactTable
            previousText="Tilbake"  nextText="Neste"
            pageText="Side" ofText="av"
            rowsText="rader"
              className="bg-white"
              style={{
                fontSize: "14px",
                fontFamily: "Roboto",
                padding: "10px",
              }}
              minRows={1}
              noDataText="Ingen Rader Funnet"
              columns={columns}
              data={stateJob.filtered}
              filterable={false}
              resizable={false}
              defaultPageSize={25}
              getTrProps={(state, rowInfo, instance) => {
                if (rowInfo) {
                  return {
                    style: {
                      color: "#68707d",
                    },
                  };
                }
                return {};
              }}
            ></ReactTable>

            {/* {stateUser.isDelete ? (
                <Delete state={stateUser} onHide={onHide}/>
              ) : (
                <Edit state={stateUser} onHide={onHide} />
              )} */}
          </div>
        ) : (
          <></>
        )}
        <ViewJob state={stateJob} onHide={onHide} />
      </div>
    </div>
  );
}

export default Table;
