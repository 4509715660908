import React from "react";
import { Switch, Route } from "react-router-dom";
import { dashboardRoutes } from "../bin/config/routes";

import { DashBoardListing } from "../components/Dashboard";
export default function DashboardComponent() {
  return (
    <Switch>
      <Route exact path={dashboardRoutes[0]} component={DashBoardListing} />
    </Switch>
  );
}
