import React from "react";
import Form from "./Form";
import { updateAdv } from "../../bin/controllers/api";
import swal from "sweetalert";
export default function EditAdvertisement(props) {
  const updateAd = async(data) => {
    const id = props.location.state.data.id;
    let r=await(updateAdv(data, id))
   
    props.history.push("/advertisement");
  };
  return (
    <>
      <Form updateAd={updateAd} data={props?.location?.state?.data} />
    </>
  );
}
