import React, { useContext } from "react";
import { Link } from "react-router-dom";
import AccordionContext from "../../bin/config/AppContext";
import { Button, Accordion, Card } from "react-bootstrap";
import "./AppPages.css";
import Add from "./AddQuestion";
import Delete from "./Delete";
import AddFAQ from "./AddFAQ";
import FAQDelete from "./DeleteFAQ";
import EditFAQ from "./EditFAQ";
import Edit from "./Edit";
import ReactTooltip from "react-tooltip";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import LoadingSuccessModal from "../../bin/common/commonFunctions";
// function ContextAwareToggle({ children, eventKey, callback }) {
//   const currentEventKey = useContext(AccordionContext);

//   const decoratedOnClick = useAccordionToggle(
//     eventKey,
//     () => callback && callback(eventKey),
//   );

//   const isCurrentEventKey = currentEventKey === eventKey;

//   return (

//     <i
//     className ={isCurrentEventKey ? 'fa fa-angle-up' :'fa fa-angle-down'}
//     onClick={decoratedOnClick}
//    >
//  {children}

//     </i>

//     // <button
//     //   type="button"
//     // //   style={{ value: isCurrentEventKey ? '\f107' : '\f106',
//     // //           height:"30px",
//     // //           width:"30px"
//     // // }}

//     //   onClick={decoratedOnClick}
//     // >
//     //   {children}
//     // </button>
//   );
// }

export default function FAQ({
  state,
  onAdd,
  onHide,
  onDelete,
  onAdd2,
  onDelete2,
  onEdit2,
  onEdit,
  pageTitle,
  onTitleEdit,
  onTitleEditOff,
  onFAQEdit,
  onFAQEditOff,
  show,
}) {
  const [toggle, setToggle] = React.useState({
    toogleid: null,
  });
  // const [loading, setLoading] = React.useState(false);
  function handlePostFAQ(e) {
    // e.preventDefault();
    //console.log("state.selectpae",state.PageData)
    onAdd2();
  }
  function handleFAQEdit(faq, e) {
    // console.log("inside handle faq edit");
    e.preventDefault();
    state.selectedFAQ = faq;
    onEdit2();
  }
  function handleEdit(quest, e) {
    // console.log("quest", quest);
    e.preventDefault();
    state.selectedQuestion = quest;
    onEdit();
  }
  function handleErrors(e) {
    e.target.value = e.target.value.trimLeft();
  }
  function handleDelete(quest, e) {
    e.preventDefault();
    state.selectedQuestion = quest;
    onDelete();
  }
  function handleFAQDelete(faq, e) {
    e.preventDefault();
    // console.log("faq in handleFAQDelete");
    // Object.assign(result,faq)
    state.selectedFAQ = faq;
    //console.log("ooo", state);
    onDelete2();
    //onAdd2()
  }

  function handlePostQuestion(faq, e) {
    e.preventDefault();
    state.selectedFAQ = faq;

    onAdd();
  }
  const editorConfiguration = {
    readOnly: false,
    toolbar: [
      "bold",
      "italic",
      "bulletedList",
      "|",
      "numberedList",
      "alignment",
      "Heading",
    ],
    removePlugins: ["Img", "Link"],
  };

  function handleSave(e) {
    e.preventDefault();
    let formData = new FormData();
    formData.set("pageDetails[pageTitle]", e.target.title.value);
    state.saveAppPage(state.pageData.id, formData);
  }

  function onToggle(id, e) {
    // console.log(e.target);
    // debugger;
    var gg = document.getElementsByClassName("fa-angle-up");
    if (gg.length > 0 && gg[0].id === "" + id) {
      setToggle({
        toogleid: null,
      });
    } else {
      setToggle({
        toogleid: id,
      });
    }
  }

  function oncancel() {
    document.getElementById("title").value = state.pageData.title;
    onTitleEditOff();
  }
  return (
    <>
      {state.pageData !== null && state.FAQData !== null ? (
        <div>
         
            <h6
              style={{ display: "inline-block", marginTop: "20px" }}
              className="top-height text-decor"
            ><Link to="/pagesTable">
            
              <i className="fa fa-angle-left"></i>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#1657d5",
                }}
              >
                {" "}
                Tilbake
              </span>
              </Link>
            </h6>
         
          <div className="row" style={{ paddingTop: "10px" }}>
            <div className="col-md-12">
              {state.pageData !== null ? (
                <form
                  onSubmit={handleSave}
                  id="myform"
                  encType="multipart/form-data"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h6
                        style={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        <span style={{ fontWeight: "700" }}>
                          Sidetittel &nbsp;
                        </span>
                        {!state.titleEdit ? (
                          <div
                            onClick={() => onTitleEdit()}
                            className="inline-block col-md-5"
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              alt="edit "
                              src={require("../../assets/green-edit.svg")}
                            />
                            &nbsp;
                            <label
                              style={{
                                color: "rgb(5, 214, 92)",
                                cursor: "pointer",
                                fontWeight: "400",
                              }}
                            >
                              Endre
                            </label>
                          </div>
                        ) : (
                          <div style={{ display: "inline-block" }}>
                            <input
                              type="submit"
                              value="Save"
                              className="Rectangle-Copy "
                              //onClick={() => handleSendMessage()}
                              style={{
                                width: "50px",
                                textAlign: "center",
                                //marginLeft: "10px",
                                height: "22px",
                                backgroundColor: "#f5f7fa",
                                border: "none",
                                color: "blue",
                                boxShadow: "none",
                              }}
                              // defaultValue="SAVE"
                            />

                            <label
                              onClick={() => oncancel()}
                              style={{
                                textAlign: "center",
                                float: "left",
                                width: "50px",
                                height: "22px",
                                backgroundColor: "#f5f7fa",
                                color: "red",
                                border: "none",
                                cursor: "pointer",
                                marginBottom: "0",
                                paddingTop: "3px",
                                fontWeight: "400",
                              }}
                            >
                              Cancel
                            </label>
                          </div>
                        )}
                      </h6>

                      <input
                        id="title"
                        type="text"
                        name="title"
                        defaultValue={state.pageData.title}
                        style={
                          state.titleEdit ? {backgroundColor: "##f5f7fa",width:"900px !important", border:"none"} : { width:"900px" }
                        }
                        className=""
                        onChange={handleErrors}
                        required
                        disabled={state.titleEdit ? false : true}
                      />
                    </div>
                  </div>
                </form>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div
                className="Rectangle-Copy "
                onClick={(e) => handlePostFAQ(e)}
                style={{
                  marginTop: "30px",
                  textAlign: "center",
                  float: "left",
                  width: "153px",
                  height: "32px",
                  cursor: "pointer",
                }}
              >
                <span
                  className="Delete"
                  style={{ width: "118px", height: "16px" }}
                >
                  Opprett ny
                </span>{" "}
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-12">
              <Accordion className="accordion-length accordion-open ">
                {state.FAQData !== null && state.pageData !== null ? (
                  state.FAQData.map((faq, i) => {
                    return (
                      <Card key={faq.id} className="card-styling main-card">
                        <Accordion.Toggle
                          as={Card.Header}
                          key={faq.id}
                          eventKey={faq.id}
                          className="card-styling"
                          onClick={(e) => onToggle(faq.id, e)}

                          // style={{borderLeftColor: "green"}}
                        >
                          {toggle.toogleid === faq.id ? (
                       <i id={faq.id} className="fa fa-angle-up icon-style"></i>
                      ) : (
                        <i id={faq.id} className="fa fa-angle-down icon-style"></i>
                      )}
                          
                          <span className=" font-style">
                            &nbsp; &nbsp;&nbsp;{faq.title}
                          </span>
                          <Link
                            onClick={(e) => {
                              handleFAQDelete(faq, e);
                            }}
                          >
                            <img
                              alt="delete"
                              src={require("../../assets/delete.svg")}
                              className="header-images"
                              data-tip="Delete"
                            />
                          </Link>

                          <Link
                            onClick={(e) => {
                              handleFAQEdit(faq, e);
                            }}
                            style={{ textAlign: "center" }}
                          >
                            <img
                              alt="edit "
                              src={require("../../assets/edit.svg")}
                              className="header-images"
                              data-tip="Edit"
                            />
                          </Link>

                          <ReactTooltip />
                        </Accordion.Toggle>

                        <Accordion.Collapse
                          eventKey={faq.id}
                          // className="x-accordion-collapse"
                        >
                          <Card.Body
                            // className="inner-alignment"

                            style={{ backgroundColor: "#f3f3f3" }}
                          >
                            <Accordion>
                              {faq.questions.length !== 0 ? (
                                faq.questions.map((quest, j) => {
                                  return (
                                    <Card
                                      style={{
                                        maxWidth: "800px",
                                        marginBottom: "10px",
                                      }}
                                      className="inner-alignment"
                                      //  style={{marginTop:"10px"}}
                                    >
                                      <Accordion.Toggle
                                        as={Card.Header}
                                        className="card-styling "
                                        eventKey={j}
                                      >
                                        <i
                                          style={{ visibility: "hidden" }}
                                          eventKey={j}
                                          className=" fa fa-angle-down icon-style"
                                        ></i>
                                        <span className="font-style">
                                          &nbsp; &nbsp;&nbsp;{quest.faqQuestion}
                                        </span>

                                        <Link
                                          onClick={(e) =>
                                            handleDelete(quest, e)
                                          }
                                        >
                                          <img
                                            alt="delete"
                                            src={require("../../assets/delete.svg")}
                                            className="header-images"
                                            data-tip="Delete"
                                          />
                                        </Link>

                                        <Link
                                          onClick={(e) => handleEdit(quest, e)}
                                        >
                                          <img
                                            alt="edit "
                                            src={require("../../assets/edit.svg")}
                                            className="header-images"
                                            data-tip="Edit"
                                          />
                                        </Link>
                                        <ReactTooltip />
                                      </Accordion.Toggle>

                                      <Accordion.Collapse
                                        eventKey={j}
                                        //style={{marginTop:"10px"}}
                                      >
                                        <Card.Body
                                          style={{
                                            whiteSpace: " pre-line",
                                            paddingTop: "10px",
                                            paddingLeft: "25px",
                                            fontSize: "14px",
                                            fontFamily: "roboto",
                                            color: " #68707d",
                                          }}
                                        >
                                          {quest.faqAnswer}
                                        </Card.Body>
                                      </Accordion.Collapse>
                                    </Card>
                                  );
                                })
                              ) : (
                                <></>
                              )}

                              <Link
                                onClick={(e) => {
                                  handlePostQuestion(faq, e);
                                }}
                                style={{ textAlign: "center" }}
                              >
                                <br />
                                <h6
                                  style={{
                                    color: "#68707d",
                                    fontFamily: "Roboto",
                                    fontWeight: "500",
                                    fontSize: "14px",
                                  }}
                                >
                                  <img
                                    src={require("../../assets/add-circle.svg")}
                                  />
                                  Legg til et nytt spørsmål
                                </h6>
                              </Link>
                            </Accordion>

                            <br />
                          </Card.Body>
                        </Accordion.Collapse>
                        {/* <hr className="class-1" /> */}
                      </Card>
                    );
                  })
                ) : (
                  <></>
                )}
              </Accordion>
            </div>
          </div>

          {state.isDelete ? (
            <Delete state state={state} onHide={onHide} />
          ) : state.isDeleteFAQ ? (
            <FAQDelete state={state} onHide={onHide} />
          ) : state.isAdd ? (
            <Add state={state} onHide={onHide} />
          ) : state.isFAQAdd ? (
            <AddFAQ state={state} onHide={onHide} />
          ) : state.isEditFAQ ? (
            <EditFAQ
              state={state}
              onHide={onHide}
              onFAQEdit={onFAQEdit}
              onFAQEditOff={onFAQEditOff}
            />
          ) : (
            <Edit state={state} onHide={onHide} />
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
