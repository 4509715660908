import React from "react";

import Table from "./Table";
import { getJobs, updateJobStatus } from "../../bin/controllers/api";
import { LoadingModal } from "../../bin/common/commonFunctions";

export default function JobComponent() {
  const [stateJob, setStateJob] = React.useState({
    jobs: [],
    selectedjob: null,
    modal: false,
    updateStatus: updateStatus.bind(this),
    error: false,
    filtered: [],
    setfilteredJobs: setfilteredJobs.bind(this),
    //isDelete: false,
    //deleteUser: deleteUser.bind(this),
  });

  const [loading, setLoading] = React.useState(false);

  function setfilteredJobs(jobs) {
    setStateJob({
      ...jobs,
    });
  }

  async function updateStatus(id, isVisible) {
    setLoading(true);
    await updateJobStatus(id, isVisible);
    loadJobs();
  }

  async function loadJobs(jobs) {
    setLoading(true);
    const data1 = await getJobs();
    if (data1.error_code === undefined) {
      if (jobs !== undefined) {
        setStateJob({
          ...stateJob,
          jobs: data1.data,
          selectedjob: null,
          modal: false,
          updateStatus: updateStatus,
          error: false,
          filtered: jobs,
          //   isDelete: false,
          //   deleteUser: deleteUser,
        });
      } else {
        setStateJob({
          ...stateJob,
          jobs: data1.data,
          selectedjob: null,
          modal: false,
          updateStatus: updateStatus,
          error: false,
          filtered: data1.data,
          //   isDelete: false,
          //   deleteUser: deleteUser,
        });
      }
    } else {
      setStateJob({
        error: true,
      });
    }
    setLoading(false);
  }

  function startsection() {
    if (stateJob.jobs) {
      return (
        <>
          {stateJob.error ? (
            <div>Noe Gikk Galt</div>
          ) : (
            <div className="bg-component">
              <br />
              <div className="">
                <div className="col-md-12 bottom-height-table">
                  <Table
                    stateJob={stateJob}
                    onHide={() =>
                      setStateJob({
                        ...stateJob,
                        modal: false,
                        selectedjob: null,
                      })
                    }
                    onShow={() => setStateJob({ ...stateJob, modal: true })}
                    // onLoad={() => setLoading(true)}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      );
    } else {
      return <div>Ingen Rader Funnet</div>;
    }
  }
  React.useEffect(()=>{
    loadJobs()
  }, []);
  
  return (
    <div className="">
      <section>{startsection()}</section>
      <LoadingModal show={loading} centered backdrop="static" />
    </div>
  );
}
