import React from "react";
import Form from "./Form"
import {
    createAd
  } from "../../bin/controllers/api";
  import swal from "sweetalert";
export default function Add(props){
    const updateAd=async(data)=>{
       const res= await createAd(data)
      //  console.log('res', res)
      //  if (res.data) {
      //   swal(
      //   {
      //     title: res.data.message,
      //     button: {
      //       text: "OK",
      //       value: true,
      //       visible: true,
      //       className: "--confirm",
      //       closeModal: true,
      //     },
      //   });
      
      // } 
      // else{
      //   swal({
      //     title: "something went wrong!",
      //     button: {
      //       text: "OK",
      //       value: true,
      //       visible: true,
      //       className: "--confirm",
      //       closeModal: true,
      //     },
      //   });
      // }
      props.history.push('/advertisement')
    }
        return(
            <>
               <Form updateAd={updateAd}/>
            </>
        )
    
}