import React from "react";
import {
  getCategories,
  createCategory,
  deleteCategoryById,
  blockCategorybyId,
  updateCategory,
  createSubCategory,
  deleteSubCategoryById,
  updateSubCategory,
  blockSubCategory,
} from "../../bin/controllers/api";
import "./Category.css";
import List from "./List";
import { LoadingModal } from "../../bin/common/commonFunctions";
import { Category } from "../../bin/models/StaticTypes";
import { SubCategory } from "../../bin/models/StaticTypes";

export default function CategoryComponent() {
  console.log("sibcategory in category list", SubCategory);

  const [stateCategory, setStateCategory] = React.useState({
    categories: [],
    modal: false,
    isDelete: false,
    isBlock: false,
    isAdd: false,
    postCategory: postCategory.bind(this),
    deleteCategory: deleteCategory.bind(this),
    blockCategory: blockCategory.bind(this),
    editCategory: editCategory.bind(this),
    selectedCategory: Category | null,
    //   filtered: [],
    // setfilteredCategories: setfilteredCategories.bind(this),
  });
  const [loading, setLoading] = React.useState(false);
  const [stateSubCategory, setStateSubCategory] = React.useState({
    jobSubCategories: [],
    modal: false,
    isDeleteSubCat: false,
    isBlockSubCat: false,
    isAddSubCat: false,
    isEditSubCat: false,
    blockSubCategories: blockSubCategories.bind(this),
    editSubCategory: editSubCategory.bind(this),
    postSubCategory: postSubCategory.bind(this),
    deleteSubCategory: deleteSubCategory.bind(this),
    selectedSubCategory: SubCategory | null,
    selectedMainCategory: Category | null,
  });

  // function setfilteredCategories(categories) {
  //   loadCategories(categories);
  // }

  async function loadCategories(reload) {
    const data1 = await getCategories();
    // if (data1.error_code === undefined) {
    //  if (categories  !== undefined) {
    if (reload === undefined)
      setStateCategory({
        ...stateCategory,
        categories: data1.data,
      });
    else {
      setStateCategory({
        ...stateCategory,
        categories: data1.data,
        modal: false,
        isDelete: false,
        isBlock: false,
        selectedCategory: null,
        isAdd: false,
        postCategory: postCategory,
        deleteCategory: deleteCategory,
        blockCategory: blockCategory,
        editCategory: editCategory,
        //  error: false,
        //  filtered: categories,
      });
    }

    setLoading(false);
  }
  async function loadSubCategories() {
    // const data2 = await createSubCategory();
    // setStateSubCategory({
    //   ...stateCategory,
    //   ...stateSubCategory,
    //   jobSubCategories: data2.data,
    //   modal: false,
    //   isEditSubCat: false,
    //   isAddSubCat: false,
    //   isDeleteSubCat: false,
    //   isBlockSubCat: false,
    //   blockSubCategories: blockSubCategories,
    //   editSubCategory: editSubCategory,
    //   deleteSubCategory: deleteSubCategory,
    //   postSubCategory: postSubCategory,
    //   selectedSubCategory: null,
    //   selectedMainCategory: null,
    // });
  }
  //   async function loadCategories(categories) {

  //    const data1 = await getCategories();
  //    if (data1.error_code === undefined) {
  //     if (categories  !== undefined) {
  //   setStateCategory({
  //     ...stateCategory,
  //     categories: data1.data,
  //     modal: false,
  //     isDelete: false,
  //     isBlock:false,
  //     selectedCategory:null,
  //     isAdd:false,
  //     postCategory:postCategory,
  //     deleteCategory:deleteCategory,
  //     blockCategory:blockCategory,
  //     editCategory:editCategory,
  //     error: false,
  //     filtered: categories,

  //   });
  // }
  // else{
  //   setStateCategory({
  //     ...stateCategory,
  //     categories: data1.data,
  //     modal: false,
  //     isAdd:false,
  //     isDelete: false,
  //     isBlock:false,
  //     selectedCategory:null,
  //     postCategory:postCategory,
  //     deleteCategory:deleteCategory,
  //     blockCategory:blockCategory,
  //     editCategory:editCategory,
  //     error: false,
  //     filtered: data1.data,

  //   });
  // }
  //     }
  //     else {
  //       setStateCategory({
  //         error: true,
  //       });
  //     }
  //   }
  async function postSubCategory(params) {
    
    //console.log("inside postsubCategory")
    //console.log("params in postsubcategory categorylist ",params)
    var subcategorydata = { ...params };
    console.log("params", params);
    await createSubCategory(subcategorydata.categoryinfo, params.id);
    // if (data.error_code === undefined)
    //loadCategories();
    loadCategories();
    // else return data;
  }

  async function postCategory(params) {
    setLoading(true);
    console.log("params", params);
    var categorydata = { ...params };
    // console.log("categorydata",categorydata.categoryinfo)
    //console.log(JSON.stringify())
    console.log("create category", categorydata);
    //await createCategory(categorydata);
    await createCategory(categorydata)
      loadCategories()

    //if successful
  }
  async function deleteCategory(params) {
    setLoading(true);

    await deleteCategoryById(params.id);
    loadCategories();
    //if successful
    // loadCategories();
  }
  async function deleteSubCategory(params) {
    setLoading(true);
    console.log("params in subdelet list", params);
    const resp = await deleteSubCategoryById(params.id);
    loadCategories();
    return resp;
    //if successful
    // loadSubCategories();
  }
  async function blockCategory(params) {
    setLoading(true);

    await blockCategorybyId(params.id, params.status);
    loadCategories();
  }
  async function blockSubCategories(params) {
    setLoading(true);

    const resp = await blockSubCategory(params.id, params.status);
    console.log(resp.ErrorModel);
    loadCategories();
    return resp;
  }

  async function editCategory(params) {
    setLoading(true);
    console.log("params in edit", params);
    var categorydata = { ...params };
    await updateCategory(categorydata.categoryinfo, params.id);
    //if (data.error_code === undefined)
    loadCategories();
    // else return data;
  }
  async function editSubCategory(params) {
    setLoading(true);
    console.log("params in edit subcat", params);
    var categorydata = { ...params };
    console.log("categorydata.categoryinfo", categorydata.categoryinfo);
    await updateSubCategory(categorydata.categoryinfo, params.id);
    // if (data.error_code === undefined)
    loadCategories();
    // else return data;
  }
  function startsection() {
    if (stateCategory.categories) {
      return (
        <div className="">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-11">
                {console.log("categorylist stateSubCategory", stateCategory)}
                <List
                  stateSubCategory={stateSubCategory}
                  stateCategory={stateCategory}
                  onHide={() =>
                    setStateCategory({
                      ...stateCategory,
                      modal: false,
                      selectedCategory: null,
                      isDelete: false,
                      isBlock: false,
                      isAdd: false,
                    })
                  }
                  onHide2={() =>
                    setStateSubCategory({
                      ...stateSubCategory,
                      modal: false,
                      selectedSubCategory: null,
                      isDeleteSubCat: false,
                      isBlockSubCat: false,
                      isAddSubCat: false,
                    })
                  }
                  onShow={() =>
                    setStateCategory({
                      ...stateCategory,
                      modal: true,
                      isDelete: false,
                      isBlock: false,
                      isAdd: false,
                      selectedCategory: null,
                    })
                  }
                  onShow2={() =>
                    setStateSubCategory({
                      ...stateSubCategory,
                      modal: true,
                      selectedSubCategory: null,
                      isDeleteSubCat: false,
                      isBlockSubCat: false,
                      isAddSubCat: false,
                    })
                  }
                  onDelete={() =>
                    setStateCategory({
                      ...stateCategory,
                      modal: true,
                      isDelete: true,
                      isBlock: false,
                      isAdd: false,
                    })
                  }
                  onDelete2={() =>
                    setStateSubCategory({
                      ...stateSubCategory,
                      modal: true,
                      isDeleteSubCat: true,
                      isBlockSubCat: false,
                      isAddSubCat: false,
                    })
                  }
                  onEdit={() =>
                    setStateCategory({
                      ...stateCategory,
                      modal: true,
                      isDelete: false,
                      isBlock: false,
                      isAdd: false,
                    })
                  }
                  onEdit2={() =>
                    setStateSubCategory({
                      ...stateSubCategory,
                      modal: true,
                      isDeleteSubCat: false,
                      isBlockSubCat: false,
                      isAddSubCat: false,
                    })
                  }
                  onBlock={() =>
                    setStateCategory({
                      ...stateCategory,
                      modal: true,
                      isDelete: false,
                      isBlock: true,
                      isAdd: false,
                    })
                  }
                  onBlock2={() =>
                    setStateSubCategory({
                      ...stateSubCategory,
                      modal: true,
                      isDeleteSubCat: false,
                      isBlockSubCat: true,
                      isAddSubCat: false,
                    })
                  }
                  onAdd={() =>
                    setStateCategory({
                      ...stateCategory,
                      modal: true,
                      isDelete: false,
                      isBlock: false,
                      isAdd: true,
                    })
                  }
                  onAdd2={() =>
                    setStateSubCategory({
                      ...stateSubCategory,
                      modal: true,
                      isDeleteSubCat: false,
                      isBlockSubCat: false,
                      isAddSubCat: true,
                    })
                  }
                  // onEdit={()=>
                  // }
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div>No records</div>;
    }
  }

  const handleChange = React.useCallback(() => {
    if (stateCategory.categories.length > 0) return;
    loadCategories();
    if (stateSubCategory.jobSubCategories.length > 0) return;
    loadSubCategories();
    setLoading(true);
  }, [loadCategories, loadSubCategories, stateSubCategory, stateCategory]);

  React.useEffect(handleChange, [stateCategory], [stateSubCategory]);
  return (
    <div>
      <LoadingModal show={loading} centered backdrop="static" />
      <div className="bg-component">
        <section>{startsection()}</section>
        <br />
      </div>
    </div>
  );
}
