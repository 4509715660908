import React from "react";

// import AppContext from '../bin/config/AppContext'

import "./sidebar.css";
import { useStateValue } from "../bin/config/AppContext";
import MenuItem from "@material-ui/core/MenuItem";
import { NavLink } from "react-router-dom";
import swal from "sweetalert";
export default function SidebarComponent() {
  console.log("props", window.location.pathname);
  const [state, setState] = React.useState({
    menu:
      window.location.pathname.trim() === "/"
        ? "dashboard"
        : window.location.pathname.trim().replace("/", ""),
  });
  var { Methods } = useStateValue();
  function logout() {
    swal({
      closeOnClickOutside: false,
      title: "Er du sikker på at du vil logge ut?",
      showConfirmButton:false,
      buttons: [{text: "JA",
      value: true,
      visible: true,
      className: "--confirm",
      closeModal: true},
      {text: "AVBRYTE",
      // className: "--confirm",
      value: false,
      visible: true,
     className: "--cancel",
      closeModal: true,} ],
    }).then(function (value) {
      if (value) {
        Methods.handleLogout();
      }
    });
  }

  return (
    
    <div className="row">
      
      <div
        className="col-md-12"
        style={{
          overflowX: "hidden",
          textAlign: "center",
          display: "inline-block",
          padding: "0px",
        }}
        id="simple-menu"
        open={true}
      >
        <img
          alt=""
          src={require("../assets/smat-Logo.svg")}
          style={{
            marginTop: "10px",
            marginBottom: "4px",
            marginLeft: "-20px",
            height:"46px",
          }}
        />
        <hr
          style={{
            marginTop: "0",
            marginRight:"5px",
            marginLeft:"20px",
          }}
        />
        <NavLink
          name="dashboard"
          className="list-group-item"
          exact
          to={"/"}
          onClick={() => setState({ menu: "dashboard" })}
        >
          <MenuItem className="list-group-menuitem font-size" style={{marginLeft:"20px"}}>
            {state.menu === "dashboard" ? (
              <img
                style={{ marginRight: "14px" }}
                alt=""
                src={require("../assets/dashboard-active.svg")}
              />
            ) : (
              <img
                style={{ marginRight: "14px" }}
                alt=""
                src={require("../assets/dashboard-1.svg")}
              />
            )}
            <span>Oversikt</span>
          </MenuItem>
        </NavLink>
        <NavLink
          name="jobs"
          className="list-group-item"
          exact
          to={"/jobs"}
          onClick={() => setState({ menu: "jobs" })}
        >
          <MenuItem className="list-group-menuitem font-size" style={{marginLeft:"20px"}}>
            {state.menu === "jobs" ? (
              <img
                style={{ marginRight: "11px" }}
                alt=""
                src={require("../assets/jobs-overview-active.svg")}
              />
            ) : (
              <img
                style={{ marginRight: "11px" }}
                alt=""
                src={require("../assets/jobs-overview.svg")}
              />
            )}
            <span>Jobber</span>
          </MenuItem>
        </NavLink>
        <NavLink
          className="list-group-item"
          exact
          to={"/advertisement"}
          onClick={() => setState({ menu: "advertisement" })}
        >
          <MenuItem className="list-group-menuitem font-size" style={{marginLeft:"20px"}}>
            {state.menu === "advertisement" ? (
              <img
                style={{ marginRight: "11px" }}
                alt=""
                src={require("../assets/ad-active.svg")}
              />
            ) : (
              <img
                style={{ marginRight: "11px" }}
                alt=""
                src={require("../assets/ad-inactive.svg")}
              />
            )}
            <span>Annonse</span>
          </MenuItem>
        </NavLink>
        <NavLink
          name="users"
          className="list-group-item"
          exact
          to={"/users"}
          onClick={() => setState({ menu: "users" })}
        >
          <MenuItem className="list-group-menuitem font-size" style={{marginLeft:"20px"}}>
            {state.menu === "users" ? (
              <img
                style={{ marginRight: "14px" }}
                alt=""
                src={require("../assets/users-active.svg")}
              />
            ) : (
              <img
                style={{ marginRight: "14px" }}
                alt=""
                src={require("../assets/man.png")}
              />
            )}
            <span>Brukere</span>
          </MenuItem>
        </NavLink>
        <NavLink
          name="messages"
          className= {state.menu === "messages" || window.location.pathname.trim().toLowerCase().includes("createMessage") ?
          "list-group-item active" :"list-group-item"}
          exact
          to={"/messages"}
          onClick={() => setState({ menu: "messages" })}
        >
          <MenuItem className="list-group-menuitem font-size" style={{marginLeft:"20px"}}>
            {state.menu === "messages" || window.location.pathname.trim().toLowerCase().includes("createMessage") ? (

              <img
                style={{ marginRight: "9px" }}
                alt=""
                src={require("../assets/messages-active.svg")}
              />
            ) : (
              <img
                style={{ marginRight: "9px" }}
                alt=""
                src={require("../assets/messages.svg")}
              />
            )}
            <span>Meldinger</span>
          </MenuItem>
        </NavLink>
        <NavLink
          className="list-group-item"
          exact
          to={"/categories"}
          onClick={() => setState({ menu: "categories" })}
        >
          <MenuItem className="list-group-menuitem font-size" style={{marginLeft:"20px"}}>
            {state.menu === "categories" ? (
              <img
                style={{ marginRight: "14px" }}
                alt=""
                src={require("../assets/category-active.svg")}
              />
            ) : (
              <img
                style={{ marginRight: "14px" }}
                alt=""
                src={require("../assets/category.svg")}
              />
            )}
            <span>Kategorier</span>
          </MenuItem>
        </NavLink>
        <NavLink
          className="list-group-item"
          exact
          to={"/pagesTable"}
          onClick={() => setState({ menu: "pagesTable" })}
        >
          <MenuItem className="list-group-menuitem font-size" style={{marginLeft:"20px"}}>
            {state.menu === "pagesTable" ? (
              <img
                style={{ marginRight: "14px" }}
                alt=""
                src={require("../assets/app-active.svg")}
              />
            ) : (
              <img
                style={{ marginRight: "14px" }}
                alt=""
                src={require("../assets/app.svg")}
              />
            )}
            <span>App innhold</span>
          </MenuItem>
        </NavLink>
       
        <hr  style={{marginLeft:"45px",marginRight:"65px"}}/>
        <a
          className="list-group-item"
          onClick={logout}
          style={{ color: "#94979b", marginTop: "30px" }}
        >
          <MenuItem className="list-group-menuitem font-size" style={{marginLeft:"20px"}}>
            <img
              style={{ marginRight: "15px" }}
              alt=""
              src={require("../assets/lock.png")}
            />
            Logg ut
          </MenuItem>
        </a>
      </div>
    </div>
            
  );
}
