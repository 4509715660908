import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import routes from "./bin/config/routes";
import AppProvider from "./bin/config/AppProvider";
import IndexRouter from "./pages";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <AppProvider>
      <Router>
        <Route path={routes} component={IndexRouter} />
      </Router>
    </AppProvider>
  );
}

export default App;
