import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useStateValue } from "../../bin/config/AppContext";
import swal from "sweetalert";
const name = React.createRef();
const subCategoryName = React.createRef();
//const subCategoryName=React.createRef();

export default function AddCat({ stateCategory, onHide }) {
  const { Methods } = useStateValue();

  function handleErrors(e) {
    e.target.value = e.target.value.trimLeft();
  }
  function onSubmit(e) {
    //console.log("heyy inside onSubmit")
    e.preventDefault();

    const query = {
      name: name.current !== null ? name.current.value.trim() : "",
      subCategoryName:
        subCategoryName !== null ? subCategoryName.current.value.trim() : "",
    };

    // return Methods.handleSubmit(query);
    // // const Id= categorydata.id

    Promise.resolve(stateCategory.postCategory(query))
      .then((error) => {
        if (error === undefined || error === null) {
          onHide();
          swal({
            title: "Du har lagt til kategorien",
            button: {
              text: "OK",
              value: true,
              visible: true,
              className: "--confirm",
              closeModal: true,
            },
          });
        } else {
          document.getElementById("error").innerText = error.error_message;
        }
      })
      .catch((error) => {
        swal({
          title: error.error_message,
          button: {
            text: "OK",
            value: true,
            visible: true,
            className: "--confirm",
            closeModal: true,
          },
        });
       
      });
  }
  console.log("value after submit", stateCategory);

  return (
    <Modal
      show={stateCategory.modal}
      onHide={onHide}
      centered
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title
          style={{
            marginLeft: "0",
            marginRight: "auto",
            display: "block",
          }}
        >
          Legg Til Kategori
        </Modal.Title>
        <Button
          type="button"
          onClick={onHide}
          variant="outline-secondary"
          className="close"
          style={{ width: "50px" }}
        >
          &times;
        </Button>
      </Modal.Header>
      <Modal.Body>
        {/* <!-- Input fields come here --> */}
        <div>
          <div id="error"></div>

          <form id="postCategory" onSubmit={onSubmit}>
            <input
              type="text"
              hidden={true}
              disabled={true}
              //  defaultValue={categorydata.id}
            />

            <div className="row" style={{ textAlign: "left" }}>
              <label className="col-4" style={{ textAlign: "left" }}>
                {" "}
                kategori Navn
              </label>
              <input
                className="col-6"
                type="text"
                ref={name}
                placeholder="Skriv inn kategorinavn"
                name="name"
                // id="user_input"
                required
                onChange={handleErrors}
              />
            </div>
            <br />
            <div className="row" style={{ textAlign: "left" }}>
              <label className="col-4" style={{ textAlign: "left" }}>
                {" "}
                Underkategorinavn
              </label>
              <input
                className="col-6"
                type="text"
                ref={subCategoryName}
                placeholder="Skriv inn underkategorinavn"
                name="subCategoryName"
                // id="user_input"
                required
                onChange={handleErrors}
              />

              <span className="form-errors"></span>
            </div>
            <div className="row" style={{ textAlign: "left" }}>
              <div className="col-12" style={{ textAlign: "center" }}>
                <input
                  className="btn text-light"
                  type="submit"
                  value="SAVE"
                  style={{ textAlign: "center", backgroundColor: "#1657d5" }}
                />
              </div>
            </div>
          </form>
        </div>{" "}
      </Modal.Body>
    </Modal>
  );
}
