import React from "react";
import { Bar } from "react-chartjs-2";

export default function BarGraph({ graph_data }) {
  console.log("rrr", graph_data);
  var bodyFontColour = "black";
  var data1 = {
    backgroundColor: "red",
    labels: graph_data.months,
    datasets: [
      {
        label: "Aktive",
        data: graph_data.activejobs,
        backgroundColor: "#2B57D5",
        borderWidth: 1,
      },
      {
        label: "Pågående",
        data: graph_data.ongoing_jobs,
        backgroundColor: "#f45617",
        borderWidth: 1,
        
      },
      {
        label: "Fullførte",
        data: graph_data.completed_jobs,
        backgroundColor: "#24A884",
        borderWidth: 1,
      },
    ],
  };

  return (
    <Bar
      style={{ backgroundColor: "white !important" }}
      data={data1}
      height={90}
      options={{
        elements: {
          rectangle: {
            borderSkipped: "right",
          },
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
                beginAtZero: false,
              },

              scaleLabel: {
                display: true,
                labelString: "MÅNED",
                fontColor: "black",
                fontStyle: "bold",
                fontSize:"16",
                borderSkipped: true,
                fontFamily:"Roboto !mportant"
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                max: 1000,
              },
              scaleLabel: {
                display: true,
                labelString: "BRUKERE",
                fontColor: "black",
                fontStyle: "bold",
                fontSize:"16",
                fontFamily:"Roboto !mportant"
              },
              gridLines: {
                display: true,
              },
            },
          ],
        },
        legend: {
          display: true,
          position: "top",
          align: "end",
          labels: {
            boxWidth: 15,
            fontSize: 14
          }
        },
        showLines: false,
        tooltips: {
          callbacks: {
            title: function (tooltipItem, data) {
              return tooltipItem[0].value;
            },
            label: function (tooltipItem, data) {
              //console.log(data.datasets[tooltipItem.datasetIndex])
              bodyFontColour =
                data.datasets[tooltipItem.datasetIndex].backgroundColor;
              //console.log(bodyFontColour)
              return data.datasets[tooltipItem.datasetIndex].label;
            },
            labelTextColor: function (tooltipItem, chart) {
              return chart.data.datasets[tooltipItem.datasetIndex]
                .backgroundColor;
            },
          },
        },
      }}
    />
  );
}
