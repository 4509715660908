import React from "react";
import AppPagesTable from "./AppPagesTable";
import FAQ from "./FAQ"
import "./AppPages.css"
import PagesList from './Table'
import { AppPages } from "../../bin/models/StaticTypes";
import { getAppPages } from "../../bin/controllers/api";
export default function AppPageComponent() {
  const [statePages, setStatePages] = React.useState({
    pages: [],
    filtered: [],
    setFilteredData: setFilteredData.bind(this),
    
  });
  // const [stateFAQs,setStateFAQs]=React.useState({
  //   FAQs:[],
  //   filteredFaqs: [],
  //   setFilteredFaqData: setFilteredFaqData.bind(this)
  // })
  function setFilteredData(pages) {
    setStatePages({
      ...pages,
    });
  }
  // function setFilteredFaqData(FAQs){
  //   setStateFAQs({
  //     ...FAQs
  //   })
  // }
  async function loadPages(pages) {
    const data1 = await getAppPages();
    console.log("ppp", pages);
    if (pages === undefined) {
      setStatePages({
        setFilteredData: setFilteredData,
        pages: data1.data,
        filtered: data1.data,
      });
    } else {
      setStatePages({
        setFilteredData: setFilteredData,
        pages: data1.data,
        filtered: pages,
      });
    }
    console.log("data in app pagesList", data1.data);
  }
  // async function loadFaqs(FAQs) {
  //   const data1 = await getFAQ();
    
  //   if (FAQs === undefined) {
  //     setStateFAQs({
  //       setFilteredFaqData: setFilteredFaqData,
  //       FAQs: data1.data,
  //       filteredFaqs: data1.data,
  //     });
  //   } else {
  //     setStateFAQs({
  //       setFilteredFaqData: setFilteredFaqData,
  //       FAQs: data1.data,
  //       filteredFaqs: FAQs
  //     });
  //   }
  //   console.log("data FAQ", data1.data);
  // }




  

  //   async function updateUsers(params) {
  //     var userdata = { ...params };
  //     //console.log(JSON.stringify())
  //     await updateUser(userdata.userinfo, params.id);
  //     //if successful
  //     loadUsers();
  //   }

  function startsection() {
     if (statePages.pages) {
      return (
        <div className="container">
          <div className="row">
         
          <div className="col-md-12 bottom-height-table">
            
            <AppPagesTable statePages={statePages} />
           
          </div>
        </div>
        </div>
      );
    } else {
      return <div>Ingen Rader Funnet</div>;
    }
  }
  const handleChange = React.useCallback(() => {
    if (statePages.pages.length > 0) return;
    loadPages();
    
  }, [loadPages,statePages]);

  React.useEffect(handleChange,[statePages]);
  return (
    <div>
      <div className="bg-component">
        <section>{startsection()}</section>
        <br />
      </div>
    </div>
  );
}
